import React, { useState, useEffect } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Label, Button, Modal,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
} from "reactstrap"
import classnames from "classnames"
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import SweetAlert from "react-bootstrap-sweetalert";
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get, put, patch, del } from "../../helpers/api_helper"

import { HeaderFormatter } from "../../helpers/methods";
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";

import not_avail from "../../assets/images/not_avail.jpg"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

import { Link } from "react-router-dom"
import moment from "moment-timezone";
import Select from "react-select";

import HouseChurchEventReplies from "./HouseChurchEventReplies";


const optionGroup = [
    {
        label: "Days",
        options: [
            { label: "Monday", value: "Monday" },
            { label: "Tuesday", value: "Tuesday" },
            { label: "Wednesday", value: "Wednesday" },
            { label: 'Thursday', value: 'Thursday' },
            { label: 'Friday', value: 'Friday' },
            { label: 'Saturday', value: 'Saturday' },
            { label: 'Sunday', value: 'Sunday' }
        ],
    },

];
const optionsGroup = [
    {
        label: "Search by",
        options: [
            { label: "Name", value: "name" },
            { label: "Address", value: "address" },
            { label: "Zip Code", value: "zipcode" },
            { label: 'Start Date', value: 'start' },
            { label: 'End Date', value: 'end' },
            { label: 'Event Type', value: 'event_type' },

        

        ],
    },
];

const MemberOptionGroup = [
    {
        label: "Search by",
        options: [
            { label: 'Email', value: 'user_email' },
            { label: 'Type', value: 'type' }

        ]
    }
]

const PostOptionGroup = [
    {
        label: "Search by",
        options: [
            { label: 'Date', value: 'created' },


        ]
    }
]

const EventTypesOptionGroup = [
    {
        label: "Event Type",
        options: [
            {label:'House Church', value: 'House Church'},
            {label:'Prayer Gathering', value: 'Prayer Gathering'},
            {label:'Worship_Event',value: 'Worship Event'},
            {label:'Bible Study',value: 'Bible Study'},
            {label:'Church Life Group',value: 'Church Life Group'},
            {label:'Youth Gathering (under 19)',value: 'Youth Gathering (under 19)'},
            {label:'Young Adult Gathering (19-25)',value: 'Young Adult Gathering (19-25)'},
            {label:'Adult Gathering (26-99+)',value: 'Adult Gathering (26-99+)'}


        ]
    }
]


const sortOptionGroup = [
    {
        label: "Order by",
        options: [
            { label: "Asc", value: "" },
            { label: "Desc", value: "-" },
        ],
    },
];



const EditCustomer = (props) => {
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)
    const [submitting, setSubmitting] = useState(null)
    const [totalSize, settotalSize] = useState(0);
    const [sizePerPage, setsizePerPage] = useState(20)

    const [meberpage, setMemberPage] = useState(1)
    const [membertotalSize, setmembertotalSize] = useState(0);

    const [loaddata, setLoaData] = useState(false)

    const [ids, setIds] = useState([])

    const [modal_standard, setmodal_standard] = useState(false)
    const [ischecked, setIsChecked] = useState(null)
    const [img, setImg] = useState(null)
    const [name, setName] = useState([])
    const [church, setOrganizations] = useState([])
    const [event, setEvent] = useState([])
    const [selectedDenomination, setselectedDenomination] = useState({ label: "Select Denomination", value: null });
    const [SelectedDay, setSelectedDay] = useState({ label: " Select days", value: null });
    const [selectedOrg, setSelectedOrg] = useState({ label: 'select Organization', value: null });

    const [activeTab, setactiveTab] = useState("1")
    const [page, setpage] = useState(1)
    const [search, setSearch] = useState("")
    const [search_by, setSearchby] = useState({ label: "Search By", value: null });
    const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });

    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [member, setMember] = useState([])
    const [filterdata, setFilterData] = useState("")
    const [selectedState, setselectedState] = useState({ label: "Select State", value: null })
    const [stateOptions, setStateOptions] = useState([])
    const [pageTitle, setPageTitle] = useState('House Church')


    const [selectedEventType, setSelectedEventType] = useState({label:'House Church', value: 'House Church'},)

    // Posts 

    const [postPage, setPostPage] = useState(1)
    const [postTotalSize, setPostTotalSize] = useState(0);
    const [posts, setPosts] = useState([])
    const [selectedPost, setSelectedPost] = useState(null)
    const [Postfilterdata, setPostFilterData] = useState("")

    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")
    const [modalPostDetial, setModalPostDetial] = useState(false)

    const [modalPostReplies, setModalPostReplies] = useState(false)
    const [eventReplies, setEventReplies] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)


    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function tog_postModel() {
        setModalPostDetial(!modalPostDetial)
        removeBodyCss()
    }
    function tog_replies() {
        setModalPostReplies(!modalPostReplies)
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const columns = [
        {
            dataField: "id",
            text: "S No.",
            hidden: true

        },

        {
            dataField: "name",
            text: "Name",
            sort: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (

                <Link to={{ pathname: `/edit-event/${row.id}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': "Edit Church" } }} className="me-3 text-dark">{cellContent}</Link>
            ),
            title: (cell) => `${cell}`
        },

        {
            dataField: "address",
            text: "Address",
            headerFormatter: HeaderFormatter,
            sort: true,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-event/${row.id}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': "Edit Church" } }} className="me-3 text-dark">{cellContent}</Link>
            ),
        },
        {


            dataField: "city",
            text: "City",
            sort: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-event/${row.id}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': "Edit Church" } }} className="me-3 text-dark">{cellContent}</Link>

            )

        },
        {


            dataField: "zipcode",
            text: "Zip Code",
            sort: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-event/${row.id}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': "Edit Church" } }} className="me-3 text-dark">{cellContent}</Link>

            )

        },
        {
            dataField: "start",
            text: "Start Date",
            sort: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-event/${row.id}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': "Edit Church" } }} className="me-3 text-dark">{moment(new Date(row.start)).format("MM-DD-YYYY")}</Link>

            )
        },

        {
            dataField: "end",
            text: "End Date",
            sort: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-event/${row.id}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': "Edit Church" } }} className="me-3 text-dark">{moment(new Date(row.end)).format("MM-DD-YYYY")}</Link>

            )

        },

        {
            dataField: "action",
            isDummyField: true,
            headerFormatter: HeaderFormatter,
            text: "Action",
            formatter: (cellContent, row) => (
                <React.Fragment>
                    <a href={`https://findahousechurch.com/event/${row?.slug}`} rel="noreferrer" target="_blank" className="me-3 text-dark" >  <i className="mdi mdi-eye" style={{ 'fontSize': '20px' }}></i></a>
                    <Link to={{ pathname: `/edit-event/${row.id}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': "Edit Church" } }} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i>{cellContent}</Link>

                </React.Fragment>

            ),
        },
    ]

    function loadState(state){
        get(`${BASE_URL}/api/house_church/state/?page_size=100`, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken()  } })
        .then(response => {
          let state_data = response.data.results
          let options = []
          for(let i=0; i<state_data.length; i++){
            if (state === state_data[i].abbr){

                setselectedState({ label: state_data[i].name, value: state_data[i].abbr })
            }
            options.push({ label: state_data[i].name, value: state_data[i].abbr })
          }
          let stateOptions = [
            {
              label: "State",
              options: options
            }
          ]
          setStateOptions(stateOptions)
        })
    
      }

      function MemberColumn(house_church){
        return [
            {
                dataField: "id",
                text: "S No.",
                hidden: true
    
            },
            {
                dataField: "user",
                text: "Name",
                isDummyField: true,
                headerFormatter: HeaderFormatter,
                formatter: (cellContent, row) => (
                    <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': "Edit Church" } }} className="me-3 text-dark">{row.user.first_name}{" "}{row.user.last_name}</Link>
    
                )
            },
            
            {
                dataField: "user_email",
                text: "Email",
                headerFormatter: HeaderFormatter,
                formatter: (cellContent, row) => (
                    // <Link to='#' className="me-3 text-dark">{cellContent}</Link>
                    <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': house_church?`Edit ${house_church.name}`:"Edit House Church" } }} className="me-3 text-dark">{cellContent}</Link>
                )
            },
            {
                dataField: "joined_by",
                text: "Joined By",
                headerFormatter: HeaderFormatter,
                sort: true,
                formatter: (cellContent, row) => (
                    <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { url: `/edit-event/${id}`, page: 'Edit Event' } }} className="me-3 text-dark">{cellContent}</Link>
                ),
            },
            {
                dataField: "type",
                text: "Type",
                sort: true,
                headerFormatter: HeaderFormatter,
                formatter: (cellContent, row) => (
    
                    <Link to='#' className="me-3 text-dark">{cellContent}</Link>
                )
    
            },
    
        ]
      }


    function postColumn(house_church){
        return [
            {
                dataField: "id",
                text: "S No.",
                hidden: true
    
            }, 
            {
                dataField: "user",
                text: "Name",
                isDummyField: true,
                headerFormatter: HeaderFormatter,
                formatter: (cellContent, row) => (
                    <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': house_church?`Edit ${house_church.name}`:"Edit House Church" } }} className="me-3 text-dark">{row.user.first_name}{" "}{row.user.last_name}</Link>
    
                )
            },
            {
                dataField: "message",
                text: "Message",
                headerFormatter: HeaderFormatter,
                formatter: (cellContent, row) => (
                    // <Link to='#' className="me-3 text-dark">{cellContent}</Link>
                    <a href={`https://findahousechurch.com/post/house-church/${row?.uuid}`} rel="noreferrer" target="_blank" className="me-3 text-dark" >  {cellContent.length >50?cellContent.substring(0,50)+" ...":cellContent}</a>
                    )
            },
            {
                dataField: "likes",
                text: "Likes",
                headerFormatter: HeaderFormatter,
                formatter: (cellContent, row) => (
                    cellContent
                )
            },
            {
                dataField: "replies",
                text: "Replies",
                headerFormatter: HeaderFormatter,
                formatter: (cellContent, row) => (
                   
                   <Link to={'#'} onClick={()=> {tog_replies(); setEventReplies(row.replies); setSelectedEvent(row.uuid)}} className="me-3 text-dark">{cellContent.length}</Link>
                )
            },
            {
                dataField: "type",
                text: "Type",
                isDummyField: true,
                headerFormatter: HeaderFormatter,
                formatter: (cellContent, row) => (
                    <React.Fragment>
                    {/* <Link to='#' onClick={() => {setSelectedPost(row); tog_postModel()}} className="me-3 text-dark"><i className="mdi mdi-eye"></i></Link> */}
                    <a href={`https://findahousechurch.com/post/house-church/${row?.uuid}`} rel="noreferrer" target="_blank" className="me-3 text-dark" >  <i className="mdi mdi-eye" style={{ 'fontSize': '20px' }}></i></a>
                    <Link to='#' onClick={() => {setSelectedPost(row); setAlert_confirm(true)}} className="text-danger"><i className="mdi mdi-delete"></i></Link>
                    </React.Fragment>
                )
    
            },
        ]
    }

    useEffect(() => {

      
        localStorage.setItem('edit_church', id)
        loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false`)
        loadOrgData(`${BASE_URL}/api/house_church/organization/`)
        loaddData(`${BASE_URL}/api/house_church/events/?house_id=${id}`, 20, 1)
        loadMember(`${BASE_URL}/api/house_church/dash-house-members/?house_id=${id}`, 20, 1)
        loadPosts(`${BASE_URL}/api/house_church/dash-house-posts/?house_id=${id}`, 20, 1)
        

        setDidMount(true);
        get(`${BASE_URL}/api/house_church/dash-list-house_church/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setUser(response.data)
                setPageTitle(response.data.name)
                loadState(response.data.state)
                

                setIsChecked(response.data.status)


                if (response.data.denomination_id) {
                    setselectedDenomination({ label: response.data.denomination_id.name, value: response.data.denomination_id.id })

                }
                if (response.data.organization) {
                    setSelectedOrg({ label: response.data.organization.name, value: response.data.organization.id })
                }
                if (response.data.days) {
                    setSelectedDay({ label: response.data.days, value: response.data.days })
                }
                if (response.data.id === undefined) {
                    props.history.push('/all-churches')
                }
            })

    }, []);

    if (!didMount) {
        return null;
    }

    //get listing of denomination when status is true and archive is false.
    function loaddData(url, sizePerPage, page) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setEvent(response.data.results)
            settotalSize(response.data.count)
            setpage(page)
            setsizePerPage(sizePerPage)
            setLoaData(true)



        })
    }
    //get listing of house member on the basis of house id.
    function loadMember(url, sizePerPage, page) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setMember(response.data.results)
            setmembertotalSize(response.data.count)
            setsizePerPage(sizePerPage)
            setMemberPage(page)
            setLoaData(true)

        })

    }

    //get listing of house church posts on the basis of house id.
    function loadPosts(url, sizePerPage, page) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setPosts(response.data.results)
            setPostTotalSize(response.data.count)
            setsizePerPage(sizePerPage)
            setPostPage(page)
        })
    }

    //get listing of denomination when status is true and archive is false
    function loadData(url) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            const formattedOptions = response.data.results.map(items => ({
                value: items.id,
                label: items.name
            }));
            setName(formattedOptions)

        })
    }
    //get listing of church on the basis of id show using label in name
    function loadOrgData(url) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            const formattedOptions = response.data.results.map(items => ({
                value: items.id,
                label: items.name
            }));
            setOrganizations(formattedOptions)

        })
    }


    function handleDeletePost(post_id){
        del(`${BASE_URL}/api/house_church/dash-house-posts/${post_id}/`,
            { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } },
            { validateStatus: false }
        )
            .then(res => {
            if (res.status >= 400) {
                
            }
            else {
                
                let new_page = postPage
                if (posts.length < 2) {
                    new_page = new_page > 1 ? new_page - 1 : 1
                }
                let url = `${BASE_URL}/api/house_church/dash-house-posts/?house_id=${id}&page_size=${sizePerPage}&page=${new_page}`
                if (Postfilterdata !== "") {
                    url = `${url}${Postfilterdata}`
                }
                loadPosts(url, sizePerPage, new_page)
                setSuccess_confirm(true)
                setAlert_confirm(false)
                setDynamic_title('Deleted!')
                setDynamic_description("Post Has been Deleted.")
                setSelectedPost(null) 

            }
            }
            )
    }


    //when file size greater than 30 kb then don't take as file.used to conver to base54 image
    const handleFileUpload = (e) => {
        try {
            let reader = new FileReader();
            let file = e.target.files[0];
            if (!file) {
                return;
            }
            const fileSizeInKB = file.size / 1024;
            if (fileSizeInKB > 30) {
                toast.error('Please select an image file smaller than 30 kB');
                setImg(null)
            } else {
                reader.onloadend = () => {
                    var previewImgUrl = reader.result
                    setImg(previewImgUrl)
                    console.log(previewImgUrl, "vhbgfhgfhgf")
                }
                reader.readAsDataURL(file);
            }

        } catch (error) {

        }
    }

    //update field of house church on the basis of id.
    function handleSubmit(e, values) {
        setSubmitting(true)
        
            let data = {
            ...values, status: ischecked, denomination_id: selectedDenomination.value, organization: selectedOrg.value
            , days: SelectedDay.value, state:selectedState.value
        }
        put(`${BASE_URL}/api/house_church/dash-list-house_church/${id}/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Something Went Wrong")
                } else {
                    props.history.push("/house-churches")
                }
            })


    }
    //update field on the basis of church 

    function handleImageSubmit(e, values) {
        if (img) {
            setSubmitting(true)
            
            patch(`${BASE_URL}/api/house_church/dash-list-house_church/${id}/`, { ...values, image: img },
                { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false })
                .then(response => {
                    if (response.status >= 400) {
                        toast.error("Image not found")
                        console.log(response)

                    } else {
                        props.history.push("/house-churches")
                        console.log(response)

                    }
                })
        } else {
            toast.error('Please select an image file smaller than 30 kB');

        }
    }


    function handleSelectGroup(selectedDenomination) {
        setselectedDenomination(selectedDenomination);
    }

    function handleSelectDayGroup(SelectedDay) {
        setSelectedDay(SelectedDay)
    }

    function handleChurchInputChange(e) {
        loadOrgData(`${BASE_URL}/api/house_church/organization/&name=${e}`)
    }
    function handleDenominationChange(e) {
        loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false&name=${e}`)
    }

    function ClearFilter() {

        setFilterData("")
        setSearch("")
        setSearchby({ label: "Search By", value: null });
        setFromDate(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
        setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))

        loaddData(`${BASE_URL}/api/house_church/events/?house_id=${id}`, 20, 1)
        // loadMember(`${BASE_URL}/api/house_church/dash-house-members/?house_id=${id}`, 20, 1)

    }

    function ClearMemberFilter() {

        setFilterData("")
        setSearch("")
        setSearchby({ label: "Search By", value: null });
        setFromDate(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
        setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))

        loadMember(`${BASE_URL}/api/house_church/dash-house-members/?house_id=${id}`, 20, 1)

    }


    
    function FilterData(e, values) {
        let filter_data = ""
        if (!search_by.value) {
            toast.error("Select Search Field")
        } else {
            if (search_by.value === "start") {
                filter_data = `start_from=${fromDate}&start_to=${toDate}`
            } else if (search_by.value === 'end') {
                filter_data = `end_from=${fromDate}&end_to=${toDate}`
            }  else if (search_by.value === 'event_type') {
                filter_data = `event_type=${selectedEventType.value}`
            }else {
                filter_data = `${search_by.value}=${values.search}`

            }
            filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
            setFilterData(filter_data)
            console.log(filter_data, 'eventfilterdata')

            loaddData(`${BASE_URL}/api/house_church/events/?house_id=${id}&${filter_data}`, 20, 1)
        }
    }



    function FilterLoadMember(e, values) {
        let filter_data = ""
        if (!search_by.value) {
            toast.error('Select Search Field')
        } else {
            filter_data = `${search_by.value}=${values.search}`
        }
        filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
        console.log(filter_data, 'loadmemeber')
        setFilterData(filter_data)
        console.log('URLS', `${BASE_URL}/api/house_church/dash-house-members/?house_id=${id}&${filter_data}`)
        loadMember(`${BASE_URL}/api/house_church/dash-house-members/?house_id=${id}&${filter_data}`, 20, 1)


    }


    function FilterPostData(e, values) {
        let filter_data = ""
        if (!search_by.value) {
            toast.error("Select Search Field")
        } else {
            if (search_by.value === "created") {

                filter_data = `&start_date=${fromDate}&end_date=${toDate}`
            }
            // filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
            setPostFilterData(filter_data)
            setPosts([])
            // loadMemberData(`${BASE_URL}/api/house_church/event-members/?event_id=${id}${filter_data}`, 20, 1)
            loadPosts(`${BASE_URL}/api/house_church/dash-house-posts/?house_id=${id}${filter_data}`, 20, 1)



        }
    }

    function ClearPostFilter() {
        setFilterData("")
        setSearch("")
        setSearchby({ label: "Search By", value: null });
        setFromDate(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
        setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))
        loadPosts(`${BASE_URL}/api/house_church/dash-house-posts/?house_id=${id}`, 20, 1)
        

    }


    


    function MemberNoDataIndication() {
        if (loaddata && event.length === 0) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )
        }
    }
    function EventNoDataIndication() {
        if (loaddata && member.length === 0) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )
        }
    }

    function postsNoDataIndication() {
        if ( posts.length === 0) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )
        }
    }
    
    function handleTableChange(type, { page, sizePerPage, searchText, }) {

        setTimeout(() => {
            setLoaData(false)
            setEvent([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/events/?house_id=${id}&page_size=${sizePerPage}&page=${new_page}`
            if (filterdata !== '') {
                url = url + `&${filterdata}`
            }
            window.scrollTo(0, 0)
            loaddData(url, sizePerPage, new_page, searchText)


        }, 1000);
    }

    function handleMemberTableChange(type, { page, sizePerPage, searchText, }) {
        setTimeout(() => {
            setLoaData(false)
            setMember([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/dash-house-members/?house_id=${id}&page_size=${sizePerPage}&page=${new_page}`
            if (filterdata !== '') {
                url = url + `&${filterdata}`
            }
            window.scrollTo(0, 0)
            loadMember(url, sizePerPage, new_page, searchText)
        }, 100);

    }

    function handlePostsTableChange(type, { page, sizePerPage, searchText, }) {
        setTimeout(() => {
            setPosts([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/dash-house-posts/?house_id=${id}&page_size=${sizePerPage}&page=${new_page}`
            if (filterdata !== '') {
                url = url + `&${filterdata}`
            }
            window.scrollTo(0, 0)
            loadPosts(url, 20, 1)
        }, 100);

    }


    function handleOnSelect(row, isSelect) {
        let id = []
        if (isSelect) {
            id = ids
            id.push(row.id)
        } else {
            for (let i = 0; i < ids.length; i++) {
                if (ids[i] !== row.id) {
                    id.push(ids[i])
                }
            }

        }
        setIds(id)
    }

    function handleOnSelectAll(isSelect, rows) {
        console.log(isSelect, 'EditManageHouseChurchIsSelect')
        if (isSelect) {
            let id = []
            for (let i = 0; i < rows.length; i++) {
                id.push(rows[i].id)
            }
            setIds(id)
        } else {
            setIds([])
        }
    }



    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };




    const defaultSorted = [{
        dataField: 'f_name',
        order: 'desc',
        savestate: true
    }];


    //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }
    const MemberPageOptions = {
        page: meberpage,
        sizePerPage: sizePerPage,
        totalSize: membertotalSize,
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '200', value: 200
        }]
    }

    const PostsPageOptions = {
        page: postPage,
        sizePerPage: sizePerPage,
        totalSize: postTotalSize,
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }

    function dateFormatter(start) {
        const date = new Date(start);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    }

    document.title = pageTitle + " | FAHC Admin Dashboard"


    return (
        <React.Fragment>
            <div className="page-content">

               
                <Breadcrumbs title={"House Church"} breadcrumbItem={user?`Edit ${user.name}`:"Edit House Churches"} link={"/house-churches"} />


                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>


                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Detail                                                                           </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                            <span className="d-none d-sm-block">Event</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "3",
                                            })}
                                            onClick={() => {
                                                toggle("3")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Member</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "4",
                                            })}
                                            onClick={() => {
                                                toggle("4")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Posts</span>
                                        </NavLink>
                                    </NavItem>

                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        {user ?
                                            <Row>
                                                <Col md="12">

                                                    <Row>
                                                        <Col sm="4">
                                                            <Card>
                                                                <CardBody>
                                                                    <CardTitle></CardTitle>
                                                                    <Row>
                                                                        <Col sm="12">
                                                                            <div className="text-end">
                                                                                <Button color='link' type='button' onClick={() => tog_standard()} >
                                                                                    <i className="bx bx-edit-alt" style={{ 'fontSize': '30px' }}></i>

                                                                                </Button>
                                                                            </div>
                                                                        </Col>

                                                                        <Col md={12}>
                                                                            <div className="text-center">
                                                                                {/* <img
                                                                                className="img-fluid"
                                                                                alt=""
                                                                                width="300"
                                                                                src={user.image ? user.image : not_avail}
                                                                            /> */}
                                                                                {/* <img src={img} alt="Selected" /> */}

                                                                            </div>

                                                                        </Col>
                                                                        {/* {img? 
                                                                <img src={img} alt="Selected"/>
                                                                :

                                                                <img src={user.img?user.img:not_avail} alt="Selected"/>


                                                                    } */}

                                                                    <img src={img?img:user.image?user.image:not_avail} alt="Selected"/>
                                                                    

                                                                    </Row>

                                                                </CardBody>
                                                            </Card>

                                                        </Col>
                                                        <Col sm="8">
                                                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                                                <Card>
                                                                    <CardBody>
                                                                        <CardTitle>House Church </CardTitle>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="name">Name</Label>
                                                                                    <AvField
                                                                                        name="name"
                                                                                        placeholder="Name"
                                                                                        type="text"
                                                                                        className="form-control"

                                                                                        id="name"
                                                                                        value={user.name}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="address">Address</Label>
                                                                                    <AvField
                                                                                        name="address"
                                                                                        placeholder="Address"
                                                                                        type="text"
                                                                                        errorMessage=" Please Enter Address."
                                                                                        className="form-control"

                                                                                        id="address"
                                                                                        value={user.address}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="city">City</Label>
                                                                                    <AvField
                                                                                        name="city"
                                                                                        placeholder="City"
                                                                                        type="text"
                                                                                        errorMessage=" Please provide a valid City."
                                                                                        className="form-control"

                                                                                        id="city"
                                                                                        value={user.city}
                                                                                    />
                                                                                </div>


                                                                            </Col>
                                                                            <Col md={6}>
                                                                                {/* <div className="mb-3">
                                                                                    <Label htmlFor="state">State</Label>
                                                                                    <AvField
                                                                                        name="state"
                                                                                        placeholder="State"
                                                                                        type="text"
                                                                                        errorMessage=" Please provide a valid State."
                                                                                        className="form-control"

                                                                                        id="state"
                                                                                        value={user.state}
                                                                                    />
                                                                                </div> */}
                                                                                <div className="mb-3">
                                                                                <Label htmlFor="state">State</Label>
                                                                                    <Select
                                                                                        value={selectedState}
                                                                                        onChange={(e) => {
                                                                                        setselectedState(e)
                                                                                        }}
                                                                                        options={stateOptions}
                                                                                        classNamePrefix="select2-selection"
                                                                                    />
                                                                                </div>

                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col md="6">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="zipcode">Zip Code</Label>
                                                                                    <AvField
                                                                                        name="zipcode"
                                                                                        placeholder="ZipCode"
                                                                                        type="text"
                                                                                        errorMessage=" Please provide a valid ZipCode."
                                                                                        className="form-control"

                                                                                        id="zipcode"
                                                                                        value={user.zipcode}
                                                                                    />
                                                                                </div>
                                                                            </Col>

                                                                            <Col md="6">
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="user_id">User</Label>
                                                                                    <AvField
                                                                                        name="user_id"
                                                                                        placeholder="User"
                                                                                        type="text"
                                                                                        errorMessage="Please provide a valid User."
                                                                                        className="form-control"
                                                                                        disabled

                                                                                        id="user_id"
                                                                                        value={user.user_id ? user.user_id.first_name : null}
                                                                                    />
                                                                                </div>

                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm={6}>

                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="church">Organization</Label>
                                                                                    <Select
                                                                                        value={selectedOrg}
                                                                                        onChange={(e) => {
                                                                                            setSelectedOrg(e)
                                                                                        }}
                                                                                        onInputChange={(e) => handleChurchInputChange(e)}
                                                                                        options={church}
                                                                                        classNamePrefix="select2-selection"
                                                                                    />
                                                                                </div>
                                                                            </Col>

                                                                            <Col sm={6}>

                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="name">Denomination</Label>
                                                                                    <Select
                                                                                        value={selectedDenomination}
                                                                                        onChange={(e) => {
                                                                                            handleSelectGroup(e);
                                                                                        }}
                                                                                        onInputChange={(e) => handleDenominationChange(e)}
                                                                                        options={name}
                                                                                        classNamePrefix="select2-selection"
                                                                                    />
                                                                                </div>

                                                                            </Col>

                                                                        </Row>

                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="days">Days</Label>
                                                                                    <Select
                                                                                        value={SelectedDay}
                                                                                        onChange={(e) => {
                                                                                            handleSelectDayGroup(e);
                                                                                        }}
                                                                                        options={optionGroup}
                                                                                        classNamePrefix="select2-selection"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="time">Time</Label>
                                                                                    <AvField
                                                                                        name="time"
                                                                                        placeholder="Time"
                                                                                        type="text"
                                                                                        errorMessage=" Please provide a valid TIme."
                                                                                        className="form-control"

                                                                                        id="time"
                                                                                        value={user.time}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                       
                                                                        
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="special_inst">Special Inst</Label>
                                                                                    <AvField
                                                                                        name="special_inst"
                                                                                        placeholder="Special Inst"
                                                                                        type="text"
                                                                                        errorMessage=" Please provide a valid Special Inst."
                                                                                        className="form-control"

                                                                                        id="special_inst"
                                                                                        value={user.special_inst}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="type">Type</Label>
                                                                                    <AvField
                                                                                        name="type"
                                                                                        placeholder="Type"
                                                                                        type="text"
                                                                                        errorMessage=" Please provide a valid Videos."
                                                                                        className="form-control"

                                                                                        id="type"
                                                                                        value={user.type}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="latitude">Latitude</Label>
                                                                                    <AvField
                                                                                        name="latitude"
                                                                                        placeholder="Latitude"
                                                                                        type="text"
                                                                                        errorMessage=" Please provide a valid Latitude."
                                                                                        className="form-control"

                                                                                        id="latitude"
                                                                                        value={user.latitude}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="longitude">Longitude</Label>
                                                                                    <AvField
                                                                                        name="longitude"
                                                                                        placeholder="longitude"
                                                                                        type="text"
                                                                                        errorMessage=" Please provide a valid longitude."
                                                                                        className="form-control"

                                                                                        id="longitude"
                                                                                        value={user.longitude}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="info">Info</Label>
                                                                                    <AvField
                                                                                        name="info"
                                                                                        placeholder="Info"
                                                                                        type="textarea"
                                                                                        errorMessage=" Please provide a valid Info."
                                                                                        className="form-control"

                                                                                        id="info"
                                                                                        value={user.info}
                                                                                    />
                                                                                </div>
                                                                            </Col>

                                                                        </Row>
                                                                        <Row>

                                                                            <Col md={6}>
                                                                                <div className="checkbox-wrapper">
                                                                                    <label>
                                                                                        <input type="checkbox"
                                                                                            checked={ischecked}
                                                                                            onChange={(e) => setIsChecked(e.target.checked)} />{" "}
                                                                                        Status
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        {submitting ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary waves-effect waves-light my-3"
                                                                            >
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                                                Updating
                                                                            </button>
                                                                            :
                                                                            <Button color="primary" type="submit">
                                                                                Update
                                                                            </Button>
                                                                        }
                                                                    </CardBody>
                                                                </Card>
                                                            </AvForm>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                                <Row>

                                                    <Col sm={6} md={4} xl={3}>

                                                    <Modal
                                    isOpen={modal_standard}
                                    toggle={() => {
                                        tog_standard()
                                    }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0 " style={{ 'textAlign': 'center' }} id="myModalLabel">
                                            Image
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setmodal_standard(false)
                                            }}
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {/* <h5>Overflowing text to show scroll behavior</h5> */}

                                        <Col lg='12'>
                                            <AvForm className="needs-validation" onValidSubmit={handleImageSubmit}>

                                                <Row>
                                                    <Col md={12}>
                                                        <div class="text-center">


                                                        </div>

                                                    </Col>



                                                    {/* <img src={img} alt="Selected" /> */}

                                                    <input type='file' accept="image/png, image/jpeg" className="mr-5 mb-3 text-center" onChange={handleFileUpload} />


                                                </Row>


                                                <div className="modal-footer">
                                                    {submitting ?

                                                        // <button
                                                        //     type="button"
                                                        //     onClick={() => {
                                                        //         tog_standard()
                                                        //     }}
                                                        //     className="btn btn-primary waves-effect waves-light my-3"
                                                        // >
                                                        //     <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                        //     Updateimage
                                                        // </button>
                                                        <Button color="primary" type="submit">
                                                            Update
                                                        </Button>
                                                        :
                                                        <Button color="primary" type="submit">
                                                            Update
                                                        </Button>
                                                    }
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            tog_standard();
                                                            setImg(null)
                                                        }}
                                                        className="btn btn-primary waves-effect"

                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>

                                                </div>


                                            </AvForm>
                                        </Col>


                                    </div>

                                </Modal>
                                                    </Col>
                                                </Row>
                                            </Row>
                                            :
                                            <div id="preloader">
                                                <div id="status">
                                                    <div className="spinner-chase">
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        }



                                    </TabPane>
                                    {/* House Church Events */}
                                    <TabPane tabId="2" className="p-3">

                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            data={event}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={event}
                                                    columns={columns}
                                                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                    bootstrap5
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                                                <Row className="mb-2">


                                                                    <Col sm="3">
                                                                        <div className="text-sm-start mb-2">
                                                                            <Select
                                                                                value={search_by}
                                                                                onChange={(e) => {
                                                                                    setSearchby(e)
                                                                                }}
                                                                                options={optionsGroup}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>


                                                                    </Col>
                                                                   
                                                                    {search_by.value ?
                                                                        <Col sm="9">
                                                                            <Row>
                                                                                <Col sm="5">
                                                                                    {search_by.value === "start" || search_by.value === "end" ?
                                                                                        <Row>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">


                                                                                                    <AvField
                                                                                                        name="from_date"
                                                                                                        placeholder="Received From"
                                                                                                        type={fromDate}
                                                                                                        errorMessage="From Date"
                                                                                                        className="form-control"
                                                                                                        value={fromDate}
                                                                                                        onChange={(e) => setFromDate(e.target.value)}
                                                                                                        id="from_date"
                                                                                                    />
                                                                                                </div>

                                                                                            </Col>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="to_date"
                                                                                                        placeholder="Category"
                                                                                                        type={toDate}
                                                                                                        errorMessage="To Date"
                                                                                                        className="form-control"

                                                                                                        value={toDate}
                                                                                                        onChange={(e) => setToDate(e.target.value)}
                                                                                                        id="to_date"
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        : search_by.value === 'event_type' ?
                                                                                        <Row>
                                                                                                <Col sm="12">
                                                                                                <div className="text-sm-start mb-2">
                                                                                                    <Select
                                                                                                        value={selectedEventType}
                                                                                                        onChange={(e) => {
                                                                                                            setSelectedEventType(e)
                                                                                                        }}
                                                                                                        options={EventTypesOptionGroup}
                                                                                                        classNamePrefix="select2-selection"
                                                                                                    />
                                                                                                </div>

                                                                                                </Col>

                                                                                            </Row>

                                                                                        : search_by.value !== null ?
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="text-sm-end mb-2">
                                                                                                        <AvField
                                                                                                            name="search"
                                                                                                            placeholder={search_by.label}
                                                                                                            type="text"
                                                                                                            value={search}
                                                                                                            onChange={(e) => setSearch(e.target.value)}
                                                                                                            className="form-control"
                                                                                                            validate={{ required: { value: true } }}
                                                                                                        />
                                                                                                    </div>

                                                                                                </Col>

                                                                                            </Row>

                                                                                            : null}

                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-start mb-2">
                                                                                        <Select
                                                                                            value={order_by}
                                                                                            onChange={(e) => {
                                                                                                setOrder_by(e)
                                                                                            }}
                                                                                            options={sortOptionGroup}
                                                                                            classNamePrefix="select2-selection"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="4">
                                                                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                        Filter
                                                                                    </Button>
                                                                                    {" "}
                                                                                    <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                                                                        Clear
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        : null}



                                                                </Row >
                                                            </AvForm>



                                                            <div className="table-responsive">

                                                                <BootstrapTable
                                                                    remote
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={true}
                                                                    dataFormat={dateFormatter}
                                                                    defaultSorted={defaultSorted}

                                                                    hover
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                    onTableChange={handleTableChange}
                                                                    noDataIndication={() => MemberNoDataIndication()}
                                                                    selectRow={selectRow}
                                                                />

                                                            </div>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <div className="mb-3">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="pagination pagination-rounded mb-2">
                                                                        <PaginationTotalStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                            </Row>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </TabPane>
                                    {/* House Church Members */}
                                    <TabPane tabId="3" className="p-3">

                                        <PaginationProvider
                                            pagination={paginationFactory(MemberPageOptions)}
                                            data={member}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={member}
                                                    columns={MemberColumn(user)}
                                                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                    bootstrap5
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <AvForm className="needs-validation" onValidSubmit={FilterLoadMember}>
                                                                <Row className="mb-2">

                                                                    <Col sm="3">
                                                                        <div className="text-sm-start mb-2">
                                                                            <Select
                                                                                value={search_by}
                                                                                onChange={(e) => {
                                                                                    setSearchby(e)
                                                                                }}
                                                                                options={MemberOptionGroup}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>


                                                                    </Col>
                                                                    {search_by.value ?
                                                                        <Col sm="9">
                                                                            <Row>
                                                                                <Col sm="5">
                                                                                    {search_by.value !== null ?
                                                                                        <Row>
                                                                                            <Col sm="12">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="search"
                                                                                                        placeholder={search_by.label}
                                                                                                        type="text"
                                                                                                        value={search}
                                                                                                        onChange={(e) => setSearch(e.target.value)}
                                                                                                        className="form-control"
                                                                                                        validate={{ required: { value: true } }}
                                                                                                    />
                                                                                                </div>

                                                                                            </Col>

                                                                                        </Row>

                                                                                        : null}

                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-start mb-2">
                                                                                        <Select
                                                                                            value={order_by}
                                                                                            onChange={(e) => {
                                                                                                setOrder_by(e)
                                                                                            }}
                                                                                            options={sortOptionGroup}
                                                                                            classNamePrefix="select2-selection"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="4">
                                                                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                        Filter
                                                                                    </Button>
                                                                                    {" "}
                                                                                    <Button type="button" color="primary" onClick={() => ClearMemberFilter()} className="btn-rounded" >
                                                                                        Clear
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        : null}



                                                                </Row >
                                                            </AvForm>



                                                            <div className="table-responsive">

                                                                <BootstrapTable
                                                                    remote
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    hover
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                    onTableChange={handleMemberTableChange}
                                                                    noDataIndication={() => EventNoDataIndication()}
                                                                // selectRow={selectRow}
                                                                />

                                                            </div>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <div className="mb-3">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="pagination pagination-rounded mb-2">
                                                                        <PaginationTotalStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                            </Row>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </TabPane>
                                    {/* House Church Posts */}
                                    <TabPane tabId="4" className="p-3">

                                        <PaginationProvider
                                            pagination={paginationFactory(PostsPageOptions)}
                                            data={posts}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={posts}
                                                    columns={postColumn(user)}
                                                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                    bootstrap5
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <AvForm className="needs-validation" onValidSubmit={FilterPostData}>
                                                                <Row className="mb-2">

                                                                    <Col sm="3">
                                                                        <div className="text-sm-start mb-2">
                                                                            <Select
                                                                                value={search_by}
                                                                                onChange={(e) => {
                                                                                    setSearchby(e)
                                                                                }}
                                                                                options={PostOptionGroup}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>


                                                                    </Col>
                                                                    {search_by.value ?
                                                                        <Col sm="9">
                                                                            <Row>
                                                                        
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-end mb-2">
                                                                                    <AvField
                                                                                        name="from_date"
                                                                                        placeholder="Received From"
                                                                                        type="date"
                                                                                        errorMessage="From Date"
                                                                                        className="form-control"
                                                                                        value={fromDate}
                                                                                        onChange={(e) => setFromDate(e.target.value)}
                                                                                        id="from_date"
                                                                                    />
                                                                                    </div>
                                    
                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-end mb-2">
                                                                                    <AvField
                                                                                        name="to_date"
                                                                                        placeholder="Category"
                                                                                        type="date"
                                                                                        errorMessage="To Date"
                                                                                        className="form-control"
                                                                                        value={toDate}
                                                                                        onChange={(e) => setToDate(e.target.value)}
                                                                                        id="to_date"
                                                                                    />
                                                                                    </div>
                                                                                </Col>
                                                                                    
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-start mb-2">
                                                                                        <Select
                                                                                            value={order_by}
                                                                                            onChange={(e) => {
                                                                                                setOrder_by(e)
                                                                                            }}
                                                                                            options={sortOptionGroup}
                                                                                            classNamePrefix="select2-selection"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                        Filter
                                                                                    </Button>
                                                                                    {" "}
                                                                                    <Button type="button" color="primary" onClick={() => ClearPostFilter()} className="btn-rounded" >
                                                                                        Clear
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        : null}
                                                                </Row >
                                                            </AvForm>

                                                            <div className="table-responsive">

                                                                <BootstrapTable
                                                                    remote
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    hover
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                    onTableChange={handlePostsTableChange}
                                                                    noDataIndication={() => postsNoDataIndication()}
                                                                // selectRow={selectRow}
                                                                />

                                                            </div>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <div className="mb-3">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="pagination pagination-rounded mb-2">
                                                                        <PaginationTotalStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                            </Row>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>



                </Row>

                {alert_confirm ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => handleDeletePost(selectedPost.id)}
                        onCancel={() => {
                        setAlert_confirm(false)
                        setSelectedPost(null)
                        }
                        }
                    >
                        You won't be able to revert this!
                    </SweetAlert>
                    ) : null}

                    {success_confirm ? (
                    <SweetAlert
                        success
                        title={dynamic_title}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                        setSuccess_confirm(false)
                        setAlert_confirm(false)
                        }
                        }
                    >
                        {dynamic_description}
                    </SweetAlert>
                    )
                    : null
                    }


            </div>
            <Col sm={6} md={4} xl={3}>
                <Modal
                isOpen={modalPostDetial}
                toggle={() => {
                    tog_postModel()
                }}
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">{`Post Detail`} </h5>

                    <button
                    type="button"
                    onClick={() => {
                        tog_postModel()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {/* <h5>Overflowing text to show scroll behavior</h5> */}

                    <Col lg='12'>
                            <Row>
                                <Col md={12}>
                                    
                                        <p><b>Send By: </b>{selectedPost?.user?.first_name} {selectedPost?.user?.last_name}</p>
                                        <p><b>Message: </b>{selectedPost?.message}</p>
                                        <p><b>Date: </b> {moment(selectedPost?.created).tz("America/Chicago").format("YYYY-MM-DD")}</p>
                                    

                                </Col>
                            </Row>
                    </Col>


                </div>


                </Modal>
            </Col>
             {/* azfdsdgd */}
             <Col sm={6} md={4} xl={3}>
                <Modal
                size="lg"
                isOpen={modalPostReplies}
                toggle={() => {
                    tog_replies()
                }}
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">{`Replies`} </h5>

                    <button
                    type="button"
                    onClick={() => {
                        tog_replies()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {/* <h5>Overflowing text to show scroll behavior</h5> */}

                   <HouseChurchEventReplies eventReplies={eventReplies} uuid={selectedEvent}/>


                </div>


                </Modal>
            </Col>
            {/* end sdfsdf */}

        </React.Fragment>
    )
}

export default EditCustomer
