import React, { useEffect, useState } from "react"
import Breadcrumbs from '../../components/Common/Breadcrumb'


import { Row, Col, Card, CardBody, Spinner, NavLink, NavItem, Nav, TabPane, TabContent, Button, Label, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { Link } from "react-router-dom"
import classnames from "classnames"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

import { toast } from "react-toastify";
import { get, post } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import moment from "moment-timezone";


const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Name", value: "name" },

    ],
  },
];

const EnableGroup = [
  {
    label: 'Disable By',
    options: [
      { label: "Move To Inactive", value: "disable" },
      { label: "Move To Archive", value: "archive" }
    ],
  },
];
const DisableGroup = [
  {
    label: "Enabale By",
    options: [
      { label: 'Move To Active', value: 'enable' },
      { label: 'Move To Archive', value: "archive" }
    ],
  },
];
const ArchiveGroup = [
  {
    label: 'Archive By',
    options: [
      { label: "Move To Active", value: "enable" },
      { label: "Move To Inactive", value: "disable" },
      { label: "Delete", value: "delete" }
    ]
  }
];

const sortOptionGroup = [
  {
    label: "Order by",
    options: [
      { label: "Asc", value: "" },
      { label: "Desc", value: "-" },
    ],
  },
];
const AllChurch = (props) => {

  const [activeTab, setactiveTab] = useState("2")
  const [church, setChurch] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [DisabledUsers, setDisabledUsers] = useState([]);
  const [DisabledTotalSize, setDisabledtotalSize] = useState(0);
  const [DisabledPage, setDisabledpage] = useState(1)
  const [ArchivePage, setArchivePage] = useState(1)
  const [ArchiveTotalSize, setArchiveTotalSize] = useState(0);
  const [archiveData, setArchiveData] = useState([])
  const [loadarchiveData, setLoadArchiveData] = useState(false)
  const [ids, setIds] = useState([])
  const [DisabledIds, setDisabledIds] = useState([])
  const [ArchiveIds, setArchiveIds] = useState([])
  const [modaldata_standard, setmodaldata_standard] = useState(false)
  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [search_by, setSearchby] = useState({ label: "Search By", value: null });
  const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });
  const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
  const [toDate, setToDate] = useState(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))
  const [selectedGroup, setselectedGroup] = useState({ label: "Perform Bulk Action", value: null });
  const [selectedDisableGroup, setselectedDisableGroup] = useState({ label: "Perform Bulk Action ", value: null });
  const [selectedArchiveGroup, setselectedArchiveGroup] = useState({ label: "Perform Bulk Action", value: null });
  const [submitting, setSubmitting] = useState(null)
  const [ischecked, setIsChecked] = useState(null)
  const [isarchive, setIsArchive] = useState(null)
  const [didMount, setDidMount] = useState(false);
  const [filterdata, setFilterData] = useState("")

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function togdata_standard() {
    setmodaldata_standard(!modaldata_standard)
    removeBodyCss()
  }



  ValidateUser(props)


  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }


  const columns = [
    {
      dataField: "id",
      text: "S No.",
      hidden: true
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <Link to={`/update-denomination/${row.id}`} className="me-3 text-dark">{cellContent}</Link>

      ),

    },
    
  ]
  const activeColume = [ ...columns , {
    dataField: "action",
    isDummyField: true,
    headerFormatter: HeaderFormatter,
    text: "Action",
    formatter: (cellContent, row) => (
        <React.Fragment>

            <a href={`https://findahousechurch.com/denomination/${row.slug}`} target="__blank" className="me-3 text-primary" >  <i className="mdi mdi-search-web"></i></a>

        </React.Fragment>

    ),
},]

  useEffect(() => {
    setDidMount(true)
    loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false`, 20, 1)
    loadDisabledData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=false`, 20, 1)
    loadArciveData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=true`, 20, 1)
    
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  //save status and archive and all field of denomination in database
  function handelsubmit(e, values) {
    setSubmitting(true)
    post(`${BASE_URL}/api/house_church/denominations/`, { ...values, status: ischecked, archive: isarchive },
      { headers: { 'Content-Type': 'application/json', }, validateStatus: false })
      .then(response => {
        console.log(response, 'succesdenominationresponse')
        if (response.status >= 400) {
          setSubmitting(false)
          togdata_standard()
          console.log(response, 'denominationresponse')

        } else {
          setSubmitting(false)
          togdata_standard()
          loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false`, 20, 1)
          loadDisabledData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=false`, 20, 1)
          loadArciveData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=true`, 20, 1)
          console.log(response, 'succesdenominationresponse')
        }
      })
  }

  //get all field when status is equal to true and archive is equal to false
  function loadData(url, sizePerPage, page) {
    let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
    data.then(response => {
      setChurch(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
    })
  }
  //get all field when status is equal to false and archvie is true
  function loadArciveData(url, sizePerPage, page) {
    let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
    data.then((response) => {
      setLoadArchiveData(true)
      setArchiveData(response.data.results)
      setArchiveTotalSize(response.data.count)
      setArchivePage(page)
      setsizePerPage(sizePerPage)
    })
  }

  //get all field when status is equalt to false and archive is also false
  function loadDisabledData(url, sizePerPage, page) {
    let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
    data.then(response => {
      setDisabledUsers(response.data.results)
      setDisabledtotalSize(response.data.count)
      setDisabledpage(page)
      setsizePerPage(sizePerPage)
    })
  }


  function ClearFilter() {
    // setChurchData("")
    setFilterData("")
    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null });
    setFromDate(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
    setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))

    loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false`, 20, 1)
    loadDisabledData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=false`, 20, 1)
    loadArciveData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=true`, 20, 1)

  }

  //filter data field of denomination page
  function FilterData(e, values) {
    let filter_data = ""
    if (!search_by.value) {
      toast.error("Select Search Field")
    } else {
      if (search_by.value === "created") {
        filter_data = `&from_date=${fromDate}&to_date=${toDate}`
        // filter_data=`&name=${fromData}&email=&{email}`
      } else {
        filter_data = `&${search_by.value}=${values.search}`
      }
      filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
      loadData(`${BASE_URL}/api/house_church/denominations/?status=true${filter_data}`, 20, 1)
      loadDisabledData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=false${filter_data}`, 20, 1)
      loadArciveData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=true${filter_data}`, 20, 1)

      setFilter(filter_data)
      setFilterData(filter_data)


    }
  }




  //when length of denomination model in database is empty then show Table Is Empty
  //with status is equal to true and archive is equal to false
  function NoDataIndication() {
    if (loadData && church.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }
  //when length of denomination model in database is empyt then show table is empty 
  //with status is equal to false and archive is equal to true
  function ArchiveDataIndication() {
    if (loadarchiveData && archiveData.length === 0) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>

        </div>)
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner className='my-3' color='dark' />

        </div>
      )
    }
  }
  //when length of denomination model in database is empyt then show table is empty
  //with status is equal to false and archive is equalt false
  function DisabledNoDataIndication() {
    if (loadDisabledData && DisabledUsers.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }
  //change the page
  function handleTableChange(type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setChurch([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/denominations/?status=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
      if (filterdata !== "") {
        url = url + `&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadData(url, sizePerPage, new_page, searchText)


    }, 1000);
  }
  //change the disable status and archive is false page
  function handleDisableTableChange(type, { page, sizePerPage, searchText, }) {

    setTimeout(() => {
      setDisabledUsers([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/denominations/?status=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
      if (filterdata !== "") {
        url = url + `&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadDisabledData(url, sizePerPage, new_page, searchText)
    }, 1000);
  }
  //change the disabe status and archive is true page
  function handleArchiveTaleChange(type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setArchiveData([])
      let new_page = page
      let url = (`${BASE_URL}/api/house_church/denominations/?status=false&archive=true&page_size=${sizePerPage}&page=${new_page}`)
      if (filterdata !== "") {
        url = url + `&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadArciveData(url, sizePerPage, new_page, searchText)
    }, 1000);
  }

  //change status of multiple id
  function HandleBulkOpertation(value) {
    let data = {}
    if (activeTab === "2") {
      data["action"] = value
      data["denominations"] = ids
    } else if (activeTab === "3") {
      data["action"] = value
      data["denominations"] = DisabledIds
    } else if (activeTab === '4') {
      data['action'] = value
      data['denominations'] = ArchiveIds
    }
    post(`${BASE_URL}/api/house_church/denominations-bulk-action/`, data,
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } },
      { validateStatus: false }
    )
      .then(res => {
        if (res.status >= 400) {
          toast.error("Somthing Went Wrong")
        }
        else {
          if (activeTab === '2') {

            let new_page = page
            if (church.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/house_church/denominations/?status=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadData(url, sizePerPage, new_page)
            setIds([])
            setChurch([])


          } else if (activeTab === '3') {

            let new_page = DisabledPage
            if (DisabledUsers.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/house_church/denominations/?status=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadDisabledData(url, sizePerPage, new_page)
            setDisabledIds([])
            setDisabledUsers([])


          } else if (activeTab === '4') {
            let new_page = ArchivePage
            if (archiveData.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/house_church/denominations/?status=false&archive=true&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadArciveData(url, sizePerPage, new_page)
            setArchiveIds([])
            setArchiveData([])
          }
          if (value === "disable") {
            loadDisabledData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=false${filter}`, 20, 1)
          } else if (value === "enable") {
            loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false${filter}`, 20, 1)
          } else if (value === "archive") {
            loadArciveData(`${BASE_URL}/api/house_church/denominations/?status=false&archive=true${filter}`, 20, 1)
          }
        }
      }
      )

  }
  //select single row
  function handleOnSelect(row, isSelect) {
    let id = []
    if (isSelect) {
      id = ids
      id.push(row.id)
    } else {
      for (let i = 0; i < ids.length; i++) {
        if (ids[i] !== row.id) {
          id.push(ids[i])
        }
      }

    }
    setIds(id)
  }
  //select multiple row of active denomination
  function handleOnSelectAll(isSelect, rows) {
    console.log(isSelect, 'isSelected')
    if (isSelect) {
      // let email = []
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setIds(id)
    } else {
      setIds([])
    }
  }
  //select single row of disable denomination
  function handleOnSelectDisabledRow(row, isSelect) {
    let id = []
    if (isSelect) {
      id = DisabledIds
      id.push(row.id)
    } else {
      for (let i = 0; i < DisabledIds.length; i++) {
        if (DisabledIds[i] !== row.id) {
          id.push(DisabledIds[i])
        }
      }

    }
    setDisabledIds(id)
  }
  //select single row of archive denomination
  function HandleonselectArchiveRow(row, isSelect) {
    let id = []
    if (isSelect) {
      id = ArchiveIds
      id.push(row.id)
    } else {
      for (let i = 0; i < ArchiveIds.length; i++) {
        if (ArchiveIds[i] !== row.id) {
          id.push(ArchiveIds[i])
        }
      }
    }
  }
  //select multiple row of archive
  function handleOneSelectAllArchiveRow(isSelect, rows) {
    if (isSelect) {
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setArchiveIds(id)
    } else {
      setArchiveIds([])
    }
  }

  function handleOnSelectAllDisabledRow(isSelect, rows) {
    if (isSelect) {
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setDisabledIds(id)
    } else {
      setDisabledIds([])
    }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };


  const selectRowforDisabled = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelectDisabledRow,
    onSelectAll: handleOnSelectAllDisabledRow
  };

  const selectRowforArchive = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: HandleonselectArchiveRow,
    onSelectAll: handleOneSelectAllArchiveRow

  };

  const defaultSorted = [{
    dataField: 'f_name',
    order: 'desc',
    savestate: true
  }];

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]

  }
  const AcrhivePageOptions = {
    page: ArchivePage,
    sizePerPage: sizePerPage,
    totalSize: ArchiveTotalSize,
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    },
    {
      text: '50', value: 50
    },
    {
      text: '200', value: 200
    },
    ]
  }
  const DisabledpageOptions = {
    page: DisabledPage,
    sizePerPage: sizePerPage,
    totalSize: DisabledTotalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]
  }
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)

  }
  function handleDisableGroup(selectedDisableGroup) {
    setselectedDisableGroup(selectedDisableGroup)

  }
  function handleArchiveGroup(selectArchiveGroup) {
    setselectedArchiveGroup(selectArchiveGroup)
  }
  function handleBulkAction() {
    if (activeTab === '2') {
      if (selectedGroup.value === 'disable') {
        ids.length > 0 ? HandleBulkOpertation('disable') : toast.error("Select Denomination")

      } else if (selectedGroup.value === 'archive') {
        ids.length > 0 ? HandleBulkOpertation('archive') : toast.error("Select Denomination")

      }
    }
  }

  function handledisablebulkAction() {
    if (activeTab === '3') {
      if (selectedDisableGroup.value === 'enable') {
        DisabledIds.length > 0 ? HandleBulkOpertation('enable') : toast.error("Select Denomination")

      } else if (selectedDisableGroup.value === 'archive') {
        DisabledIds.length > 0 ? HandleBulkOpertation('archive') : toast.error("Select Denomination")

      }
    }
  }

  function handleArchivebulkAction() {
    if (activeTab === '4') {
      if (selectedArchiveGroup.value === 'enable') {
        ArchiveIds.length > 0 ? HandleBulkOpertation('enable') : toast.error('select Denominations')
      } else if (selectedArchiveGroup.value === 'disable') {
        ArchiveIds.length > 0 ? HandleBulkOpertation('disable') : toast.error('select Denomination')
      }else if (selectedArchiveGroup.value === 'delete') {
        ArchiveIds.length > 0 ? HandleBulkOpertation('delete') : toast.error('select Denomination')
      }
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Denominations' link="/dashboard" />
        <Col xs={12}>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Active</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Inactive</span>
                  </NavLink>
                </NavItem>


                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggle("4")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Archive</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="2">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={church}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={church}
                        columns={activeColume}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <AvForm className="needs-validation" onValidSubmit={FilterData}>
                              <Row className="mb-2">
                                <Col sm='3'>
                                  <Select
                                    value={selectedGroup}
                                    onChange={(e) => {
                                      handleSelectGroup(e);
                                    }}
                                    options={EnableGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col sm='1'>
                                  <Button type='button' color='primary' className="me-2" onClick={() => handleBulkAction()}>Apply</Button>
                                </Col>
                                <Col sm="3">
                                  <div className="text-sm-start mb-2">
                                    <Select
                                      value={search_by}
                                      onChange={(e) => {
                                        setSearchby(e)
                                      }}
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>


                                </Col>
                                {/* <Col sm={2}></Col> */}
                                <Col sm='5'>
                                  <div className='float-end'>
                                    <Button type='button' color='primary' onClick={() => props.history.push('/create-denomination')}><i className="bx bx-plus"></i>{" "}
                                      Create Denomination</Button>
                                  </div>
                                </Col>
                                <Row>
                                  {search_by.value ?
                                    <Col sm="8">
                                      <Row>
                                        <Col sm="5">
                                          {search_by.value === "created" ?
                                            <Row>
                                              <Col sm="6">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="from_date"
                                                    placeholder="Received From"
                                                    type="date"
                                                    errorMessage="From Date"
                                                    className="form-control"
                                                    value={fromDate}
                                                    onChange={(e) => setFromDate(e.target.value)}
                                                    id="from_date"
                                                  />
                                                </div>

                                              </Col>
                                              <Col sm="6">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="to_date"
                                                    placeholder="Category"
                                                    type="date"
                                                    errorMessage="To Date"
                                                    className="form-control"
                                                    value={toDate}
                                                    onChange={(e) => setToDate(e.target.value)}
                                                    id="to_date"
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            : search_by.value !== null ?
                                              <Row>
                                                <Col sm="12">
                                                  <div className="text-sm-end mb-2">
                                                    <AvField
                                                      name="search"
                                                      placeholder={search_by.label}
                                                      type="text"
                                                      value={search}
                                                      onChange={(e) => setSearch(e.target.value)}
                                                      className="form-control"
                                                      validate={{ required: { value: true } }}
                                                    />
                                                  </div>

                                                </Col>

                                              </Row>

                                              : null}

                                        </Col>
                                        <Col sm="3">
                                          <div className="text-sm-start mb-2">
                                            <Select
                                              value={order_by}
                                              onChange={(e) => {
                                                setOrder_by(e)
                                              }}
                                              options={sortOptionGroup}
                                              classNamePrefix="select2-selection"
                                            />
                                          </div>
                                        </Col>
                                        <Col sm="4">
                                          <Button type="submit" color="success" className="btn-rounded me-2" >
                                            Filter
                                          </Button>
                                          {" "}
                                          <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                            Clear
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Col>
                                    : null}
                                </Row>



                              </Row >
                              <Row>

                              </Row>
                            </AvForm>
                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleTableChange}
                                noDataIndication={() => NoDataIndication()}
                                selectRow={selectRow}
                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>


                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </TabPane>
                <TabPane tabId="3" >
                  <PaginationProvider
                    pagination={paginationFactory(DisabledpageOptions)}
                    data={DisabledUsers}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={DisabledUsers}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <AvForm className="needs-validation" onValidSubmit={FilterData}>
                              <Row className="mb-2">
                                <Col sm='3'>
                                  <Select
                                    value={selectedDisableGroup}
                                    onChange={(e) => {
                                      handleDisableGroup(e);
                                    }}
                                    options={DisableGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col sm='1'>
                                  <Button type='button' color='primary' className="me-2" onClick={() => handledisablebulkAction()}>Apply</Button>
                                </Col>
                                <Col sm="3">
                                  <div className="text-sm-start mb-2">
                                    <Select
                                      value={search_by}
                                      onChange={(e) => {
                                        setSearchby(e)
                                      }}
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>


                                </Col>
                                {search_by.value ?
                                  <Col sm="8">
                                    <Row>
                                      <Col sm="5">
                                        {search_by.value === "created" ?
                                          <Row>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="from_date"
                                                  placeholder="Received From"
                                                  type="date"
                                                  errorMessage="From Date"
                                                  className="form-control"
                                                  value={fromDate}
                                                  onChange={(e) => setFromDate(e.target.value)}
                                                  id="from_date"
                                                />
                                              </div>

                                            </Col>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="to_date"
                                                  placeholder="Category"
                                                  type="date"
                                                  errorMessage="To Date"
                                                  className="form-control"
                                                  value={toDate}
                                                  onChange={(e) => setToDate(e.target.value)}
                                                  id="to_date"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          : search_by.value !== null ?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>

                                              </Col>

                                            </Row>

                                            : null}

                                      </Col>
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={order_by}
                                            onChange={(e) => {
                                              setOrder_by(e)
                                            }}
                                            options={sortOptionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">


                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                          Filter
                                        </Button>
                                        {" "}
                                        <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                          Clear
                                        </Button>

                                      </Col>
                                    </Row>
                                  </Col>
                                  : null}

                              </Row>
                            </AvForm>
                            {/* <Row className="my-2">

                              <Col sm="6" >
                                <Button type="button" color="primary" onClick={() => { DisabledIds.length > 0 ? HandleBulkOpertation('enable') : toast.error("Select Church") }} className="btn-rounded me-2" >
                                  Enable
                                </Button>{" "}
                                <Button type='button' color="primary" onClick={() => { DisabledIds.length > 0 ? HandleBulkOpertation('archive') : toast.error("Select Church") }}>Archive</Button>
                              </Col>

                            </Row> */}

                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleDisableTableChange}
                                noDataIndication={() => DisabledNoDataIndication()}
                                selectRow={selectRowforDisabled}

                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}

                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>


                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>

                </TabPane>
                <TabPane tabId="4">
                  <PaginationProvider
                    pagination={paginationFactory(AcrhivePageOptions)}
                    data={archiveData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={archiveData}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <AvForm className="needs-validation" onValidSubmit={FilterData}>
                              <Row className="mb-2">
                                <Col sm='3'>
                                  <Select
                                    value={selectedArchiveGroup}
                                    onChange={(e) => {
                                      handleArchiveGroup(e);
                                    }}
                                    options={ArchiveGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col sm='1'>
                                  <Button type='button' className='me-2' color='primary' onClick={() => handleArchivebulkAction()}>Apply</Button>
                                </Col>
                                <Col sm="3">
                                  <div className="text-sm-start mb-2">
                                    <Select
                                      value={search_by}
                                      onChange={(e) => {
                                        setSearchby(e)
                                      }}
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>


                                </Col>
                                {search_by.value ?
                                  <Col sm="8">
                                    <Row>
                                      <Col sm="5">
                                        {search_by.value === "created" ?
                                          <Row>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="from_date"
                                                  placeholder="Received From"
                                                  type="date"
                                                  errorMessage="From Date"
                                                  className="form-control"
                                                  value={fromDate}
                                                  onChange={(e) => setFromDate(e.target.value)}
                                                  id="from_date"
                                                />
                                              </div>

                                            </Col>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="to_date"
                                                  placeholder="Category"
                                                  type="date"
                                                  errorMessage="To Date"
                                                  className="form-control"
                                                  value={toDate}
                                                  onChange={(e) => setToDate(e.target.value)}
                                                  id="to_date"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          : search_by.value !== null ?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>

                                              </Col>

                                            </Row>

                                            : null}

                                      </Col>
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={order_by}
                                            onChange={(e) => {
                                              setOrder_by(e)
                                            }}
                                            options={sortOptionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">
                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                          Filter
                                        </Button>
                                        {" "}
                                        <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                          Clear
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                  : null}



                              </Row >
                            </AvForm>

                            {/* <Row className="my-2"> */}


                            {/* <Col sm="6" >

                                <Button type="button" color="primary" onClick={() => { ArchiveIds.length > 0 ? HandleBulkOpertation('enable') : toast.error("Select Church") }} className="btn-rounded me-2" >
                                  Enable
                                </Button>{" "}

                                <Button type="button" color="primary" onClick={() => { ArchiveIds.length > 0 ? HandleBulkOpertation('disable') : toast.error("Select Church") }} className="btn-rounded me-2" >
                                  Disabled
                                </Button>
                              </Col>




                            </Row> */}




                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleArchiveTaleChange}
                                noDataIndication={() => ArchiveDataIndication()}
                                selectRow={selectRowforArchive}
                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>


                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>

                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>

          <Modal
            isOpen={modaldata_standard}
            toggle={() => {
              togdata_standard()
            }}
          >
            <div className="modal-header">
              <p className="mt-3">Create Denomination</p>

              <button
                type="button"
                onClick={() => {
                  setmodaldata_standard(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

            </div>

            <div className="modal-body">

              <Col lg='12'>


                <AvForm className="needs-validation" onValidSubmit={handelsubmit}>
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label htmlFor="name"> Name</Label>
                        <AvField
                          name="name"
                          placeholder=" Name"
                          type="text"
                          className="form-control"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter a name' },
                            pattern: { value: '^[a-zA-Z0-9 ]*$', errorMessage: 'Your name must be composed only with letter and numbers' },
                            minLength: { value: 6, errorMessage: 'Your name must be between 6 and 16 characters' },
                            maxLength: { value: 16, errorMessage: 'Your name must be between 6 and 16 characters' }
                          }}
                          id="name"

                        />
                      </div>
                    </Col>

                  </Row>
                  <Row>

                    <Col md={3}>

                      <div className="checkbox-wrapper">
                        <label>
                          <input
                            type="checkbox"
                            id="data"
                            // defaultChecked={selected_row?.status}
                            checked={ischecked}
                            onChange={(e) => setIsChecked(e.target.checked)}

                          />{"   "}
                          Status
                        </label>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="checkbox-wrapper">
                        <label>
                          <input
                            type='checkbox'
                            checked={isarchive}
                            onChange={(e) => setIsArchive(e.target.checked)} />{" "}
                          Archive
                        </label>


                      </div>

                    </Col>
                  </Row>

                  {submitting ?
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          togdata_standard()
                        }}
                      >
                        Close
                      </button>
                      <Button color="primary" type="submit">
                        Create
                      </Button>
                    </div>
                    :
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          togdata_standard()
                        }}
                      >
                        Close
                      </button>
                      <Button color="primary" type="submit">
                        Create
                      </Button>
                    </div>

                  }

                </AvForm>
              </Col>
            </div>

          </Modal>
        </Col>


      </div>

    </React.Fragment>
  );
}
export default AllChurch
