import React, { useEffect, useState } from "react"
import Breadcrumbs from '../../../components/Common/Breadcrumb'


import { Row, Col, Card, CardBody, Spinner, Button, Modal, Label } from "reactstrap"
import Select from "react-select";
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation"

// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get, del, post } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper";
import { HeaderFormatter } from "../../../helpers/methods";
import { ValidateUser } from "../../../helpers/validate_user";
import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken"


const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Name", value: "name" },

    ],
  },
];


const sortOptionGroup = [
  {
    label: "Order by",
    options: [
      { label: "Asc", value: "" },
      { label: "Desc", value: "-" },
    ],
  },
];
const Badwords = (props) => {
  const [church, setChurch] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [loaddata, setLoadData] = useState(false)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [search, setSearch] = useState("")
  const [search_by, setSearchby] = useState({ label: "Search By", value: null });
  const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });
  const [didMount, setDidMount] = useState(false);
  const [filterdata, setFilterData] = useState(" ")
  const [seletedWord, setSeletedWord] = useState('null')
  const [modal_standard, setmodal_standard] = useState(false)

  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [submitting, setSubmitting] = useState(null)


  ValidateUser(props)


  const columns = [
    {
      dataField: "id",
      text: "S No.",
      hidden: true
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (

        <React.Fragment>
          <Link to="#" className="me-3 text-dark" >{cellContent}</Link>
        </React.Fragment>
      ),

    },
    {
      dataField: "action",
      isDummyField: true,
      headerFormatter: HeaderFormatter,
      text: "Action",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to="#" onClick={() => { setAlert_confirm(true); setSeletedWord(row.id) }} className="me-3 text-danger"><i className="mdi mdi-trash-can font-size-18"></i>{cellContent}</Link>
        </React.Fragment>
      ),
    },


  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/house_church/badwords/`, 20, 1)
    setDidMount(true);

    return () => setDidMount(false);

  }, []);

  if (!didMount) {
    return null;
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function togdata_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }


  //get data of category field
  function loadData(url, sizePerPage, page) {
    let data = get(url, { headers: { 'Content-Type': 'application/json', } })
    data.then(response => {
      setChurch(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setLoadData(true)
    })
  }

  function handleDerleteWord(id) {
    del(`${BASE_URL}/api/house_church/badwords/${id}/`,
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } },
      { validateStatus: false }
    )
      .then(res => {
        console.log(res, "-res")
        if (res.status >= 400) {

        }
        else {
          let new_page = page
          if (church.length < 2) {
            new_page = new_page > 1 ? new_page - 1 : 1
          }
          let url = `${BASE_URL}/api/house_church/badwords/?page_size=${sizePerPage}&page=${new_page}`
          loadData(url, sizePerPage, new_page)
          setSuccess_confirm(true)
          setAlert_confirm(false)
          setDynamic_title('Deleted!')
          setDynamic_description("Bad Word Has been Deleted.")
          setSeletedWord(null)
        }
      }
      )
  }


  function ClearFilter() {
    setFilterData("")
    setSearch("")
    setSearchby({ label: "Search By", value: null });

    loadData(`${BASE_URL}/api/house_church/badwords/`, 20, 1)

  }

  //search field on the basis fo category field and sorting data
  function FilterData(e, values) {
    let filter_data = ""
    if (!search_by.value) {
      toast.error("Select Search Field")
    } else {

      filter_data = `search=${values.search}&order_by=${order_by.value}${search_by.value}`
      console.log(filter_data, 'filtercountyname')
      setFilterData(filter_data)
      loadData(`${BASE_URL}/api/house_church/badwords/?${filter_data}`, 20, 1)


    }
  }

  //show Empty data when length of category listing is null 
  function NoDataIndication() {
    if (loaddata && church.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }


  //change the page
  function handleTableChange(type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setChurch([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/badwords/?page_size=${sizePerPage}&page=${new_page}`
      if (filterdata !== '') {
        url = url + `&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadData(url, sizePerPage, new_page, searchText)


    }, 1000);
  }

  function handleCreateBadword(e, values) {

    setSubmitting(true)
    post(`${BASE_URL}/api/house_church/badwords/`, values,
      { headers: { 'Content-Type': 'application/json', }, validateStatus: false })
      .then(response => {
        if (response.status >= 400) {
          setSubmitting(false)
          togdata_standard()
          console.log(response, 'denominationresponse')

        } else {
          setSubmitting(false)
          togdata_standard()
          loadData(`${BASE_URL}/api/house_church/badwords/`, 20, 1)
          console.log(response, 'succesdenominationresponse')
        }
      })
  }


  const defaultSorted = [{
    dataField: 'f_name',
    order: 'desc',
    savestate: true
  }];

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]

  }


  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Bad Words' link="/dashboard" />
        <Col xs={12}>
          <Card>
            <CardBody>

              <Row>
                <Col lg='10'>
                </Col>
                <Col lg='2'>
                  {/* <div className="position-absolute">
                    <Button type='button' color='primary' onClick={()=>datatog_standard()}><i className="bx bx-plus"></i>{" "}
                      Create Category</Button>
                  </div> */}
                </Col>
              </Row>

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                data={church}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={church}
                    columns={columns}
                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                    bootstrap5
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <AvForm className="needs-validation" onValidSubmit={FilterData}>
                          <Row className="mb-2">

                            <Col sm="3">
                              <div className="text-sm-start mb-2">
                                <Select
                                  value={search_by}
                                  onChange={(e) => {
                                    setSearchby(e)
                                  }}
                                  options={optionGroup}
                                  classNamePrefix="select2-selection"
                                />
                              </div>


                            </Col>

                            <Col sm="6">
                              {search_by.value ?
                                <Row>
                                  <Col sm="4">
                                    {search_by.value !== null ?
                                      <Row>
                                        <Col sm="12">
                                          <div className="text-sm-end mb-2">
                                            <AvField
                                              name="search"
                                              placeholder={search_by.label}
                                              type="text"
                                              value={search}
                                              onChange={(e) => setSearch(e.target.value)}
                                              className="form-control"
                                              validate={{ required: { value: true } }}
                                            />
                                          </div>

                                        </Col>

                                      </Row>

                                      : null}

                                  </Col>
                                  <Col sm="4">
                                    <div className="text-sm-start mb-2">
                                      <Select
                                        value={order_by}
                                        onChange={(e) => {
                                          setOrder_by(e)
                                        }}
                                        options={sortOptionGroup}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="4">
                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                      Filter
                                    </Button>
                                    {" "}
                                    <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                      Clear
                                    </Button>
                                  </Col>
                                </Row>
                                : null}
                            </Col>

                            <Col sm='3'>
                              <div className='float-end'>
                                <Button type='button' color='primary' onClick={() => { togdata_standard() }}><i className="bx bx-plus"></i>{" "}
                                  Add New</Button>
                              </div>

                            </Col>




                          </Row >
                        </AvForm>

                        <div className="table-responsive">

                          <BootstrapTable
                            remote
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            responsive
                            bordered={false}
                            striped={true}
                            defaultSorted={defaultSorted}
                            hover
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            onTableChange={handleTableChange}
                            noDataIndication={() => NoDataIndication()}
                          // selectRow={selectRow}
                          />

                        </div>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="pagination pagination-rounded mb-2">
                              <PaginationTotalStandalone
                                {...paginationProps}
                              />
                            </div>

                          </Col>
                          <Col sm={6}>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>

                          </Col>
                        </Row>


                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>



            </CardBody>
          </Card>
        </Col>
        {alert_confirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleDerleteWord(seletedWord)}
            onCancel={() => {
              setAlert_confirm(false)
              setSeletedWord(null)
            }
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {success_confirm ? (
          <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setSuccess_confirm(false)
              setAlert_confirm(false)
              setSeletedWord(null)
            }
            }
          >
            {dynamic_description}
          </SweetAlert>
        )
          : null
        }
        <Col lg={6}>

          <Modal
            isOpen={modal_standard}
            toggle={() => {
              togdata_standard()
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Add Bad Word
              </h5>

              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

            </div>

            <div className="modal-body">

              <Col lg='12'>

                {church ?
                  <AvForm className="needs-validation" onValidSubmit={handleCreateBadword}>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label htmlFor="name"> Name</Label>
                          <AvField
                            name="name"
                            placeholder=" Name"
                            type="text"
                            className="form-control"
                            validate={{
                              required: { value: true, errorMessage: 'Please enter a name' },
                              pattern: { value: '^[a-zA-Z0-9 ]*$', errorMessage: 'Your name must be composed only with letter and numbers' },
                              minLength: { value: 6, errorMessage: 'Your name must be between 6 and 16 characters' },
                              maxLength: { value: 16, errorMessage: 'Your name must be between 6 and 16 characters' }
                            }}
                            id="name"

                          />
                        </div>
                      </Col>

                    </Row>

                    {submitting ?
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            togdata_standard()
                          }}
                        >
                          Close
                        </button>
                        <Button color="primary" type="submit">
                          Create
                        </Button>
                      </div>
                      :
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            togdata_standard()
                          }}
                        >
                          Close
                        </button>
                        <Button color="primary" type="submit">
                          Create
                        </Button>
                      </div>

                    }

                  </AvForm>
                  : null}
              </Col>
            </div>

          </Modal>
        </Col>

      </div>


    </React.Fragment>
  );
}
export default Badwords
