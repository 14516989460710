import React, { useEffect, useState } from "react"
import Breadcrumbs from '../../../components/Common/Breadcrumb'


import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { Link } from "react-router-dom"

// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper";
import { HeaderFormatter } from "../../../helpers/methods";
import { ValidateUser } from "../../../helpers/validate_user";

const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Name", value: "name" },
      {label:'State',value:"state"}

    ],
  },
];


const sortOptionGroup = [
  {
    label: "Order by",
    options: [
      { label: "Asc", value: "" },
      { label: "Desc", value: "-" },
    ],
  },
];
const State = (props) => {
  const [church, setChurch] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [loaddata, setLoadData] = useState(false)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [ids, setIds] = useState([])
  const [search, setSearch] = useState("")
  const [search_by, setSearchby] = useState({ label: "Search By", value: null });
  const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });
  const { id } = props.match.params
  const [didMount, setDidMount] = useState(false);
  const [filterdata,setFilterData]=useState(" ")


  ValidateUser(props)


  const columns = [
    {
      dataField: "id",
      text: "S No.",
      hidden: true
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (

       <React.Fragment>
         <Link to="#" className="me-3 text-dark" >{cellContent}</Link>
       </React.Fragment>
      ),
    
    },
    {
        dataField:"state",
        text:"State Name",
        sort:true,
        headerFormatter:HeaderFormatter,
        formatter:(cellContent, row)=>(
            <React.Fragment>
                <Link to='#' className='me-3 text-dark'>{row.state?row.state.name:null}</Link>
            </React.Fragment>
        )
    },
 

  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/house_church/county/`, 20, 1)
    setDidMount(true);

    return () => setDidMount(false);

  }, [id]);

  if (!didMount) {
    return null;
  }

  //get data of category field
  function loadData(url, sizePerPage, page) {
    let data = get(url, { headers: { 'Content-Type': 'application/json', } })
    data.then(response => {
      setChurch(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setLoadData(true)
    })
  }

  
  function ClearFilter() {
    setFilterData("")
    setSearch("")
    setSearchby({ label: "Search By", value: null });

    loadData(`${BASE_URL}/api/house_church/county/`, 20, 1)

  }

  //search field on the basis fo category field and sorting data
  function FilterData(e, values) {
    let filter_data = ""
    if (!search_by.value) {
      toast.error("Select Search Field")
    } else {
      
      filter_data = `${search_by.value}=${values.search}&order_by=${order_by.value}${search_by.value}`
      console.log(filter_data,'filtercountyname')
      setFilterData(filter_data)
      loadData(`${BASE_URL}/api/house_church/county/?${filter_data}`, 20, 1)


    }
  }

  //show Empty data when length of category listing is null 
  function NoDataIndication() {
    if (loaddata && church.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }


  //change the page
  function handleTableChange(type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setChurch([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/county/?page_size=${sizePerPage}&page=${new_page}`
      if (filterdata!==''){
        url=url+`&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadData(url, sizePerPage, new_page, searchText)


    }, 1000);
  }

  //select single select row
  function handleOnSelect(row, isSelect) {
    let id = []
    if (isSelect) {
      id = ids
      id.push(row.id)
    } else {
      for (let i = 0; i < ids.length; i++) {
        if (ids[i] !== row.id) {
          id.push(ids[i])
        }
      }

    }
    setIds(id)
  }
  //select multiple select row
  function handleOnSelectAll(isSelect, rows) {
    if (isSelect) {
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setIds(id)
    } else {
      setIds([])
    }
  }



  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };




  const defaultSorted = [{
    dataField: 'f_name',
    order: 'desc',
    savestate: true
  }];

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]

  }


  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='County' link="/dashboard" />
        <Col xs={12}>
          <Card>
            <CardBody>

              <Row>
                <Col lg='10'>
                </Col>
                <Col lg='2'>
                  {/* <div className="position-absolute">
                    <Button type='button' color='primary' onClick={()=>datatog_standard()}><i className="bx bx-plus"></i>{" "}
                      Create Category</Button>
                  </div> */}
                </Col>
              </Row>

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                data={church}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={church}
                    columns={columns}
                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                    bootstrap5
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <AvForm className="needs-validation" onValidSubmit={FilterData}>
                          <Row className="mb-2">

                            <Col sm="3">
                              <div className="text-sm-start mb-2">
                                <Select
                                  value={search_by}
                                  onChange={(e) => {
                                    setSearchby(e)
                                  }}
                                  options={optionGroup}
                                  classNamePrefix="select2-selection"
                                />
                              </div>


                            </Col>
                            {search_by.value ?
                              <Col sm="8">
                                <Row>
                                  <Col sm="5">
                                    {search_by.value !== null ?
                                        <Row>
                                          <Col sm="12">
                                            <div className="text-sm-end mb-2">
                                              <AvField
                                                name="search"
                                                placeholder={search_by.label}
                                                type="text"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                              />
                                            </div>

                                          </Col>

                                        </Row>

                                        : null}

                                  </Col>
                                  <Col sm="3">
                                    <div className="text-sm-start mb-2">
                                      <Select
                                        value={order_by}
                                        onChange={(e) => {
                                          setOrder_by(e)
                                        }}
                                        options={sortOptionGroup}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="4">
                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                      Filter
                                    </Button>
                                    {" "}
                                    <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                      Clear
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                              : null}



                          </Row >
                        </AvForm>





                        <div className="table-responsive">

                          <BootstrapTable
                            remote
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            responsive
                            bordered={false}
                            striped={true}
                            defaultSorted={defaultSorted}
                            hover
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            onTableChange={handleTableChange}
                            noDataIndication={() => NoDataIndication()}
                            selectRow={selectRow}
                          />

                        </div>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="pagination pagination-rounded mb-2">
                              <PaginationTotalStandalone
                                {...paginationProps}
                              />
                            </div>

                          </Col>
                          <Col sm={6}>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>

                          </Col>
                        </Row>


                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>



            </CardBody>
          </Card>
        </Col>

      </div>

    </React.Fragment>
  );
}
export default State
