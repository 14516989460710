import React, { useState, useEffect } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Label, Button,


} from "reactstrap"



import { toast } from "react-toastify";
import { get, post } from "../../helpers/api_helper"

import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";

import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

import Select from "react-select";

const optionGroup = [
    {
        label: "Days",
        options: [
            { label: "Monday", value: "Monday" },
            { label: "Tuesday", value: "Tuesday" },
            { label: "Wednesday", value: "Wednesday" },
            { label: 'Thursday', value: 'Thursday' },
            { label: 'Friday', value: 'Friday' },
            { label: 'Saturday', value: 'Saturday' },
            { label: 'Sunday', value: 'Sunday' }
        ],
    },

];

const TypeOptionGroup = [
    {
        label: "Type",

        options: [
            { label: "Independent", value: "independent" },
            { label: "Affilate", value: "affilate" }
        ]
    }
];



const CreateHouse = (props) => {
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)
    const [accountUser, setAccountUser] = useState([])
    const [submitting, setSubmitting] = useState(null)

    const [ischecked, setIsChecked] = useState(null)
    const [img, setImg] = useState(null)
    const [name, setName] = useState([])
    const [church, setChurch] = useState([])
    const [selectedDenomination, setselectedDenomination] = useState({ label: "Select Denomination", value: null });
    const [SelectedDay, setSelectedDay] = useState({ label: " Select days", value: null });
    const [SelectedChurch, setSelectedChurch] = useState({ label: 'select church', value: null });
    const [SeletedUser, setSelectedUser] = useState({ label: "Select User", value: null });

    const [selectedType, setSelectedType] = useState({ label: "Select Type", value: null });
    const [errorr, setError] = useState(null)




    useEffect(() => {
        loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false`)
        loadUserData(`${BASE_URL}/api/accounts/users/?status=true&archive=false`)
        loadChurchData(`${BASE_URL}/api/house_church/dash_list_churches/?status=true&archive=false`)



        setDidMount(true);
        get(`${BASE_URL}/api/house_church/dash-list-house_church/${id}/`,
            { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setUser(response.data)
                setImg(response.data.image,)
                console.log(response.data, 'housechurchlist')
            

                setIsChecked(response.data.status)

            })


    }, [id]);




    if (!didMount) {
        return null;
    }
    //when file size is greater than 30kb then file is not consider.this is convert base4image
    const handleFileUpload = (e) => {
        try {
            let reader = new FileReader();
            let file = e.target.files[0];
            if (!file) {
                return;
            }
            const fileSizeInKB = file.size / 1024;
            if (fileSizeInKB > 30) {
                toast.error('Please select an image file smaller than 30 kB');
                setImg(null)
            } else {
                reader.onloadend = () => {
                    var previewImgUrl = reader.result
                    setImg(previewImgUrl)
                }
                reader.readAsDataURL(file);
            }

        } catch (error) {

        }
    }
    //save data of house church and also show data of denomination and also data of church
    // function handleSubmit(e, values) {
      
    //     if (SeletedUser.value === null) {
    //         setError("Please Select Option");


    //     }else{
    //         setSubmitting(true)
    //         let data = { ...values, image: img, status: ischecked, days: SelectedDay.value, type: selectedType.value, user_id: SeletedUser.value, denomination_id: selectedDenomination.value, church_id: SelectedChurch.value }
    //         post(`${BASE_URL}/api/house_church/dash-list-house_church/`, data,
    //             { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
    //         )
    //             .then(response => {
    //                 if (response.status >= 400) {
    
    //                     setSubmitting(false)
                       
                      
                        
    //                 } else {
    //                     setSubmitting(false)
    //                     console.log(response, 'actualhousechurch')
                       
    //                 }if (img){
    //                     toast.error('please select less than 30kb image')
    //                 }
    //             })
    //     }
     
      

    // }
    function handleSubmit(e, values) {
        if (SeletedUser.value === null) {
            setError("Please Select Option");
        } else {
            setSubmitting(true);
            // Check if an image is selected
            if (img) {
                // Convert the image to bytes to calculate its size
                const imageSizeInKB = img.size / 1024;
    
                if (imageSizeInKB > 30) {
                    // Show an error message using the 'toast.error()' function
                    toast.error('Please select an image less than 30 KB');
                    setSubmitting(false); // Set submitting to false to enable the user to try again.
                    return; // Exit the function without making the API call.
                }
            }
    
            let data = { ...values, image: img, status: ischecked, days: SelectedDay.value, type: selectedType.value, user_id: SeletedUser.value, denomination_id: selectedDenomination.value, church_id: SelectedChurch.value };
    
            post(`${BASE_URL}/api/house_church/dash-list-house_church/`, data,
                { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
            )
            .then(response => {
                if (response.status >= 400) {
                    setSubmitting(false);
                } else {
                    setSubmitting(false);
                    console.log(response, 'actualhousechurch');
                }
            });
        }
    }
    

    // show listing of denomination name 
    function loadData(url) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()} })
        data.then(response => {
            const formattedOptions = response.data.results.map(items => ({
                value: items.id,
                label: items.name
            }));



            setName(formattedOptions)



        })
    }
    //show listing of church data when status of church is true and archive is false
    function loadChurchData(url) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()} })
        data.then(response => {
            const formattedOptions = response.data.results.map(items => ({
                value: items.id,
                label: items.name
            }));
            setChurch(formattedOptions)

        })
    }
    //show listing of accounts user when status of user is true and archive is false
    function loadUserData(url) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()} })
        data.then(response => {

            const formattedOptions = response.data.results.map((item) => ({
                value: item.id,
                label: item.first_name + '' + item.last_name
            }));
            setAccountUser(formattedOptions)

        })
    }

    function handleSelectGroup(selectedDenomination) {
        setselectedDenomination(selectedDenomination);
    }

    function handleSelectDayGroup(SelectedDay) {
        setSelectedDay(SelectedDay)
    }
    function handleSelectChurchGroup(SelectedChurch) {
        setSelectedChurch(SelectedChurch)


    }
    function handleSelectUserGroup(SeletedUser) {
        setSelectedUser(SeletedUser)

    }
    function handleSelectTypeGroup(selectedType) {
        setSelectedType(selectedType)
    }
    function handleChurchInputChange(e) {
        loadChurchData(`${BASE_URL}/api/house_church/dash_list_churches/?status=true&archive=false&name=${e}`)
    }
    function handleDenominationChange(e) {
        loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false&name=${e}`)
    }
    function handleUserChange(e) {
        loadUserData(`${BASE_URL}/api/accounts/users/?status=true&archive=false&first_name=${e}`)
    }



    return (
        <React.Fragment>
            <div className="page-content">


                <Breadcrumbs title="House Church" breadcrumbItem={"House Church"} link="/house-churches" />


                <Row>
                    <Col lg={12}>



                        {user ?
                            <Row>
                                <Col md="12">

                                    <Row>

                                        <Col sm="10">
                                            <AvForm className="needs-validation" onValidSubmit={handleSubmit} >
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle>House Church </CardTitle>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="name">Name</Label>
                                                                    <AvField
                                                                        name="name"
                                                                        placeholder="Name"
                                                                        type="text"
                                                                        className="form-control"
                                                                        validate={{
                                                                            required: { value: true, errorMessage: 'Please enter a name' },
                                                                            pattern: { value: '^[a-zA-Z0-9 ]*$', errorMessage: 'Your name must be composed only with letter and numbers' },
                                                                            minLength: { value: 6, errorMessage: 'Your name must be between 6 and 16 characters' },
                                                                            maxLength: { value: 16, errorMessage: 'Your name must be between 6 and 16 characters' }
                                                                        }}
                                                                        id="name"
                                                                        value={user.name}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="address">Address</Label>
                                                                    <AvField
                                                                        name="address"
                                                                        placeholder="Address"
                                                                        type="text"
                                                                        errorMessage=" Please Enter Address."
                                                                        className="form-control"

                                                                        id="address"
                                                                        value={user.address}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="city">City</Label>
                                                                    <AvField
                                                                        name="city"
                                                                        placeholder="City"
                                                                        type="text"
                                                                        errorMessage=" Please provide a valid City."
                                                                        className="form-control"

                                                                        id="city"
                                                                        value={user.city}
                                                                    />
                                                                </div>


                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="state">State</Label>
                                                                    <AvField
                                                                        name="state"
                                                                        placeholder="State"
                                                                        type="text"
                                                                        errorMessage=" Please provide a valid State."
                                                                        className="form-control"

                                                                        id="state"
                                                                        value={user.state}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md="6">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="zipcode">Zip Code</Label>
                                                                    <AvField
                                                                        name="zipcode"
                                                                        placeholder="ZipCode"
                                                                        type="text"
                                                                        errorMessage=" Please provide a valid ZipCode."
                                                                        className="form-control"

                                                                        id="zipcode"
                                                                        value={user.zipcode}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md="6">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="user_id">User</Label>

                                                                    <Select
                                                                        value={SeletedUser}
                                                                        onChange={(e) => {
                                                                            handleSelectUserGroup(e);
                                                                        }}
                                                                        onInputChange={(e) => handleUserChange(e)}
                                                                        options={accountUser}


                                                                        required />
                                                                    {/* {errorr && <div className="errorr" color='red'>{errorr}</div>} */}
                                                                    {/* {errorr && <p style={{'color':'red'}}>Please select a user Option.</p>} */}
                                                                    {errorr && <div>
                                                                   
                                                                         <pre style={{'color':'red'}}>{(errorr, null, 'Select User Option')}</pre>
                                                                    </div>}


                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={6}>

                                                                <div className="mb-3">
                                                                    <Label htmlFor="church">Church</Label>
                                                                    <Select
                                                                        value={SelectedChurch}
                                                                        onChange={(e) => {
                                                                            handleSelectChurchGroup(e);
                                                                        }}
                                                                        onInputChange={(e) => handleChurchInputChange(e)}
                                                                        options={church}

                                                                        classNamePrefix="select2-selection"
                                                                    />



                                                                </div>
                                                            </Col>

                                                            <Col sm={6}>

                                                                <div className="mb-3">
                                                                    <Label htmlFor="name">Denomination</Label>
                                                                    <Select
                                                                        value={selectedDenomination}
                                                                        onChange={(e) => {
                                                                            handleSelectGroup(e);
                                                                        }}
                                                                        onInputChange={(e) => handleDenominationChange(e)}
                                                                        options={name}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>

                                                            </Col>

                                                        </Row>

                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="days">Days</Label>
                                                                    <Select
                                                                        value={SelectedDay}
                                                                        onChange={(e) => {
                                                                            handleSelectDayGroup(e);
                                                                        }}
                                                                        options={optionGroup}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="time">Time</Label>
                                                                    <AvField
                                                                        name="time"
                                                                        placeholder="Time"
                                                                        type="time"
                                                                        errorMessage=" Please provide a valid TIme."
                                                                        className="form-control"

                                                                        id="time"
                                                                        value={user.time}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="special_inst">Special Inst</Label>
                                                                    <AvField
                                                                        name="special_inst"
                                                                        placeholder="Special Inst"
                                                                        type="text"
                                                                        errorMessage=" Please provide a valid Special Inst."
                                                                        className="form-control"

                                                                        id="special_inst"
                                                                        value={user.special_inst}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="type">Type</Label>
                                                                    <Select
                                                                        value={selectedType}
                                                                        onChange={(e) => {
                                                                            handleSelectTypeGroup(e);
                                                                        }}
                                                                        options={TypeOptionGroup}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="latitude">Latitude</Label>
                                                                    <AvField
                                                                        name="latitude"
                                                                        placeholder="Latitude"
                                                                        type="text"
                                                                        errorMessage=" Please provide a valid Latitude."
                                                                        className="form-control"

                                                                        id="latitude"
                                                                        value={user.latitude}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="longitude">Longitude</Label>
                                                                    <AvField
                                                                        name="longitude"
                                                                        placeholder="longitude"
                                                                        type="text"
                                                                        errorMessage=" Please provide a valid longitude."
                                                                        className="form-control"

                                                                        id="longitude"
                                                                        value={user.longitude}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="info">Info</Label>
                                                                    <AvField
                                                                        name="info"
                                                                        placeholder="Info"
                                                                        type="textarea"
                                                                        errorMessage=" Please provide a valid Info."
                                                                        className="form-control"

                                                                        id="info"
                                                                        value={user.info}
                                                                    />
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                        <Row>

                                                            <Col md={6}>
                                                                <div className="checkbox-wrapper">
                                                                    <label>
                                                                        <input type="checkbox"
                                                                            checked={ischecked}
                                                                            onChange={(e) => setIsChecked(e.target.checked)}
                                                                            value={user.status}

                                                                        />{" "}
                                                                        Status
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Label>Image</Label>{" "}
                                                                <input
                                                                    type="file"
                                                                    id="image"
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={(e) => handleFileUpload(e)}
                                                                    value={user.image}
                                                                />
                                                            </Col>
                                                        </Row>


                                                        {submitting ?
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary waves-effect waves-light my-3"
                                                            >
                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                                Creating
                                                            </button>
                                                     

                                                            :
                                                            <Button color="primary" type="submit">
                                                                Create
                                                            </Button>
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </AvForm>

                                        </Col>

                                    </Row>


                                </Col>

                            </Row>
                            :
                            <div id="preloader">
                                <div id="status">
                                    <div className="spinner-chase">
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                    </div>
                                </div>
                            </div>
                        }





                    </Col>



                </Row>


            </div>

        </React.Fragment>
    )
}

export default CreateHouse
