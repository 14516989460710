import React,{useState,useEffect} from "react"
import { Row, Col, CardBody, Card } from "reactstrap"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import './data.css';

const Dashboard = (props) => {

  const [totalusers,setTotalUsers]=useState(0)
  const [totalchurches,setTotalChurches]=useState(0)
  const [allhouses,setAllHouse]=useState(0)
  const [userregistrations,setUserRegistrations]=useState(0)
  const [housechurchregistrations,setHouseChurchRegistrations]=useState(0)
  const [orgregistrations,setOrgRegistrations]=useState(0)
  const [flaggedusercount,setFlaggedUserCount]=useState(0)
  const [flaggedhousechurchcount,setFlaggedHouseChurchCount]=useState(0)
  const [upcomingeventcount,setUpcomingEventCount]=useState(0)
  const [flaggedEventsReportscount,setFlaggedEventsReportsCount]=useState(0)
  const [flaggedhousechurchReportscount,setFlaggedHouseChurchReportsCount]=useState(0)
  


  useEffect(()=>{
    loadData(`${BASE_URL}/api/house_church/dashboard-data/`)
    // driverObj.drive();
  },[])

  function loadData(url){
    let data=get(url,{headers:{'Content-Type':'aplication/json','Authorization': getAccessToken() }})
    data.then(response=>{
      setTotalUsers(response.data.total_users)
      setTotalChurches(response.data.total_churches)
      setAllHouse(response.data.all_houses)
      setUserRegistrations(response.data.user_registrations)
      setHouseChurchRegistrations(response.data.house_church_registrations)
      setOrgRegistrations(response.data.organization_count)
      setFlaggedUserCount(response.data.flagged_user_count)
      setFlaggedHouseChurchCount(response.data.flagged_house_church_count)
      setUpcomingEventCount(response.data.upcoming_event_count)
      setFlaggedHouseChurchReportsCount(response.data.flagged_house_post)
      setFlaggedEventsReportsCount(response.data.flagged_events_post)


    })
    
  }

  const driverObj = driver({
    showProgress: true,
    popoverClass: 'driverjs-theme',
    // allowClose: false,
    steps: [
  
      { popover: { title: 'Dashboard Tour', description: 'Welcome to the Find A House Church.' }},
      { element: '#Total_Users', popover: { title: 'Total Users', description: 'Here is the code example showing animated tour. Let\'s walk you through it.', side: "left", align: 'start' }},
      { element: '#Churches', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#House_Churches', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#Violations', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#Registered_Users', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#Registered_House_Church', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#Registered_Church', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#Flagged_Profiles', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#Flagged_Houses', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#Upcoming_Event', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#Flagged_Events_Posts', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { element: '#Flagged_Houses_Church_Posts', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
      { popover: { title: 'Happy Coding', description: "<img src='https://i.imgur.com/EAQhHu5.gif' style='height: 202.5px; width: 270px;' /><span style='font-size: 15px; display: block; margin-top: 10px; text-align: center;'>Over to You.</span>" } }
    
    ]
  });

  return (
    <React.Fragment>
      <div className="page-content">
  
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to FAHC Dashboard</li>
                </ol>
              </div>
            </div>
          </div>
        </Row>
        
     
  
        <Row >
         <Col lg='3' >
              <Card id="Total_Users" >
                <CardBody >
                  <div className="d-flex align-items-start" >
                    <div className="avatar-sm font-size-20 me-3">
                      <span className="avatar-title bg-soft-primary text-primary rounded">
                        <i className="mdi mdi-account-multiple-outline"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <div className="font-size-16 mt-2">Total Users</div>
                    </div>
                  </div>
                  <h4 className="mt-4">{totalusers}</h4>
                  <Row>
                    <div className="col-7">
                      <p className="mb-0">
                        
                        <a href='/users'>
                        <span className="text-success me-2">View Users</span>
                        </a>
                        <i className=""></i>
                      </p>
                    </div>
                  </Row>
                </CardBody>
              </Card>
         </Col>
         <Col lg='3'>

         
            <Card id="Churches">
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="fas fa-church"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Churches</div>

                  </div>
                </div>
                <h4 className="mt-4">{totalchurches}</h4>
                <Row>
                  <div className="col-7">
                    <p className="mb-0"></p>
                      <a href='/all-churches'><span className="text-success me-2"> View Church <i
                      className=""></i> </span></a>
                  </div>
                 
                </Row>
              </CardBody>
            </Card>
         </Col>
         <Col lg='3'>

     
            <Card id="House_Churches">
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="fas fa-home fa-church"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">House Churches</div>

                  </div>
                </div>
                <h4 className="mt-4">{allhouses}</h4>
                <Row>
                  <div className="col-7">
                    <p className="mb-0"></p>
                      <a href='/house-churches'><span className="text-success me-2"> View Houses <i
                      className=""></i> </span></a>
                  </div>
                
                </Row>
              </CardBody>
            </Card>
         </Col>
         <Col lg='3'>
        
            <Card id="Violations">
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="fas fa-exclamation-circle"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Violations</div>

                  </div>
                </div>
                <h4 className="mt-4">{flaggedusercount + flaggedhousechurchcount + flaggedEventsReportscount + flaggedhousechurchReportscount}</h4>
              
                <Row>
                  <div className="col-7">
                    <p className="mb-0"></p>
                      <a href='/flagged-house-church'><span className="text-success me-2"> <i
                      className=""></i> </span></a>
                  </div>
              
                </Row>
               
              </CardBody>
            </Card>
         </Col>
        
       
        </Row>
        <Row >
         <Col lg='4' >
        
           <Card id="Registered_Users">
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Users Registered Last 30 Days</div>

                  </div>
                </div>
                <h4 className="mt-4">{userregistrations}</h4>
              
              </CardBody>
            </Card>
         </Col>
         <Col lg='4'>

         
            <Card id='Registered_House_Church'>
              <CardBody>
                <div className="d-flex align-items-end">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="fas fa-church"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">House Churches Registered Last 30 Days</div>

                  </div>
                </div>
                <h4 className="mt-4">{housechurchregistrations}</h4>
               
              </CardBody>
            </Card>
         </Col>
         <Col lg='4'>

     
            <Card id='Registered_Church'>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="fas fa-home fa-church"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Organization Registered Last 30 Days</div>

                  </div>
                </div>
                <h4 className="mt-4">{orgregistrations}</h4>
               
              </CardBody>
            </Card>
         </Col>
       
        
       
        </Row>
        <Row >
         <Col lg='4' >
        
           <Card id="Flagged_Profiles">
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Flagged Profiles</div>

                  </div>
                </div>
                <h4 className="mt-4">{flaggedusercount}</h4>
                <Row>
                
                
                </Row>
              </CardBody>
            </Card>
         </Col>
         <Col lg='4'>

         
            <Card id='Flagged_Houses'>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="fas fa-church"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Flagged Houses</div>

                  </div>
                </div>
                <h4 className="mt-4">{flaggedhousechurchcount}</h4>
                <Row>
                 
                </Row>
              </CardBody>
            </Card>
         </Col>
         <Col lg='4'>

     
            <Card id='Upcoming_Event'>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="bx bx-calendar-event"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Upcoming Event</div>

                  </div>
                </div>
                <h4 className="mt-4">{upcomingeventcount}</h4>
                <Row>
                 

                </Row>
              </CardBody>
            </Card>
         </Col>
       
        
       
        </Row>
        <Row >
         <Col lg='4'>
        
           <Card  id='Flagged_Events_Posts'>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Flagged Events Posts</div>

                  </div>
                </div>
                <h4 className="mt-4">{flaggedEventsReportscount}</h4>
                <Row>
                
                
                </Row>
              </CardBody>
            </Card>
         </Col>
         <Col lg='4'>

         
            <Card id='Flagged_Houses_Church_Posts'>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="fas fa-church"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Flagged Houses Church Posts</div>

                  </div>
                </div>
                <h4 className="mt-4">{flaggedhousechurchReportscount}</h4>
                <Row>
                 
                </Row>
              </CardBody>
            </Card>
         </Col>
       
        </Row>
        
      
    
      </div>
    </React.Fragment>
  )
}

export default Dashboard