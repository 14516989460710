import React, { useState, useEffect } from "react";

import { BASE_URL } from "../../helpers/url_helper";
// import { toast } from 'react-toastify';


import { Row, Col } from "reactstrap"

import { Link } from "react-router-dom"

import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { del } from "../../helpers/api_helper"
import { HeaderFormatter } from "../../helpers/methods";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"
import SweetAlert from "react-bootstrap-sweetalert";


const HouseChurchEventReplies = (props) => {
    console.log(props.eventReplies, "--------------props")
    // const state = props.location.statedata
    const id  = 1288
    // const [didMount, setDidMount] = useState(false);
    const [sizePerPage, setsizePerPage] = useState(20)

    const [postPage, setPostPage] = useState(1)
    const [postTotalSize, setPostTotalSize] = useState(props?.eventReplies?.length);
    const [posts, setPosts] = useState(props?.eventReplies)
    const [selectedPostMessage, setSelectedMessage] = useState(null)

    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")


    const postColumn = [
        {
            dataField: "id",
            text: "S No.",
            hidden: true

        }, 
        {
            dataField: "user",
            text: "Name",
            isDummyField: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { 'url': `/edit-house-church/${id}`, 'page': "Edit Church" } }} className="me-3 text-dark">{row.user.first_name}{" "}{row.user.last_name}</Link>

            )
        },
        {
            dataField: "message",
            text: "Message",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <a href={`https://findahousechurch.com/post/house-church/${props?.uuid}`} rel="noreferrer" target="_blank" className="me-3 text-dark" >  {cellContent}</a>
            )
        },
        {
            dataField: "type",
            text: "Type",
            isDummyField: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <React.Fragment>
                <a href={`https://findahousechurch.com/post/house-church/${props?.uuid}`} rel="noreferrer" target="_blank" className="me-3 text-dark" >  <i className="mdi mdi-eye" style={{ 'fontSize': '20px' }}></i></a>
                <Link to='#' onClick={() => {setSelectedMessage(row); setAlert_confirm(true)}} className="text-danger"><i className="mdi mdi-delete"></i></Link>
                </React.Fragment>
            )

        },
    ]



    // useEffect(() => {
    //     localStorage.setItem("ID_EditEvent", id);
    //     loadPosts(`${BASE_URL}/api/house_church/dash-post/?event_id=${id}`)
    //     setDidMount(true);
    // }, []);

    // if (!didMount) {
    //     return null;
    // }

    function postsNoDataIndication() {
        if ( posts.length === 0) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )
        }
    }



    function handlePostsTableChange(type, { page, sizePerPage, searchText, }) {
        setTimeout(() => {
            setPosts([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/dash-post/?event_id=${id}&page_size=${sizePerPage}&page=${new_page}`
            window.scrollTo(0, 0)
            // loadPosts(url, sizePerPage, new_page)
        }, 100);

    }

    function handleDeletePost(post_id){

        console.log(post_id)
        del(`${BASE_URL}/api/house_church/dash-house-posts/${post_id}/`,
            { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } },
            { validateStatus: false }
        )
            .then(res => {
            if (res.status >= 400) {
                
            }
            else {
                window.location.reload()

            }
            }
            )
    }


    const defaultSorted = [{
        dataField: 'user_email',
        order: 'desc',
        savestate: true
    }];


    const PostsPageOptions = {
        page: postPage,
        sizePerPage: sizePerPage,
        totalSize: postTotalSize,
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }

    return (
        <React.Fragment>
        

            

            <PaginationProvider
                pagination={paginationFactory(PostsPageOptions)}
                data={posts}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField="id"
                            data={posts}
                            columns={postColumn}
                            remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                            bootstrap5
                            search
                        >
                            {toolkitProps => (
                                <React.Fragment>
                                

                                    <div className="table-responsive">

                                        <BootstrapTable
                                            remote
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            responsive
                                            bordered={false}
                                            striped={true}
                                            defaultSorted={defaultSorted}
                                            hover
                                            classes={
                                                "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            onTableChange={handlePostsTableChange}
                                            noDataIndication={() => postsNoDataIndication()}
                                        // selectRow={selectRow}
                                        />

                                    </div>
                                    <Row>
                                        <Col sm={6}>
                                            <div className="mb-3">
                                                <SizePerPageDropdownStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="pagination pagination-rounded mb-2">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                />
                                            </div>

                                        </Col>
                                        <Col sm={6}>
                                            <div className="pagination pagination-rounded justify-content-end mb-2">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>

                                        </Col>
                                    </Row>


                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    )}
                </PaginationProvider>
       
            {alert_confirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleDeletePost(selectedPostMessage.id)}
            onCancel={() => {
              setAlert_confirm(false)
              setSelectedMessage(null)
            }
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {success_confirm ? (
          <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setSuccess_confirm(false)
              setSelectedMessage(false)

            }
            }
          >
            {dynamic_description}
          </SweetAlert>
        )
          : null
        }

        </React.Fragment>
    )
}

export default HouseChurchEventReplies
