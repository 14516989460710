import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { get, put } from "../../helpers/api_helper";
import { toast } from 'react-toastify';
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

const UserDetail = (props) => {
    const state = props.location.statedata
    console.log(state, "-------------------------------------")
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)
    const [ischecked, setIsChecked] = useState(null)

    useEffect(() => {
        setDidMount(true);
        // loadEditHouseChurch(`${BASE_URL}/api/house_church/dash-list-house_church/${id}`)
        get(`${BASE_URL}/api/accounts/users/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setUser(response.data)
            
                setIsChecked(response.data.status)
            if (response.data.id===undefined){
                props.history.push('/users')
            }

            })
        // return () => setDidMount(false);

    }, [id,props.history]);

    if (!didMount) {
        return null;
    }
    

    function handleSubmit(e, values) {
        put(`${BASE_URL}/api/accounts/users/${id}/`, { ...values, status: ischecked },
            { headers: { 'Content-Type': 'application/json', }, validateStatus: false }
        )
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Somthing Went Wrong")
                } else {
                    props.history.push("/users")
                }
            })

    }
   
    console.log(state, "------state--")
    return (
        <React.Fragment>
            <div className="page-content" >

                
                    <Breadcrumbs title={state?.page?state.page:"Users"} breadcrumbItem={"User Detail"}     link={state?.url?state.url:"/users"}  />


                {user ?
                    <Row>
                        <Col md="12">

                            <Row>
                                
                                <Col sm="8">
                                    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle>User Details </CardTitle>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="image">User Name</Label>
                                                            <AvField
                                                                name="username"
                                                                placeholder="User Name"
                                                                type="text"
                                                                className="form-control"
                                                                disabled
                                                                id="username"
                                                                value={user.username}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="email">Email</Label>
                                                            <AvField
                                                                name="email"
                                                                placeholder="Enter Email"
                                                                type="email"
                                                                errorMessage=" Please Enter Email."
                                                                className="form-control"
                                                                disabled
                                                                id="email"
                                                                value={user.email}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="first_name">First Name</Label>
                                                            <AvField
                                                                name="first_name"
                                                                placeholder="First Name"
                                                                type="text"
                                                                errorMessage=" Please Enter First Name."
                                                                className="form-control"
                                                                disabled


                                                                id="first_name"
                                                                value={user.first_name}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="last_name">Last Name</Label>
                                                            <AvField
                                                                name="last_name"
                                                                placeholder="Last Name"
                                                                type="text"
                                                                errorMessage=" Please Enter Last Name."
                                                                className="form-control"
                                                                disabled

                                                                id="last_name"
                                                                value={user.last_name}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>

                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="address">Address</Label>
                                                            <AvField
                                                                name="address"
                                                                placeholder="Address"
                                                                type="text"
                                                                errorMessage=" Please Enter Address."
                                                                className="form-control"
                                                                disabled

                                                                id="address"
                                                                value={user.address}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="city">City</Label>
                                                            <AvField
                                                                name="city"
                                                                placeholder="City"
                                                                type="text"
                                                                errorMessage=" Please provide a valid city."
                                                                className="form-control"
                                                                disabled

                                                                id="city"
                                                                value={user.city}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="state">State</Label>
                                                            <AvField
                                                                name="state"
                                                                placeholder="State"
                                                                type="text"
                                                                errorMessage=" Please provide a valid State."
                                                                className="form-control"
                                                                disabled

                                                                id="state"
                                                                value={user.state}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="zipcode">Zip</Label>
                                                            <AvField
                                                                name="zipcode"
                                                                placeholder="Zip Code"
                                                                type="text"
                                                                className="form-control"
                                                                disabled

                                                                id="zipcode"
                                                                value={user.zipcode}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                                <Row>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="phone"
                                                                label="Phone"
                                                                placeholder="Contact Number"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                disabled
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9+-]+$",
                                                                        errorMessage: "Invalid Contact"
                                                                    },
                                                                }}
                                                                value={user.phone}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="company">Company</Label>
                                                            <AvField
                                                                name="company"
                                                                placeholder="Company"
                                                                type="text"
                                                                className="form-control"
                                                                disabled
                                                                id="company"
                                                                value={user.company}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="website">Website</Label>
                                                            <AvField
                                                                name="website"
                                                                placeholder="Website URL"
                                                                type="text"
                                                                className="form-control"
                                                                disabled

                                                                id="website"
                                                                value={user.website}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="fb">Facebook</Label>
                                                            <AvField
                                                                name="fb"
                                                                placeholder="Facebook URL"
                                                                type="text"
                                                                className="form-control"
                                                                disabled

                                                                id="fb"
                                                                value={user.fb}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="insta">Instagram</Label>
                                                            <AvField
                                                                name="insta"
                                                                placeholder="Instagram URL"
                                                                type="text"
                                                                className="form-control"
                                                                disabled 

                                                                id="insta"
                                                                value={user.insta}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="twitter">Twitter</Label>
                                                            <AvField
                                                                name="twitter"
                                                                placeholder="Twitter URL"
                                                                type="text"
                                                                className="form-control"
                                                                disabled

                                                                id="twitter"
                                                                value={user.twitter}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="latitude">Latitude</Label>
                                                            <AvField
                                                                name="latitude"
                                                                placeholder="Latitude"
                                                                type="text"
                                                                className="form-control"
                                                                disabled

                                                                id="latitude"
                                                                value={user.latitude}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="longitude">Longitude</Label>
                                                            <AvField
                                                                name="longitude"
                                                                placeholder="Longitude"
                                                                type="text"
                                                                className="form-control"
                                                                disabled

                                                                id="longitude"
                                                                value={user.longitude}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>

                                                    <Col md="6">
                                                        <Label htmlFor='about'>About</Label>
                                                        <AvField
                                                            // className="mb-3"
                                                            // type="textarea"
                                                            // label="About"
                                                            // rows="3"
                                                            // name="about"
                                                            // id="about"
                                                            // value={user.about}
                                                            name='about'
                                                            placeholder='About'
                                                            type='textarea'
                                                            className='form-control'
                                                            disabled

                                                            id="about"
                                                            value={user.about}
                                                            

                                                        />
                                                    </Col>
                                                    <Col md="6">
                                                    <div className="checkbox-wrapper mb-5" style={{'marginTop':'40px'}}>
                                                            <label>
                                                                <input type="checkbox"
                                                                    checked={ischecked}
                                                                    disabled
                                                                    onChange={(e) => setIsChecked(e.target.checked)} /> {"      "} {"      "}
                                                                Status
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* {submitting ?
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary waves-effect waves-light my-3"
                                                    >
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                        Updating
                                                    </button>
                                                    :
                                                    <Button color="primary" type="submit">
                                                        Update
                                                    </Button>
                                                } */}
                                            </CardBody>
                                        </Card>
                                    </AvForm>
                                </Col>

                            </Row>


                        </Col>
                        
                    </Row>
                    :
                    <div id="preloader">
                        <div id="status">
                            <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                            </div>
                        </div>
                    </div>
                }

            </div>

        </React.Fragment>
    )
}

export default UserDetail