import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { post } from "../../helpers/api_helper";
import { toast } from 'react-toastify';
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

const CreateDenomination = (props) => {

    const [didMount, setDidMount] = useState(false);
    const [submitting, setSubmitting] = useState(null)

    useEffect(() => {
        setDidMount(true);
        return () => setDidMount(false);

    }, []);

    if (!didMount) {
        return null;
    }

    function handleSubmit(e, values) {
        setSubmitting(true)
        let data = values
        data['status'] = document.getElementById('statuss').checked

        post(`${BASE_URL}/api/house_church/denominations/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Somthing Went Wrong")
                } else {
                    props.history.push("/denominations")
                }
            })
        console.log(data, "--------------hjhjhj")
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Denominations" breadcrumbItem={`Create Denomination`} link="/denominations" />

                <Row>
                    <Col lg="6">
                        <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Denomination Details</CardTitle>
                                    <Row>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <Label htmlFor="name"> Name</Label>
                                                <AvField
                                                    name="name"
                                                    placeholder=" Name"
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="page_title">Page Title</Label>
                                                <AvField
                                                    name="page_title"
                                                    placeholder="Page Title"
                                                    type="text"
                                                    errorMessage=" Please provide a valid Detail."
                                                    className="form-control"
                                                    id="page_title"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="page_description">Page Description</Label>
                                                <AvField
                                                    name="page_description"
                                                    placeholder="Page Description"
                                                    type="textarea"
                                                    errorMessage=" Please provide a valid Info."
                                                    className="form-control"
                                                    id="page_description"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="meta_title">Meta Title</Label>
                                                <AvField
                                                    name="meta_title"
                                                    placeholder="Meta Title"
                                                    type="text"
                                                    errorMessage=" Please provide a valid Detail."
                                                    className="form-control"
                                                    id="meta_title"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="meta_description">Meta Description</Label>
                                                <AvField
                                                    name="meta_description"
                                                    placeholder="Meta Description"
                                                    type="textarea"
                                                    errorMessage=" Please provide a valid Info."
                                                    className="form-control"
                                                    id="meta_description"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className="checkbox-wrapper">
                                                <label>
                                                    <input
                                                        id="statuss"
                                                        name="status"
                                                        type="checkbox"
                                                        defaultChecked={true}
                                                    />{"   "}
                                                    Status
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    {submitting ?
                                        <button
                                            type="button"
                                            className="btn btn-primary waves-effect waves-light my-3"
                                        >
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                            Submitting
                                        </button>
                                        :
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    }

                                </CardBody>
                            </Card>
                        </AvForm>
                    </Col>

                </Row>

            </div>

        </React.Fragment>
    )
}

export default CreateDenomination
