import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { post } from "../../helpers/api_helper";

const roleOptions = [
    {
      label: "Admin Type",
      options: [
        {value: 0, label: "Super Admin"},
        {value: 1, label: "Sub Admin"},
        
        ],
    },
  ];


const NewUser = (props) => {
    
    const [userRole, setUserRole] = useState({value: 1, label: "Sub Admin"});
    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
        
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
      }

    function handleSelectHearFrom(selectedUserRole) {
      setUserRole(selectedUserRole);
    }


    function handleSubmit(e, values){
      let data =  values
      data["role"] = userRole.value
      post(`${BASE_URL}/api/accounts/users/`, data,
      { headers: { 'Content-Type': 'application/json', }, validateStatus: false}  
      )
      .then(response => {
          if(response.status >= 400){
          }else{
              props.history.push("/admins")
          }
      })
        
    }

    return (
        <React.Fragment>
          <div className="page-content">

            <Breadcrumbs title="Users" breadcrumbItem={"New User"} link="/admins" />

            <Row>
              <Col md="12">
                <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                  <Row>
                    <Col sm="6">
                      <Card>
                        <CardBody>
                          <CardTitle>User Details </CardTitle> 
                          <Row className="mb-3">
                              <Label htmlFor="username" className="col-md-3 col-form-label">Login Name <span style={{color:'red'}}>*</span></Label>
                              <div className="col-md-9">
                                  <AvField
                                      name="username"
                                      placeholder="Login Name"
                                      type="text"
                                      errorMessage="Enter Login Name"
                                      className="form-control"
                                      validate={{ required: { value: true },
                                                  pattern: {value: '^[a-z0-9_]+$', errorMessage: 'Login name must be composed only with small letter, numbers and _.'}, 
                                              }}
                                      id="username"
                                  />
                              </div>
                          </Row>
                          <Row className="mb-3">
                              <Label htmlFor="first_name" className="col-md-3 col-form-label">First Name <span style={{color:'red'}}>*</span></Label>
                              <div className="col-md-9">
                                  <AvField
                                      name="first_name"
                                      placeholder="First Name"
                                      type="text"
                                      errorMessage="Enter First Name"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="first_name"
                                  />
                              </div>
                          </Row>
                          <Row className="mb-3">
                              <Label htmlFor="last_name" className="col-md-3 col-form-label">Last Name <span style={{color:'red'}}>*</span></Label>
                              <div className="col-md-9">
                                  <AvField
                                      name="last_name"
                                      placeholder="Last Name"
                                      type="text"
                                      errorMessage="Enter Last Name"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="last_name"
                                  />
                              </div>
                          </Row>
                          <Row className="mb-3">
                              <Label htmlFor="email" className="col-md-3 col-form-label">Email <span style={{color:'red'}}>*</span></Label>
                              <div className="col-md-9">
                                  <AvField
                                      name="email"
                                      placeholder="Email"
                                      type="email"
                                      errorMessage="Enter Email"
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="email"
                                  />
                              </div>
                          </Row>
                          <Row className="mb-3">
                              <Label htmlFor="password" className="col-md-3 col-form-label">Password <span style={{color:'red'}}>*</span></Label>
                              <div className="col-md-9">
                                <AvField
                                    name="password"
                                    placeholder="Email"
                                    type="password"
                                    errorMessage="Enter Email"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    id="password"
                                />
                              </div>
                          </Row>
                          <Row className="mb-3">
                              <Label htmlFor="password" className="col-md-3 col-form-label"></Label>
                              <div className="col-md-9">
                                <AvField
                                  name="password1"
                                  type="password"
                                  placeholder="Re-type Password"
                                  errorMessage="Enter Re-password"
                                  validate={{
                                      required: { value: true },
                                      match: { value: "password" },
                                  }}
                                  />
                              </div>
                          </Row>
                          <Row className="mb-3">
                              <Label htmlFor="role" className="col-md-3 col-form-label">Admin Type</Label>
                              <div className="col-md-9">
                                  <Select
                                      value={userRole}
                                      onChange={(e) => {
                                          handleSelectHearFrom(e);
                                      }}
                                      options={roleOptions}
                                      classNamePrefix="select2-selection"
                                      id="role"
                                      />
                              </div>
                          </Row>
                          
                          <Button color="primary" type="submit">
                              Submit
                          </Button>      
                        </CardBody>
                      </Card>
                    </Col>
                  
                  </Row>
                    
                </AvForm>
              </Col>
            </Row>

          </div>

        </React.Fragment>
    )
}

export default NewUser
