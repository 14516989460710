import React, { useState, useEffect } from "react";
import {
    Row, Col, Card, CardBody, Label, Button, Spinner,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
} from "reactstrap"
import classnames from "classnames"
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get, put, del } from "../../helpers/api_helper"

import { HeaderFormatter } from "../../helpers/methods";
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

import { Link } from "react-router-dom"
import moment from "moment-timezone";
import Select from "react-select";


const EditOrganization = (props) => {
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)
    const [submitting, setSubmitting] = useState(null)
    const [totalSize, settotalSize] = useState(0);
    const [sizePerPage, setsizePerPage] = useState(20)
    const [page, setpage] = useState(1)
    const [name, setName] = useState([])
    const [selectedDenomination, setselectedDenomination] = useState({ label: "Select Denomination", value: null });

    const [activeTab, setactiveTab] = useState("1")

    const [pageTitle, setPageTitle] = useState('House Church')

    const [loadHouseChurch, setloadHouseChurch] = useState(false)
    const [houseChurches, setHouseChurches] = useState([])
    const [church, setChurch] = useState([]);


    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    function loadHouseData(url, sizePerPage, page) {
        setloadHouseChurch(false)
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setHouseChurches(response.data.results)
            settotalSize(response.data.count)
            setpage(page)
            setsizePerPage(sizePerPage)
            setloadHouseChurch(true)
        })
    }

    const columns = [
        {
            dataField: "id",
            text: "S No.",
            hidden: true

        },
        {
            dataField: "user_id",
            text: "Owner",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{row.user_id ? row.user_id.first_name + " " + row.user_id.last_name : null}</Link>
            ),
        },


        {
            dataField: "name",
            text: "Name",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (

                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{cellContent}</Link>
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "user_id.phone",
            text: "Phone",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{cellContent}</Link>
            )
            ,
        },
        {
            dataField: "user_id.email",
            text: "Email",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{cellContent}</Link>
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "created_at",
            text: "Created",
            headerFormatter: HeaderFormatter,
            // sort: true,
            formatter: (cellContent, row) => (
                moment(cellContent).tz("America/Chicago").format("MM-DD-YYYY")
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "site",
            isDummyField: true,
            headerFormatter: HeaderFormatter,
            text: "site",
            formatter: (cellContent, row) => (
                <React.Fragment>
                    <a href={`https://findahousechurch.com/house-church/${row.slug}`} target="__blank" className="me-3 text-primary" >  <i className="mdi mdi-search-web"></i></a>
                </React.Fragment>

            ),
        },
    ]

    useEffect(() => {
        loadOrganization()
        loadHouseData(`${BASE_URL}/api/house_church/dash-list-house_church/?organization=${id}`, 20, 1)

        setDidMount(true);


    }, []);

    if (!didMount) {
        return null;
    }


    function loadOrganization() {
        get(`${BASE_URL}/api/house_church/organization/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setUser(response.data)
                setPageTitle(response.data.name)

                if (response.data.denomination_id) {
                    setselectedDenomination({ label: response.data.denomination_id.name, value: response.data.denomination_id.id })

                }
                if (response.data.id === undefined) {
                    props.history.push('/all-churches')
                }
            })
    }


    //get listing of denomination when status is true and archive is false
    function loadData(url) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            const formattedOptions = response.data.results.map(items => ({
                value: items.id,
                label: items.name
            }));
            setName(formattedOptions)

        })
    }

    //update field of house church on the basis of id.
    function handleSubmit(e, values) {

        setSubmitting(true)
        let data = {
            ...values, 'denomination_id': selectedDenomination.value, 'user_id': user.user_id.id
        }

        put(`${BASE_URL}/api/house_church/organization/${id}/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setSubmitting(false)
                if (response.status >= 400) {
                    console.log(response)
                    toast.error("Something Went Wrong")
                    let keys = Object.keys(response.data)
                    console.log(keys)
                    for (let i = 0; i < keys.length; i++) {
                        let msg = response.data[keys[i]][0]
                        console.log(msg, " msg.log(response)")
                        toast.error(`${keys[i]}:${msg}`)
                    }

                } else {
                    loadOrganization()
                    toast.success('Organization Updated Successfully')
                }
            })



    }

    function NoDataIndication() {
        if (loadHouseChurch && church.length === 0) {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )

        } else {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
            )

        }
    }



    function handleSelectGroup(selectedDenomination) {
        setselectedDenomination(selectedDenomination);
    }

    function handleDenominationChange(e) {
        loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false&name=${e}`)
    }


    function handleTableChange(type, { page, sizePerPage, searchText, }) {

        setTimeout(() => {
            setloadHouseChurch(false)
            setChurch([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/dash-list-house_church/?organization=${id}&page_size=${sizePerPage}&page=${new_page}`
            window.scrollTo(0, 0)
            loadHouseData(url, sizePerPage, new_page, searchText)


        }, 1000);
    }





    const defaultSorted = [{
        dataField: 'f_name',
        order: 'desc',
        savestate: true
    }];


    //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }


    document.title = pageTitle + " | FAHC Admin Dashboard"


    return (
        <React.Fragment>
            <div className="page-content">


                <Breadcrumbs title={"Organizations"} breadcrumbItem={user ? `Edit ${user.name}` : "Edit Organization"} link={"/organizations"} />


                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>


                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Detail                                                                           </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                            <span className="d-none d-sm-block">House Churches</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        {user ?
                                            <Row>

                                                <Col sm="8">
                                                    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                                        <Card>
                                                            <CardBody>

                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="name">Name</Label>
                                                                            <AvField
                                                                                name="name"
                                                                                placeholder="Name"
                                                                                type="text"
                                                                                className="form-control"

                                                                                id="name"
                                                                                value={user.name}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="address">Address</Label>
                                                                            <AvField
                                                                                name="address"
                                                                                placeholder="Address"
                                                                                type="text"
                                                                                errorMessage=" Please Enter Address."
                                                                                className="form-control"

                                                                                id="address"
                                                                                value={user.address}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="city">Phone</Label>
                                                                            <AvField
                                                                                name="phone"
                                                                                placeholder="Phone"
                                                                                type="text"
                                                                                errorMessage=" Please provide a valid Phone."
                                                                                className="form-control"

                                                                                id="phone"
                                                                                value={user.phone}
                                                                            />
                                                                        </div>


                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="email">Email</Label>
                                                                            <AvField
                                                                                name="email"
                                                                                placeholder="Email"
                                                                                type="text"
                                                                                errorMessage=" Please provide a valid Email."
                                                                                className="form-control"

                                                                                id="email"
                                                                                value={user.email}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="zipcode">Zip Code</Label>
                                                                            <AvField
                                                                                name="zipcode"
                                                                                placeholder="ZipCode"
                                                                                type="text"
                                                                                errorMessage=" Please provide a valid ZipCode."
                                                                                className="form-control"

                                                                                id="zipcode"
                                                                                value={user.zipcode}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={6}>

                                                                        <div className="mb-3">
                                                                            <Label htmlFor="name">Denomination</Label>
                                                                            <Select
                                                                                value={selectedDenomination}
                                                                                onChange={(e) => {
                                                                                    handleSelectGroup(e);
                                                                                }}
                                                                                onInputChange={(e) => handleDenominationChange(e)}
                                                                                options={name}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>

                                                                    </Col>
                                                                </Row>
                                                                <Row>

                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="best_time_to_contact">Best Time To Contact</Label>
                                                                            <AvField
                                                                                name="best_time_to_contact"
                                                                                placeholder="Special Inst"
                                                                                type="text"
                                                                                errorMessage=" Please provide a valid Detail"
                                                                                className="form-control"

                                                                                id="best_time_to_contact"
                                                                                value={user.best_time_to_contact}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="point_of_contact">Point of Contact</Label>
                                                                            <AvField
                                                                                name="point_of_contact"
                                                                                placeholder="Point Of Contact"
                                                                                type="text"
                                                                                errorMessage=" Please provide a valid Point Of Contact."
                                                                                className="form-control"

                                                                                id="point_of_contact"
                                                                                value={user.point_of_contact}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="user_id">User</Label>
                                                                            <AvField
                                                                                name="user_id"
                                                                                placeholder="User"
                                                                                type="text"
                                                                                errorMessage="Please provide a valid User."
                                                                                className="form-control"
                                                                                disabled

                                                                                id="user_id"
                                                                                value={user.user_id ? user.user_id.first_name : null}
                                                                            />
                                                                        </div>

                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="created_at">Created</Label>
                                                                            <AvField
                                                                                name="created_at"
                                                                                placeholder="Created"
                                                                                type="date"
                                                                                className="form-control"
                                                                                disabled

                                                                                id="created_at"
                                                                                value={user.created_at.split("T")[0]}
                                                                            />
                                                                        </div>

                                                                    </Col>
                                                                </Row>
                                                                <Row>

                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="page_title">Page Title</Label>
                                                                            <AvField
                                                                                name="page_title"
                                                                                placeholder="Page Title"
                                                                                type="text"
                                                                                errorMessage=" Please provide a valid Detail."
                                                                                className="form-control"

                                                                                id="page_title"
                                                                                value={user.page_title}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="page_description">Page Description</Label>
                                                                            <AvField
                                                                                name="page_description"
                                                                                placeholder="Page Description"
                                                                                type="textarea"
                                                                                errorMessage=" Please provide a valid Info."
                                                                                className="form-control"

                                                                                id="page_description"
                                                                                value={user.page_description}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>

                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="meta_title">Meta Title</Label>
                                                                            <AvField
                                                                                name="meta_title"
                                                                                placeholder="Meta Title"
                                                                                type="text"
                                                                                errorMessage=" Please provide a valid Detail."
                                                                                className="form-control"

                                                                                id="meta_title"
                                                                                value={user.meta_title}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <Label htmlFor="meta_description">Meta Description</Label>
                                                                            <AvField
                                                                                name="meta_description"
                                                                                placeholder="Meta Description"
                                                                                type="textarea"
                                                                                errorMessage=" Please provide a valid Info."
                                                                                className="form-control"

                                                                                id="meta_description"
                                                                                value={user.meta_description}
                                                                            />
                                                                        </div>
                                                                    </Col>

                                                                </Row>

                                                                {submitting ?
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary waves-effect waves-light my-3"
                                                                    >
                                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                                        Updating
                                                                    </button>
                                                                    :
                                                                    <Button color="primary" type="submit">
                                                                        Update
                                                                    </Button>
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                    </AvForm>
                                                </Col>

                                            </Row>
                                            :
                                            <div id="preloader">
                                                <div id="status">
                                                    <div className="spinner-chase">
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                        <div className="chase-dot"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        }



                                    </TabPane>
                                    {/* House Church Events */}
                                    <TabPane tabId="2" className="p-3">

                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            data={houseChurches}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={houseChurches}
                                                    columns={columns}
                                                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                    bootstrap5
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>

                                                            <div className="table-responsive">

                                                                <BootstrapTable
                                                                    remote
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    hover
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                    onTableChange={handleTableChange}
                                                                    noDataIndication={() => NoDataIndication()}
                                                                // selectRow={selectRow}
                                                                />

                                                            </div>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <div className="mb-3">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="pagination pagination-rounded mb-2">
                                                                        <PaginationTotalStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                            </Row>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </TabPane>

                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>



                </Row>

                {/* {alert_confirm ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => handleDeletePost(selectedPost.id)}
                        onCancel={() => {
                            setAlert_confirm(false)
                            setSelectedPost(null)
                        }
                        }
                    >
                        You won't be able to revert this!
                    </SweetAlert>
                ) : null}

                {success_confirm ? (
                    <SweetAlert
                        success
                        title={dynamic_title}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setSuccess_confirm(false)
                            setAlert_confirm(false)
                        }
                        }
                    >
                        {dynamic_description}
                    </SweetAlert>
                )
                    : null
                } */}


            </div>


        </React.Fragment>
    )
}

export default EditOrganization
