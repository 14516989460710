import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { BASE_URL } from "../../../helpers/url_helper";
import { get, patch } from "../../../helpers/api_helper";
import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken"
import { ValidateUser } from "../../../helpers/validate_user";
import { toast } from "react-toastify";

const LocationPageContent = (props) => {
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [pageContent, setPageContent] = useState(null)
    const [submitting, setSubmitting] = useState(null)

    ValidateUser(props)

    useEffect(() => {

        setDidMount(true);
        get(`${BASE_URL}/api/house_church/meta-content/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setPageContent(response.data)
            })

    }, []);


    if (!didMount) {
        return null;
    }

    function handleSubmit(e, values) {
        setSubmitting(true)
        
        patch(`${BASE_URL}/api/house_church/meta-content/${id}/`, values,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                if (response.status >= 400) {

                    setSubmitting(false)
                    console.log(response.data, 'notchurch')
                } else {
                    setSubmitting(false)
                    toast.success("Content Saved.")
                }
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Page Contents" breadcrumbItem={`Mangae ${pageContent?pageContent.page:""} Page Meta`} link="/page-contents" />
                {/* <Breadcrumbs title="Location Page" breadcrumbItem={"Location Church"} /> */}

                {pageContent ?
                    <Row>

                        <Col lg={6}>
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Location Page </CardTitle>
                                        
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label htmlFor="page_title">Page title</Label>
                                                    <AvField
                                                        name="page_title"
                                                        placeholder="page_title"
                                                        type="text"
                                                        errorMessage=" Please Enter Page title."
                                                        className="form-control"

                                                        id="page_title"
                                                        value={pageContent.page_title}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            
                                            <Col md='12'>
                                                <div className="mb-3">
                                                    <Label htmlFor="page_description">Page description</Label>
                                                    <AvField
                                                        name='page_description'
                                                        placeholder=' Page description'
                                                        type='textarea'
                                                        className='form-control'
                                                        id='page_description'
                                                        value={pageContent.page_description}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md="12">
                                                <div className="mb-3">
                                                    <Label htmlFor="keywords">Keywords</Label>
                                                    <AvField
                                                        name="keywords"
                                                        placeholder="keywords"
                                                        type="text"
                                                        className="form-control"

                                                        id="keywords"
                                                        value={pageContent.keywords}
                                                    />
                                                </div>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label htmlFor="meta_title">Meta title</Label>
                                                    <AvField
                                                        name='meta_title'
                                                        placeholder='Meta title'
                                                        type='text'
                                                        className='form-control'
                                                        id='meta_title'
                                                        value={pageContent.meta_title} />

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md='12'>
                                                <div className="mb-3">
                                                    <Label htmlFor="meta_description">Meta description</Label>
                                                    <AvField
                                                        name='meta_description'
                                                        placeholder=' Meta description'
                                                        type='textarea'
                                                        className='form-control'
                                                        id='meta_description'
                                                        value={pageContent.meta_description}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>
                                        {submitting ?
                                            <Button color='primary' type='button'>
                                                Saving
                                            </Button>
                                            :
                                            <Button color='primary' type='submit' >
                                                Save
                                            </Button>  
                                        
                                        }
                                    </CardBody>
                                </Card>
                            </AvForm>

                        </Col>

                    </Row>

                    :
                    <div id="preloader">
                        <div id="status">
                            <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                            </div>
                        </div>
                    </div>

                }

            </div>


        </React.Fragment>


    )
}

export default LocationPageContent
