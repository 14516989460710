import React, { useState, useEffect } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Label, Button, Modal, TabContent, Spinner,
    TabPane,
    NavLink,
    NavItem,
    Nav,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { get, put, patch } from "../../helpers/api_helper";
import { toast } from 'react-toastify';
import not_avail from "../../assets/images/not_avail.jpg"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import { HeaderFormatter } from "../../helpers/methods";
import moment from "moment-timezone";




const EditCustomer = (props) => {
    const state = props.location.statedata
    const { id } = props.match.params
    const [activeTab, setactiveTab] = useState("1")
    const [didMount, setDidMount] = useState(false);
    const [church, setChurch] = useState(null)
    const [submitting, setSubmitting] = useState(null)
    const [modal_standard, setmodal_standard] = useState(false)
    const [isClaimed, setIsClaimed] = useState(null)
    const [search, setSearch] = useState(null)
    const [name, setName] = useState(null)
    const [ischecked, setIsChecked] = useState(null)
    const [img, setImg] = useState(null)
    const [selectedGroup, setselectedGroup] = useState({ label: "Select Denomination", value: null });
    const [approved, setApproved] = useState(false)
    const [pageTitle, setPageTitle] = useState('Church')
    const [loadHouseChurch, setloadHouseChurch] = useState(false)
    const [houseChurches, setHouseChurchs] = useState([])
    const [totalSize, settotalSize] = useState(0);
    const [sizePerPage, setsizePerPage] = useState(20)
    const [page, setpage] = useState(1)


    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false`)
        loadChurchData()
        loadHouseData(`${BASE_URL}/api/house_church/dash-list-house_church/`, 20, 1)

        setDidMount(true);


    }, [id]);

    function loadHouseData(url, sizePerPage, page) {
        setloadHouseChurch(false)
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setHouseChurchs(response.data.results)
            settotalSize(response.data.count)
            setpage(page)
            setsizePerPage(sizePerPage)
            setloadHouseChurch(true)
        })
    }

    function handleTableChange(type, { page, sizePerPage, searchText, }) {

        setTimeout(() => {
            setloadHouseChurch(false)
            setChurch([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/dash-list-house_church/?page_size=${sizePerPage}&page=${new_page}`
            window.scrollTo(0, 0)
            loadHouseData(url, sizePerPage, new_page, searchText)


        }, 1000);
    }


    const columns = [
        {
            dataField: "id",
            text: "S No.",
            hidden: true

        },
        {
            dataField: "user_id",
            text: "Owner",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{row.user_id ? row.user_id.first_name + " " + row.user_id.last_name : null}</Link>
            ),
        },


        {
            dataField: "name",
            text: "Name",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (

                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{cellContent}</Link>
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "user_id.phone",
            text: "Phone",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{cellContent}</Link>
            )
            ,
        },
        {
            dataField: "user_id.email",
            text: "Email",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{cellContent}</Link>
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "created_at",
            text: "Created",
            headerFormatter: HeaderFormatter,
            // sort: true,
            formatter: (cellContent, row) => (
                moment(cellContent).tz("America/Chicago").format("MM-DD-YYYY")
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "site",
            isDummyField: true,
            headerFormatter: HeaderFormatter,
            text: "site",
            formatter: (cellContent, row) => (
                <React.Fragment>
                    <a href={`https://findahousechurch.com/house-church/${row.slug}`} target="__blank" className="me-3 text-primary" >  <i className="mdi mdi-search-web"></i></a>
                </React.Fragment>

            ),
        },
    ]

    function loadChurchData() {
        get(`${BASE_URL}/api/house_church/dash_list_churches/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setChurch(response.data)
                setPageTitle(response.data.name)
                setIsChecked(response.data.status)
                setIsClaimed(response.data.claimed)
                if (response.data.denomination_id) {
                    setselectedGroup({ label: response.data.denomination_id.name, value: response.data.denomination_id.id })

                }
            })
    }

    if (!didMount) {
        return null;
    }
    const handleFileUpload = (e) => {
        try {
            let reader = new FileReader();
            let file = e.target.files[0];
            if (!file) {
                return;
            }
            const fileSizeInKB = file.size / 1024;
            if (fileSizeInKB > 30) {
                toast.error('Please select an image file smaller than 30 kB');
                setImg(null)
            } else {
                reader.onloadend = () => {
                    var previewImgUrl = reader.result
                    setImg(previewImgUrl)
                }
                reader.readAsDataURL(file);
            }

        } catch (error) {

        }
    }

    function handleSubmit(e, values) {
        setSubmitting(true)

        let data = { ...values, status: ischecked, claimed: isClaimed ? 1 : 0, search_status: search ? 1 : 0, approved: approved, denomination_id: selectedGroup.value }
        console.log(data)
        put(`${BASE_URL}/api/house_church/dash_list_churches/${id}/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Somthing Went Wrong")
                    console.log(response)
                    setSubmitting(false)
                    loadChurchData()
                } else {
                    setSubmitting(false)
                    loadChurchData()
                }
            })

    }


    function handleImageSubmit(e, values) {
        if (img) {
            setSubmitting(true)
            patch(`${BASE_URL}/api/house_church/dash_list_churches/${id}/`, { ...values, image: img },
                { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false })
                .then(response => {
                    if (response.status >= 400) {
                        toast.error("Image not found")
                        console.log(response)

                    } else {
                        props.history.push("/all-churches")
                        console.log(response)

                    }
                })
        } else {
            toast.error('Please select an image file smaller than 30 kB');

        }
    }


    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup);
    }

    function loadData(url) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            const formattedOptions = response.data.results.map(items => ({
                value: items.id,
                label: items.name
            }));



            setName(formattedOptions)



        })
    }

    function handleDenominationChange(e) {
        loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false&name=${e}`)
    }

    function NoDataIndication() {
        if (loadHouseChurch && church.length === 0) {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )

        } else {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
            )

        }
    }

    const defaultSorted = [{
        dataField: 'f_name',
        order: 'desc',
        savestate: true
    }];


    //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }

    document.title = pageTitle + " | FAHC Admin Dashboard"

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Churches" breadcrumbItem={church ? church.name : "Church Name"} link="/all-churches" data={state} />
                {church ?
                    <Row>

                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                        toggle("1")
                                    }}
                                >
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Detail                                                                           </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                        toggle("2")
                                    }}
                                >
                                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                    <span className="d-none d-sm-block">House Churches</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="p-3 text-muted">
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="4">
                                        <Card>
                                            <CardBody>
                                                <CardTitle></CardTitle>
                                                <Row>
                                                    <Col sm="12">

                                                        <div className="text-end">
                                                            <Button color="link" type="button" onClick={() => tog_standard()}>
                                                                <i className="bx bx-edit-alt" style={{ 'fontSize': '30px' }}></i>
                                                            </Button>
                                                        </div>

                                                    </Col>
                                                    <Col md={12}>


                                                    </Col>
                                                    <img src={img ? img : church.image ? church.image : not_avail} alt="Selected" />

                                                </Row>

                                            </CardBody>
                                        </Card>

                                    </Col>
                                    <Col sm="8">
                                        <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Church Detail </CardTitle>

                                                    <Row>
                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <Label htmlFor="name">Name</Label>
                                                                <AvField
                                                                    name="name"
                                                                    placeholder="Name"
                                                                    type="text"
                                                                    className="form-control"

                                                                    id="name"
                                                                    value={church.name}
                                                                />
                                                            </div>

                                                        </Col>
                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <Label htmlFor="address">Address</Label>
                                                                <AvField
                                                                    name="address"
                                                                    placeholder="Address"
                                                                    type="text"
                                                                    errorMessage=" Please Enter Address."
                                                                    className="form-control"

                                                                    id="address"
                                                                    value={church.address}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">

                                                            <div className='mb-3'>
                                                                <Label htmlFor="country">Country</Label>
                                                                <AvField
                                                                    name='country'
                                                                    placeholder='Country'
                                                                    type='text'
                                                                    className='form-control'
                                                                    id='country'
                                                                    value={church.country} />
                                                            </div>

                                                        </Col>


                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <Label htmlFor="state">State</Label>
                                                                <AvField
                                                                    name="state"
                                                                    placeholder="State"
                                                                    type="text"
                                                                    errorMessage=" Please Enter State."
                                                                    className="form-control"

                                                                    id="state"
                                                                    value={church.state}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <Label htmlFor="city">City</Label>
                                                                <AvField
                                                                    name="city"
                                                                    placeholder="City"
                                                                    type="text"
                                                                    errorMessage=" Please Enter city."
                                                                    className="form-control"

                                                                    id="city"
                                                                    value={church.city}
                                                                />
                                                            </div>
                                                        </Col>


                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <Label htmlFor="zipcode">ZipCode</Label>
                                                                <AvField
                                                                    name="zipcode"
                                                                    placeholder="ZipCode"
                                                                    type="text"
                                                                    errorMessage=" Please provide a valid ZipCode."
                                                                    className="form-control"

                                                                    id="zipcode"
                                                                    value={church.zipcode}
                                                                />
                                                            </div>
                                                        </Col>



                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="phone"
                                                                    label="Phone"
                                                                    placeholder="Contact Number"
                                                                    type="text"
                                                                    errorMessage="Enter Only Digits"
                                                                    validate={{
                                                                        pattern: {
                                                                            value: "^[0-9+-]+$",
                                                                            errorMessage: "Invalid Contact"
                                                                        },
                                                                    }}
                                                                    value={church.phone}
                                                                />
                                                            </div>

                                                        </Col>
                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <Label htmlFor="email">Email</Label>
                                                                <AvField
                                                                    name='email'
                                                                    placeholder='Email'
                                                                    type='email'
                                                                    className='form-control'
                                                                    id='email'
                                                                    value={church.email} />

                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col md='6'>
                                                            <div className="mb-3">
                                                                <Label htmlFor="pageurl">Page Url</Label>
                                                                <AvField
                                                                    name='pageurl'
                                                                    placeholder='Page Url'
                                                                    type='text'
                                                                    className='form-control'
                                                                    id='pageurl'
                                                                    value={church.pageurl}
                                                                />

                                                            </div>
                                                        </Col>


                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="poc">POC</Label>
                                                                <AvField
                                                                    name="poc"
                                                                    placeholder="Poc"
                                                                    type="text"
                                                                    errorMessage=" Please provide a valid Poc."
                                                                    className="form-control"

                                                                    id="poc"
                                                                    value={church.poc}
                                                                />
                                                            </div>

                                                        </Col>
                                                    </Row>


                                                    <Row>
                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <Label htmlFor="poc_email">POC Email</Label>
                                                                <AvField
                                                                    name="poc_email"
                                                                    placeholder="Poc Email"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="poc_email"
                                                                    value={church.poc_email}
                                                                />
                                                            </div>

                                                        </Col>
                                                        <Col md="6">
                                                            <div className='mb-3'>
                                                                <Label htmlFor='poc_phone'>POC Phone</Label>
                                                                <AvField
                                                                    name='poc_phone'
                                                                    placeholder='Poc Phone'
                                                                    type='text'
                                                                    id="poc_phone"
                                                                    value={church.poc_phone} />

                                                            </div>

                                                        </Col>
                                                    </Row>


                                                    <Row>
                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor='e_zip_full'>E Zip Full</Label>
                                                                <AvField
                                                                    name='e_zip_full'
                                                                    placeholder='E Zip Full'
                                                                    type='text'
                                                                    className='form-control'
                                                                    id="e_zip_full"
                                                                    value={church.e_zip_full}
                                                                />

                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label>Denomination</Label>
                                                                <Select
                                                                    value={selectedGroup}
                                                                    onChange={(e) => {
                                                                        handleSelectGroup(e);
                                                                    }}
                                                                    onInputChange={(e) => handleDenominationChange(e)}
                                                                    options={name}
                                                                    classNamePrefix="select2-selection"
                                                                />

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor='loc_county'>LOC Country</Label>
                                                                <AvField
                                                                    name='loc_county'
                                                                    placeholder='Loc Country'
                                                                    type='text'
                                                                    className='form-control'
                                                                    id='loc_county'
                                                                    value={church.loc_county} />
                                                            </div>
                                                        </Col>

                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor='loc_area_code'>LOC Area Code</Label>
                                                                <AvField
                                                                    name='loc_area_code'
                                                                    placeholder='Loc Area Code'
                                                                    type='text'
                                                                    className='form-control'
                                                                    id='loc_area_code'
                                                                    value={church.loc_area_code} />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor="loc_fips">LOC Fips</Label>
                                                                <AvField
                                                                    name='loc_fips'
                                                                    placeholder='Loc Fips'
                                                                    type='text'
                                                                    className='form-control'
                                                                    id='loc_fips'
                                                                    value={church.loc_fips} />

                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor='loc_msa'>LOC MSA</Label>
                                                                <AvField
                                                                    name='loc_msa'
                                                                    placeholder='Loc MSA'
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="loc_msa"
                                                                    value={church.loc_msa} />

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="loc_tz">LOC TZ</Label>
                                                                <AvField
                                                                    name='loc_tz'
                                                                    placeholder='Loc TZ'
                                                                    type='text'
                                                                    id="loc_tz"
                                                                    value={church.loc_tz} />

                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor="loc_lat_poly">LOC Lat Poly</Label>
                                                                <AvField
                                                                    name='loc_lat_poly'
                                                                    placeholder='Loc Lat Poly'
                                                                    type='text'
                                                                    id='loc_lat_poly'
                                                                    value={church.loc_lat_poly} />
                                                            </div>


                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor='loc_long_poly'>LOC Long Poly</Label>
                                                                <AvField
                                                                    name='loc_long_poly'
                                                                    placeholder='Loc Long Poly'
                                                                    type='text'
                                                                    id='loc_long_poly'
                                                                    value={church.loc_long_poly} />
                                                            </div>

                                                        </Col>
                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor='loc_dst'>LOC DST</Label>
                                                                <AvField
                                                                    name='loc_dst'
                                                                    placeholder='Loc DST'
                                                                    type='text'
                                                                    id='loc_dst'
                                                                    value={church.loc_dst} />
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor='lat'>Latitude</Label>
                                                                <AvField
                                                                    name='lat'
                                                                    placeholder='Lat'
                                                                    type='text'
                                                                    id='lat'
                                                                    value={church.lat} />

                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor='lng'>Longitude</Label>
                                                                <AvField
                                                                    name='lng'
                                                                    placeholder='Lng'
                                                                    type='text'
                                                                    id='lng'
                                                                    value={church.lng} />

                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor='website'>Website</Label>
                                                                <AvField
                                                                    name='website'
                                                                    placeholder='Website'
                                                                    type='text'
                                                                    id="website"
                                                                    value={church.website} />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor="biz_phone_ext">Biz Phone Ext</Label>
                                                                <AvField
                                                                    name='biz_phone_ext'
                                                                    placeholder='Biz Phone Ext'
                                                                    type='text'
                                                                    id='biz_phone_ext'
                                                                    value={church.biz_phone_ext} />
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row>

                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="biz_info">Biz Info</Label>
                                                                <AvField
                                                                    name="biz_info"
                                                                    placeholder="Biz Info"
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    id="biz_info"
                                                                    value={church.biz_info}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col mb={12}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor='page_title'>Page Title</Label>
                                                                <AvField
                                                                    name='page_title'
                                                                    placeholder='Page Title'
                                                                    type='text'
                                                                    id='page_title'
                                                                    value={church.page_title} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="page_desc">Page Desc</Label>
                                                                <AvField
                                                                    name="page_desc"
                                                                    placeholder="Page Desc"
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    id="page_desc"
                                                                    value={church.page_desc}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col mb={12}>
                                                            <div className='mb-3'>
                                                                <Label htmlFor='web_meta_title'>Web Meta Title</Label>
                                                                <AvField
                                                                    name='web_meta_title'
                                                                    placeholder='Web Meta Title'
                                                                    type='text'
                                                                    id='web_meta_title'
                                                                    value={church.web_meta_title} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="web_meta_desc">Web Meta Desc</Label>
                                                                <AvField
                                                                    name="web_meta_desc"
                                                                    placeholder="Web Meta Desc"
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    id="web_meta_desc"
                                                                    value={church.web_meta_desc}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="web_meta_keys">Web Meta Keys</Label>
                                                                <AvField
                                                                    name='web_meta_keys'
                                                                    placeholder='Web Meta Keys'
                                                                    type='text'
                                                                    id='web_meta_keys'
                                                                    value={church.web_meta_keys} />

                                                            </div>
                                                        </Col>
                                                    </Row>



                                                    <Row>


                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor='code'>Code</Label>
                                                                <AvField
                                                                    name='code'
                                                                    placeholder='Code'
                                                                    type='textarea'
                                                                    id='code'
                                                                    value={church.code} />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>

                                                        <Col md={4}>
                                                            <div className="checkbox-wrapper mb-2">
                                                                <label>
                                                                    <input type="checkbox"
                                                                        checked={ischecked}
                                                                        onChange={(e) => setIsChecked(e.target.checked)} /> {"      "} {"      "}
                                                                    Status
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="checkbox-wrapper mb-3">
                                                                <label>
                                                                    <input type="checkbox"
                                                                        checked={isClaimed}
                                                                        onClick={(e) => setIsClaimed(e.target.checked)}
                                                                    /> {"      "} {"      "}
                                                                    Claimed
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="checkbox-wrapper mb-3">
                                                                <label>
                                                                    <input type="checkbox"
                                                                        checked={search}
                                                                        onClick={(e) => setSearch(e.target.checked)}
                                                                    /> {"      "} {"      "}
                                                                    Search Status
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="checkbox-wrapper mb-3">
                                                                <label>
                                                                    <input type="checkbox" checked={approved} onClick={(e) => setApproved(e.target.checked)} />{"  "}
                                                                    Approved
                                                                </label>

                                                            </div>
                                                        </Col>

                                                    </Row>




                                                    {submitting ?
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light my-3"
                                                        >
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                            Updating
                                                        </button>
                                                        :
                                                        <Button color="primary" type="submit">
                                                            Update
                                                        </Button>
                                                    }
                                                </CardBody>
                                            </Card>
                                        </AvForm>
                                    </Col>

                                </Row>

                            </TabPane>
                            <TabPane tabId="2" className="p-3">

                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    data={houseChurches}
                                >
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField="id"
                                            data={houseChurches}
                                            columns={columns}
                                            remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                            bootstrap5
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>

                                                    <div className="table-responsive">

                                                        <BootstrapTable
                                                            remote
                                                            {...toolkitProps.baseProps}
                                                            {...paginationTableProps}
                                                            responsive
                                                            bordered={false}
                                                            striped={true}
                                                            defaultSorted={defaultSorted}
                                                            hover
                                                            classes={
                                                                "table align-middle table-nowrap table-check"
                                                            }
                                                            headerWrapperClasses={"table-light"}
                                                            onTableChange={handleTableChange}
                                                            noDataIndication={() => NoDataIndication()}
                                                        />

                                                    </div>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <div className="mb-3">
                                                                <SizePerPageDropdownStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                            <div className="pagination pagination-rounded mb-2">
                                                                <PaginationTotalStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>

                                                        </Col>
                                                        <Col sm={6}>
                                                            <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>

                                                        </Col>
                                                    </Row>


                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    )}
                                </PaginationProvider>
                            </TabPane>
                        </TabContent>


                    </Row>


                    :
                    <div id="preloader">
                        <div id="status">
                            <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                            </div>
                        </div>
                    </div>
                }

                <Col sm={6} md={4} xl={3}>
                    <Modal
                        isOpen={modal_standard}
                        toggle={() => {
                            tog_standard();

                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0 " style={{ 'textAlign': 'center' }} id="myModalLabel">
                                Image
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard(false)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <h5>Overflowing text to show scroll behavior</h5> */}

                            <Col lg='12'>
                                <AvForm className="needs-validation" onValidSubmit={handleImageSubmit}>

                                    <Row>
                                        <Col md={12}>
                                            <div className="text-center">


                                            </div>

                                        </Col>



                                        {/* <img src={img} alt="Selected" /> */}

                                        <input type='file' accept="image/png, image/jpeg" className="mr-5 mb-3 text-center" onChange={handleFileUpload} />


                                    </Row>


                                    <div className="modal-footer">
                                        {submitting ?

                                            // <button
                                            //     type="button"
                                            //     onClick={() => {
                                            //         tog_standard()
                                            //     }}
                                            //     className="btn btn-primary waves-effect waves-light my-3"
                                            // >
                                            //     <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                            //     Updateimage
                                            // </button>
                                            <Button color="primary" type="submit">
                                                Update
                                            </Button>
                                            :
                                            <Button color="primary" type="submit">
                                                Update
                                            </Button>
                                        }
                                        <button
                                            type="button"
                                            onClick={() => {
                                                tog_standard();
                                                setImg(null)
                                            }}
                                            className="btn btn-primary waves-effect"

                                            data-dismiss="modal"
                                        >
                                            Close
                                        </button>

                                    </div>


                                </AvForm>
                            </Col>


                        </div>

                    </Modal>
                </Col>


            </div>

        </React.Fragment>
    )
}

export default EditCustomer
