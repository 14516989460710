import PropTypes from "prop-types"
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

// import avatar2 from "../../assets/images/users/avatar-7.jpg"
// import { put,get } from "../../helpers/api_helper";

import {
  changeSidebarType,
} from "../../store/actions"

// import { toTitleCase } from "../../helpers/methods"
// import { BASE_URL } from "../../helpers/url_helper"

const Sidebar = props => {
  // const { id } = props.match.params
  const [username, setusername] = useState("Admin")
  const [img,setImg]=useState([])
  const { changeSidebarType } = props;
  function HandleSidebar(){
    if(props.type === "icon"){
      props.changeSidebarType("default")
      localStorage.setItem("sidebar_type","default")
    }else{
      props.changeSidebarType("icon")
      localStorage.setItem("sidebar_type","icon")
    }    
  }

  useEffect(() => {
    let type = localStorage.getItem("sidebar_type");
    changeSidebarType(type ? type : "default");

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (obj.user.username.includes("@")) {
        let name = obj.user.username;
        let image = obj.user.image;
        setusername(name.split("@")[0]);
        setImg(image);
      } else {
        setusername(obj.user.username);
        setImg(obj.user.image);
      }
    }
  }, [changeSidebarType]);


  return (
    <React.Fragment>
    
      <div className="vertical-menu">
      <div className="h-100">
      <div className="text-center py-4">
        <Link to="#" style={{color: '#5a5a5a'}} onClick={HandleSidebar}><i className="fas fa-grip-horizontal" style={{fontSize:"25px"}} /></Link>
      </div>
      
        <div className="user-wid text-center py-4">
          <div className="user-img">
            <img src={img} alt="" className="avatar-md mx-auto rounded-circle" />
          </div>
          <div className="mt-3">
            <Link to="#" className="text-dark fw-medium font-size-16"><span className="text-primary">{username}</span></Link>
            {/* <p className="text-body mt-1 mb-0 font-size-13"><span style={{color: '#a6b0cf'}}>UI/UX Designer</span></p> */}
          </div>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        </div>
      </div>
 
      
    </React.Fragment>
  )
}

Sidebar.propTypes = {
        type: PropTypes.string,
        changeSidebarType: PropTypes.func,
        leftSideBarType: PropTypes.any,
}

const mapStatetoProps = state => {
  return {
        layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
      {changeSidebarType,}
)(withRouter(withTranslation()(Sidebar)))






