// import {  }
import jwt_decode from "jwt-decode";

export function ValidateUser(props){
    const obj = JSON.parse(localStorage.getItem("authUser"))
    if (!obj){
        props.history.push('/logout')
    }else if (!validateToken(obj.access)){
        props.history.push('/logout')
    }
}


function validateToken(accessToken){
  
    var isExpired = false;
    if (accessToken){
      let token = jwt_decode(accessToken);
      let currentDate = new Date();
  
      // JWT exp is in seconds
      if (token.exp * 1000 < currentDate.getTime()) {
        isExpired = false;
      } else { 
        isExpired = true;
      }
    }
    
    return isExpired
  
  }