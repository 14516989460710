import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Spinner, TabPane, Button, Modal, Badge } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import Select from "react-select";
import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get, post, patch } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import moment from "moment-timezone";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"




const optionGroup = [
  {
    label: "Admin By",
    options: [
      { label: "Move to Delete", value: "delete" },
      { label: "Move to Pending", value: "pending" },
      { label: "Move to Resolved", value: "resolved" }
    ]
  }
];



const FlaggedHouseChurches = (props) => {
  const [userData, setUserData] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)

  const [loaddata, setLoaData] = useState(false)

  const [ids, setIds] = useState([])

  const [modal_standard, setmodal_standard] = useState(false)

  const [selectedGroup, setselectedGroup] = useState({ 'label': "Perform Bulk Action", 'value': null })
  const [selectedComment, setSelectedComment] = useState(" ")


  ValidateUser(props)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }


  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,

    },
    {
      dataField: 'profile',
      text: 'Profile',
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={{ pathname: `/user-detail/${row.profile.id}`,statedata: { 'url': "/flagged-profile", 'page': "Flagged Profile" } }} className="me-3 text-dark">{row.profile ? row.profile.first_name + "" + row.profile.last_name : null}</Link>
      ),

    },

    {
      dataField: 'comment',
      text: "Reason",
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (

        <Link to="#" className="me-3 text-primary" onClick={() => { tog_standard(); setSelectedComment(cellContent) }} style={{ 'fontSize': '20px' }} ><i className="mdi mdi-comment-account"></i></Link>

      ),
    },

    {
      dataField: "status",
      text: "Status",
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => {
        let badgeClassName = '';
        let badgeText = '';

        if (row.status === 'resolved') {
          badgeClassName = 'bg-success';
          badgeText = 'Resolved';
        } else if (row.status === 'pending') {
          badgeClassName = 'bg-danger';
          badgeText = 'Pending';
        }
        return (
          <Link
            to="#"
            className="me-3 text-dark"
          >
            <Badge className={badgeClassName}>{badgeText}</Badge>
          </Link>
        );
      }
    },

    {
      dataField: 'created_at',
      text: 'Date',
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={{ pathname: `/user-detail/${row.profile.id}`, statedata: { 'url': "/flagged-profile", 'page': "Flagged Profile" } }} className="me-3 text-dark">{moment(new Date(row.created_at)).format("MM-DD-YYYY")}</Link>
      ),

    },
    {
      dataField: "View",
      isDummyField: true,
      headerFormatter: HeaderFormatter,
      text: "View",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <a href={`https://findahousechurch.com/member/${row.profile.slug}`} target="blank" className="me-3 text-primary" >  <i className="fas fa-user" style={{ 'fontSize': '20px' }}></i> {cellContent}</a>
          {/* <a href={`//user-detail/${row.id}`} target="_blank" className="me-3 text-primary" >  <i className="mdi mdi-eye" style={{ 'fontSize': '20px' }}></i> {cellContent}</a> */}
          <Link to={{ pathname: `/user-detail/${row.profile.id}`, statedata: { 'url': "/flagged-profile", 'page': "Flagged Profile" }  }} className="me-3 text-primary"><i className='mdi mdi-eye' style={{'fontSize':20}}></i>{cellContent}</Link>

        </React.Fragment>

      ),
    },
    {
      dataField: "Action",
      isDummyField: true,
      headerFormatter: HeaderFormatter,
      text: "Action",
      formatter: (cellContent, row) => (
        <React.Fragment>
          {row.status === 'resolved'?
          <Link to="#"  className="me-3 text-dark"><i className="fas fa-ban"> </i></Link>
          :
          <Link to="#" onClick={()=> BanUser(row.id, row.profile.id)} className="me-3 text-dark"><i className="fas fa-ban"> Ban User</i></Link>}
          
        </React.Fragment>

      ),
    },


  ]



  useEffect(() => {
  
    loadData(`${BASE_URL}/api/house_church/reports/?house_id=null`, 20, 1)


  }, []);
  //get report listing  of profile by user
  function loadData(url, sizePerPage, page) {
    let data = get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } })
    data.then(response => {
      console.log(response.data.results)
      setUserData(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setLoaData(true)
    })
  }

  function BanUser(report_id, user_id){
    // let data = {'status':false, 'archive':true}
    let data = {'action':'resolved', 'reports':[report_id]}
    post(`${BASE_URL}/api/house_church/reports-bulk-action/`, data, {
      headers: { 'Content-Type': 'application/json' ,'Authorization': getAccessToken()}
    }, { validataStatus: false })
      .then(resp => { 
        if (resp >= 400) {
          toast.error("SomeThing Went Wrong")
        } else {
          archiveUser(user_id)
          let url = `${BASE_URL}/api/house_church/reports/?house_id=null&page_size=${sizePerPage}&page=${page}`;
          loadData(url, sizePerPage, page);
        }
      }
      )
  }



  function archiveUser(user_id){
    let data = {'status':false, 'archive':true}
    patch(`${BASE_URL}/api/accounts/users/${user_id}/`, data, {
      headers: { 'Content-Type': 'application/json' ,'Authorization': getAccessToken()}
    }, { validataStatus: false })
      .then(resp => { 
        if (resp >= 400) {
          console.log("USer Not Archived", resp)
        } else{
          console.log("USer Archived", resp)
        }
      }
      )
  }




  
  //when length of id is null then show Table is Empty
  function NoDataIndication() {
    if (loaddata && userData.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }

  //select multiple id with status and check if multple id is in reports then change status
  function HandleBulkOperations(value) {
    let data = {}
    data['action'] = value
    data['reports'] = ids
    post(`${BASE_URL}/api/house_church/reports-bulk-action/`, data, {
      headers: { 'Content-Type': 'application/json' ,'Authorization': getAccessToken()}
    }, { validataStatus: false })
      .then(resp => { 
        if (resp >= 400) {
          toast.error("SomeThing Went Wrong")
        } else {
          let new_page=page;
          if (userData.length < 2) {
            new_page = new_page > 1 ? new_page - 1 : 1;
          }
          let url = `${BASE_URL}/api/house_church/reports/?house_id=null&page_size=${sizePerPage}&page=${new_page}`;
          loadData(url, sizePerPage, new_page);
        }
      }
      )
  }
  //change status according of select reports id
  function BulkActions() {
    ids.length > 0 ? HandleBulkOperations(selectedGroup.value) : toast.error("select Reports")

  }

  //using for page change
  function handleTableChange(type, { page, sizePerPage, searchText, }) {

    setTimeout(() => {
      setLoaData(false)
      setUserData([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/reports/?house_id=null&page_size=${sizePerPage}&page=${new_page}`
      window.scrollTo(0, 0)
      loadData(url, sizePerPage, new_page, searchText)
    }, 1000);
  }


  function handleOnSelect(row, isSelect) {
    let id = []
    if (isSelect) {
      id = ids
      id.push(row.id)
    } else {
      for (let i = 0; i < ids.length; i++) {
        if (ids[i] !== row.id) {
          id.push(ids[i])
        }
      }

    }
    setIds(id)
  }
  //select multiple row of active denomination
  function handleOnSelectAll(isSelect, rows) {
    console.log(isSelect,'isSelected')
    if (isSelect) {
      // let email = []
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setIds(id)
    } else {
      setIds([])
    }
  }



  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };



  const defaultSorted = [{
    dataField: 'f_name',
    order: 'desc',
    savestate: true
  }];

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]

  }





  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Flagged Profile' link="/dashboard" />
        <Col xs={12}>
          <Card>
            <CardBody>
              <TabPane tabId="2">
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={userData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={userData}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <AvForm className="needs-validation">
                            <Row className="mb-2">
                              <Col sm='3'>
                                {/* <Label>Single Select</Label> */}
                                <Select
                                  value={selectedGroup}
                                  onChange={(e) => {
                                    handleSelectGroup(e);
                                  }}
                                  options={optionGroup}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col sm="2">
                                <Button type='button' color='primary' onClick={() => BulkActions()}>Apply</Button>
                              </Col>





                            </Row >
                          </AvForm>






                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              defaultSorted={defaultSorted}
                              hover
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={handleTableChange}
                              noDataIndication={() => NoDataIndication()}
                              selectRow={selectRow}
                            />

                          </div>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="pagination pagination-rounded mb-2">
                                <PaginationTotalStandalone
                                  {...paginationProps}
                                />
                              </div>

                            </Col>
                            <Col sm={6}>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>

                            </Col>
                          </Row>


                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </TabPane>


            </CardBody>
          </Card>
          <Col lg={6}>
            <Modal
              isOpen={modal_standard}
              toggle={() => {
                tog_standard()
              }}
            >
              <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                          Flagged Profile
                            </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col lg='12'>
                    <p>Comment:{selectedComment}</p>
                  </Col>

                </Row>
              </div>

            </Modal>
          </Col>

        </Col>


      </div>


    </React.Fragment>
  )
}

export default FlaggedHouseChurches
