import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Spinner, NavLink, NavItem, Nav, TabPane, TabContent, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
import classnames from "classnames"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get, post, del } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import {ExportToExcel} from './ExportToExcel'
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"
import moment from "moment-timezone";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Name", value: "name" },
      { label: "Email", value: "email" },
      { label: "Date", value: "date_joined" },
    ],
  },
];

const EnableGroup = [
  {
    label: 'Enable by',
    options: [
      { label: "Move To Inactive", value: 'disable' },
      { label: "Move To Archive", value: 'archive' }
    ]
  }
];


const DisableGroup = [
  {
    label: 'Disable by',
    options: [
      
      { label: "Move To Active", value: 'enable' },
      { label: "Move To Archive", value: 'archive' },
      { label: "Move To Delete", value: 'delete' },
    ]
  }
];
const ArchiveGroup = [
  {
    label: 'Archive by',
    options: [
      { label: "Move To Active", value: 'enable' },
      { label: "Move To Inactive", value: 'disable' },
      { label: "Move To Delete", value: 'delete' },
    ]
  }
];

const sortOptionGroup = [
  {
    label: "Order by",
    options: [
      { label: "Asc", value: "" },
      { label: "Desc", value: "-" },
    ],
  },
];

const Users = (props) => {
  const [activeTab, setactiveTab] = useState("2")
  const [userData, setUserData] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [loadUserdata, setLoadUserData] = useState(false)

  const [archiveIds, setArchiveIds] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [archiveTotalSize, setArchiveTotalSize] = useState(0);
  const [archivePage, setArchivePage] = useState(1)

  const [DisabledUsers, setDisabledUsers] = useState([]);
  const [DisabledTotalSize, setDisabledtotalSize] = useState(0);
  const [DisabledPage, setDisabledpage] = useState(1)

  const [selected_id, setSelected_id] = useState(null)

  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")

  const [loaddata, setLoaData] = useState(false)
  const [ids, setIds] = useState([])
  const [DisabledIds, setDisabledIds] = useState([])

  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [search_by, setSearchby] = useState({ label: "Search By", value: null });
  const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });

  const [selectedGroup,setselectedGroup]=useState({'label':'Perform Bulk Action','value':null})
  const [selectedDisableGroup,setselectedDisableGroup]=useState({'label':'Perform Bulk  Action','value':null})
  const [selectedArchiveGroup,setselectedArchiveGroup]=useState({'label':'Perform Bulk Action','value':null})
  const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
  const [toDate, setToDate] = useState(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))
  const [userExportData,setUserExportData]=useState([])
  const [filterdata,setFilterData]=useState("")
  const [pageTitle, setPageTitle] = useState('Users')
  const [deleteUser, setDeleteUser] = useState('null')

  ValidateUser(props)


  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }


  const Defaultcolumns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,

    },

    {
      dataField: 'first_name',
      text: "Name",
      headerFormatter: HeaderFormatter,
      // sort: true,
      formatter: (cellContent, row) => (
        <Link to={{ pathname: `/edit-user-profile/${row.id}`, statedata: { 'activeTab': activeTab, 'page': { "2": page, '3': DisabledPage } } }} className="me-3 text-dark">{row.first_name + " " + row.last_name}</Link>
      ),
    },

    {
      dataField: "email",
      text: "Email",
      headerFormatter: HeaderFormatter,
      // sort: true,
      formatter: (cellContent, row) => (
        <Link to={{ pathname: `/edit-user-profile/${row.id}`, statedata: { 'activeTab': activeTab, 'page': { "2": page, '3': DisabledPage } } }} className="me-3 text-dark">{cellContent}</Link>
      ),
    },
    {
      dataField: "phone",
      text: "Phone",
      headerFormatter: HeaderFormatter,
      // sort: true,
      formatter: (cellContent, row) => (
        <Link to={{ pathname: `/edit-user-profile/${row.id}`, statedata: { 'activeTab': activeTab, 'page': { "2": page, '3': DisabledPage } } }} className="me-3 text-dark">{cellContent}</Link>
      ),
    },
    {
      dataField: "date_joined",
      text: "Date",
      headerFormatter: HeaderFormatter,
      // sort: true,
      formatter: (cellContent, row) => (
        moment(cellContent).tz("America/Chicago").format("MM-DD-YYYY")
      ),
    },

    
  ]

  const columns = [...Defaultcolumns, 
    {
      dataField: "action",
      isDummyField: true,
      headerFormatter: HeaderFormatter,
      text: "Action",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={{ pathname: `/edit-user-profile/${row.id}`, statedata: { 'activeTab': activeTab, 'page': { "2": page, '3': DisabledPage } } }} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i>{cellContent}</Link>
          <Link to="#" onClick={()=> {setAlert_confirm(true); setDeleteUser(row.id)}}  className="me-3 text-danger"><i className="mdi mdi-trash-can font-size-18"></i>{cellContent}</Link>
        </React.Fragment>
      ),
    },]
    const Activecolumns = [...Defaultcolumns, 
      {
        dataField: "action",
        isDummyField: true,
        headerFormatter: HeaderFormatter,
        text: "Action",
        formatter: (cellContent, row) => (
          <React.Fragment>
            <Link to={{ pathname: `/edit-user-profile/${row.id}`, statedata: { 'activeTab': activeTab, 'page': { "2": page, '3': DisabledPage } } }} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i>{cellContent}</Link>
          </React.Fragment>
        ),
      },]

  useEffect(() => {
    loadData(`${BASE_URL}/api/accounts/users/?status=true&archive=false&archive=false&is_dummy=false`, 20, 1)
    loadDisabledData(`${BASE_URL}/api/accounts/users/?status=false&archive=false&is_dummy=false`, 20, 1)
    loadArchiveData(`${BASE_URL}/api/accounts/users/?status=false&archive=true&is_dummy=false`, 20, 1)

    

  }, []);

  function loadData(url, sizePerPage, page) {
    setLoadUserData(false)
    get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } })
    .then(response => {
      // const data=response.data.results.filter((item)=>item.first_name !=='' || item.last_name !=='')
      let users_data = response.data.results
      setUserData(users_data)
      let newArray=[]; 
      for(let i=0 ; i< users_data.length; i++){
        let item = users_data[i]
        let obj={'First Name':item.first_name,'Last Name':item.last_name,'Email':item.email,'Phone':item.phone,'Adress':item.address,'City':item.city,'State':item.state}
        newArray.push(obj);

      }
      setUserExportData(newArray)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setLoadUserData(true)
    })
  }

  function loadArchiveData(url, sizePerPage, page) {
    setLoaData(false)
    let data = get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } })
    data.then(response => {
      setArchiveData(response.data.results)
      setArchiveTotalSize(response.data.count)
      setArchivePage(page)
      setsizePerPage(sizePerPage)
      setLoaData(true)
    })
  }

  function loadDisabledData(url, sizePerPage, page) {
    setLoaData(false)
    let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()} })
    data.then(response => {
      setDisabledUsers(response.data.results)
      setDisabledtotalSize(response.data.count)
      setDisabledpage(page)
      setsizePerPage(sizePerPage)
      setLoaData(true)
    })
  }
  
  function ClearFilter() {
    setFilterData("")
    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null });

    loadData(`${BASE_URL}/api/accounts/users/?status=true&archive=false&is_dummy=false`, 20, 1)
    loadDisabledData(`${BASE_URL}/api/accounts/users/?status=false&archive=false&is_dummy=false`, 20, 1)
    loadArchiveData(`${BASE_URL}/api/accounts/users/?status=false&archive=true&is_dummy=false`, 20, 1)


  }

  function handleDeleteUser(id){
    del(`${BASE_URL}/api/accounts/users/${id}/`,
      { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } },
      { validateStatus: false }
    )
      .then(res => {
        if (res.status >= 400) {
         
        }
        else {
          if (activeTab === '2') {
            let new_page = page
            if (userData.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/accounts/users/?status=true&archive=false&is_dummy=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadData(url, sizePerPage, new_page)

          } else if(activeTab === '3'){
            let new_page = DisabledPage
            if (DisabledUsers.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/accounts/users/?status=false&archive=false&is_dummy=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadDisabledData(url, sizePerPage, new_page)
          }else {
            let new_page = archivePage
            if (archiveData.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/accounts/users/?status=false&archive=true&is_dummy=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadArchiveData(url, sizePerPage, new_page)
          }
          setSuccess_confirm(true)
          setAlert_confirm(false)
          setDynamic_title('Deleted!')
          setDynamic_description("User Has been Deleted.")
          setSelected_id(null)
          setDeleteUser(null)
        }
      }
      )
  }
  
function FilterData(e, values) {
  let filter_data = "";

  if (!search_by.value) {
    toast.error("Select Search Field");
  } else {
    
      if (search_by.value === "date_joined"){
        filter_data = `&from_date=${fromDate}&to_date=${toDate}`
      }
      else{
        filter_data = `&${search_by.value}=${values.search}`;
      }
      // Check if the order_by field is provided and add it to the filter_data
     
     if (order_by.value && search_by.value) {
      // filter_data = filter_data + `&order_by=${order_by.value}`;
      
      filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
      console.log(filter_data,'withorder')

      }

 
    loadData(`${BASE_URL}/api/accounts/users/?status=true&archive=false&is_dummy=false${filter_data}`, 20, 1)
    loadDisabledData(`${BASE_URL}/api/accounts/users/?status=false&archive=false&is_dummy=false${filter_data}`, 20, 1)
    loadArchiveData(`${BASE_URL}/api/accounts/users/?status=false&archive=true&is_dummy=false${filter_data}`, 20, 1)

    setFilter(filter_data)
    setFilterData(filter_data)

  }
}
  function NoDataIndication() {
    if (loadUserdata && userData.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }
  function ArchiveNoDataIndication() {
    if (loaddata && archiveData.length === 0) {
      return (
        <div className="d-flex align-item-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color='dark' />

        </div>
      )
    }
  }
  function DisabledNoDataIndication() {
    if (loaddata && DisabledUsers.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }

  function handleTableChange(type, { page, sizePerPage, searchText, }) {

    setTimeout(() => {
      setLoadUserData(false)
      setUserData([])
      let new_page = page
      let url = `${BASE_URL}/api/accounts/users/?status=true&archive=false&is_dummy=false&page_size=${sizePerPage}&page=${new_page}`
     if (filterdata!==''){
      url=url + `&${filterdata}`
     }
      window.scrollTo(0, 0)
      loadData(url, sizePerPage, new_page, searchText)
    }, 1000);
  }
  function handleArchiveTableChange(type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setLoaData(false)
      setArchiveData([])
      let new_page = page
      let url = `${BASE_URL}/api/accounts/users/?status=false&archive=true&is_dummy=false&page_size=${sizePerPage}&page=${new_page}`
      if (filterdata!==''){
        url=url +`&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadArchiveData(url, sizePerPage, new_page, searchText)
    }, 1000);

  }

  function handleDisableTableChange(type, { page, sizePerPage, searchText, }) {

    setTimeout(() => {
      setLoaData(false)
      setDisabledUsers([])
      let new_page = page
      let url = `${BASE_URL}/api/accounts/users/?status=false&archive=false&is_dummy=false&page_size=${sizePerPage}&page=${new_page}`
      if (filterdata!==''){
        url=url + `&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadDisabledData(url, sizePerPage, new_page, searchText)
    }, 1000);
  }


  function HandleBulkOpertation(value) {
    
    let data = {}
    if (activeTab === "2") {
      data["action"] = value
      data["users"] = ids
    } else if (activeTab === "3") {
      data["action"] = value
      data["users"] = DisabledIds
    } else if (activeTab === '4') {
      data['action'] = value
      data['users'] = archiveIds
    }

    post(`${BASE_URL}/api/accounts/users-bulk-action/`, data,
      { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } },
      { validateStatus: false }
    )
      .then(res => {
        if (res.status >= 400) {
          toast.error("Somthing Went Wrong")
        }
        else {
          console.log(res)
          if (activeTab === '2') {

            let new_page = page
            if (userData.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/accounts/users/?status=true&archive=false&is_dummy=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadData(url, sizePerPage, new_page)
            setIds([])
            setUserData([])


          } else if (activeTab === '3') {

            let new_page = DisabledPage
            if (DisabledUsers.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/accounts/users/?status=false&archive=false&is_dummy=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadDisabledData(url, sizePerPage, new_page)
            setDisabledIds([])
            setDisabledUsers([])


          } else if (activeTab === '4') {
            let new_page = archivePage
            if (archiveData.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/accounts/users/?status=false&archive=true&is_dummy=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadArchiveData(url, sizePerPage, new_page)
            setArchiveIds([])
            setArchiveData([])
          }
          if (value === "disable") {
            loadDisabledData(`${BASE_URL}/api/accounts/users/?status=false&archive=false&is_dummy=false${filter}`, 20, 1)
          } else if (value === "enable") {
            loadData(`${BASE_URL}/api/accounts/users/?status=true&archive=false&is_dummy=false${filter}`, 20, 1)
          } else if (value === "archive") {
            loadArchiveData(`${BASE_URL}/api/accounts/users/?status=false&archive=true&is_dummy=false${filter}`, 20, 1)
          }
        }
      }
      )
  }

  function handleOnSelect(row, isSelect) {
    let id = []
    if (isSelect) {
      id = ids
      id.push(row.id)
    } else {
      for (let i = 0; i < ids.length; i++) {
        if (ids[i] !== row.id) {
          id.push(ids[i])
        }
      }

    }
    setIds(id)
  }

  function handleOnSelectAll(isSelect, rows) {
    if (isSelect) {
      let email = []
      let id = []
      for (let i = 0; i < rows.length; i++) {
        email.push(rows[i].email.toLowerCase())
        id.push(rows[i].id)
      }
      setIds(id)
    } else {
      setIds([])
    }
  }

  function handleOnSelectDisabledRow(row, isSelect) {
    let id = []
    if (isSelect) {
      id = DisabledIds
      id.push(row.id)
    } else {
      for (let i = 0; i < DisabledIds.length; i++) {
        if (DisabledIds[i] !== row.id) {
          id.push(DisabledIds[i])
        }
      }

    }
    setDisabledIds(id)
  }
  function handleOneSelectArchiveRow(row, isSelect) {
    let id = []
    if (isSelect) {
      id = archiveIds
      id.push(row.id)
    } else {
      for (let i = 0; i < archiveIds.length; i++) {
        if (archiveIds[i] !== row.id) {
          id.push(archiveIds[i])
        }
      }
    }
    setArchiveIds(id)
  }


  function handleOneSelectAllArchiveRow(isSelect, rows) {
    if (isSelect) {
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setArchiveIds(id)
    } else {
      setArchiveIds([])

    }
  }

  function handleOnSelectAllDisabledRow(isSelect, rows) {
    if (isSelect) {
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setDisabledIds(id)
    } else {
      setDisabledIds([])
    }
  }


  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };


  const selectRowforDisabled = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelectDisabledRow,
    onSelectAll: handleOnSelectAllDisabledRow
  };
  const selectArchiveRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOneSelectArchiveRow,
    onSelectAll: handleOneSelectAllArchiveRow


  }
  const defaultSorted = [{
    dataField: 'f_name',
    order: 'desc',
    savestate: true
  }];

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]

  }
  const ArcivePageOptions = {
    page: archivePage,
    sizePerPage: sizePerPage,
    totalSize: archiveTotalSize,
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    },
    {
      text: '200', value: 200
    }]
  }

  const DisabledpageOptions = {
    page: DisabledPage,
    sizePerPage: sizePerPage,
    totalSize: DisabledTotalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]
  }

  function handleSelectGroup(selectedGroup){
    setselectedGroup(selectedGroup)

  }
  function handleDisableGroup(selectedDisableGroup){
    setselectedDisableGroup(selectedDisableGroup)
  }
  function handleArchiveGroup(selectArchiveGroup){
    setselectedArchiveGroup(selectArchiveGroup)
  }
  function handleBulkEnableGroup(){
   if (activeTab ==='2'){
    if (selectedGroup.value === 'disable'){
      ids.length > 0 ? HandleBulkOpertation('disable') : toast.error("Select user")
    }else if (selectedGroup.value==='archive'){
      ids.length > 0 ? HandleBulkOpertation('archive') : toast.error("Select user")

    }
   }

  }
  function handleBulkDisable(){
    if (activeTab ==='3'){
      if (selectedDisableGroup.value==='enable'){
        DisabledIds.length > 0 ? HandleBulkOpertation('enable') : toast.error("Select user")

      } else if (selectedDisableGroup.value==='archive'){
        DisabledIds.length > 0 ? HandleBulkOpertation('archive') : toast.error("Select user")

      }
      else if (selectedDisableGroup.value==='delete'){
        DisabledIds.length > 0 ? HandleBulkOpertation('delete') : toast.error("Select user")

      }
    
    }
  }
  function handleBulkArchive(){
    if (activeTab ==='4'){
      if (selectedArchiveGroup.value==='enable'){
        archiveIds.length > 0 ? HandleBulkOpertation('enable') : toast.error("Select user")

      }else if(selectedArchiveGroup.value ==='disable'){

        archiveIds.length > 0 ? HandleBulkOpertation('disable') : toast.error("Select user")
      }
      else if(selectedArchiveGroup.value ==='delete'){

        archiveIds.length > 0 ? HandleBulkOpertation('delete') : toast.error("Select user")


      }
    }
  }



  document.title = pageTitle + " | FAHC Admin Dashboard"
  
  
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Users' link="/dashboard" />
        <Col xs={12}>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Active</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Inactive</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === '4',
                    })}
                    onClick={() => {
                      toggle("4")
                    }}>
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Archive</span>
                  </NavLink>
                </NavItem>

              </Nav>

              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="2">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={userData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={userData}
                        columns={Activecolumns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <AvForm className="needs-validation" onValidSubmit={FilterData}>
                              <Row className="mb-2">
                              <Col sm="3">
                              <Select
                                  value={selectedGroup}
                                  onChange={(e) => {
                                    handleSelectGroup(e);
                                  }}
                                  options={EnableGroup}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col sm='2'>
                                <Button type='button' className="me-2" color='primary' onClick={()=>handleBulkEnableGroup()}>Apply</Button>
                              </Col>
                                <Col sm="3">
                                  <div className="text-sm-start mb-2">
                                    <Select
                                      value={search_by}
                                      onChange={(e) => {
                                        setSearchby(e)
                                      }}
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>


                                </Col>
                                <Col sm="4">
                                <ExportToExcel apiData={userExportData} fileName={"Users"} /> 
                                </Col>
                                {search_by.value ?
                                  <Col sm="9">
                                    <Row>
                                      <Col sm="5">
                                        { search_by.value === 'date_joined' ?
                                            <Row>
                                              <Col sm="6">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="from_date"
                                                    placeholder="Received From"
                                                    type="date"
                                                    errorMessage="From Date"
                                                    className="form-control"
                                                    value={fromDate}
                                                    onChange={(e)=> setFromDate(e.target.value)}
                                                    id="from_date"
                                                  />
                                                </div>
                                              
                                              </Col>
                                              <Col sm="6">
                                                <div className="text-sm-end mb-2">
                                                <AvField
                                                    name="to_date"
                                                    placeholder="Category"
                                                    type="date"
                                                    errorMessage="To Date"
                                                    className="form-control"
                                                    value={toDate}
                                                    onChange={(e)=> setToDate(e.target.value)}
                                                    id="to_date"
                                                  />
                                                </div>
                                            </Col>

                                            </Row>
                                            :search_by.value !== null ?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>

                                              </Col>

                                            </Row>

                                            : null}

                                      </Col>
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={order_by}
                                            onChange={(e) => {
                                              setOrder_by(e)
                                            }}
                                            options={sortOptionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">
                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                          Filter
                                        </Button>
                                        {" "}
                                        <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                          Clear
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                  : null}



                              </Row >
                            </AvForm>

                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleTableChange}
                                noDataIndication={() => NoDataIndication()}
                                selectRow={selectRow}
                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>

                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </TabPane>
                <TabPane tabId="3" >
                  <PaginationProvider
                    pagination={paginationFactory(DisabledpageOptions)}
                    data={DisabledUsers}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={DisabledUsers}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <AvForm className="needs-validation" onValidSubmit={FilterData}>
                              <Row className="mb-2">
                              <Col sm="3">
                              <Select
                                  value={selectedDisableGroup}
                                  onChange={(e) => {
                                    handleDisableGroup(e);
                                  }}
                                  options={DisableGroup}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col sm='2'>
                                <Button type='button' className="me-2"  color='primary' onClick={()=>handleBulkDisable()}>Apply</Button>
                              </Col>
                                <Col sm="3">
                                  <div className="text-sm-start mb-2">
                                    <Select
                                      value={search_by}
                                      onChange={(e) => {
                                        setSearchby(e)
                                      }}
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>


                                </Col>
                                <Col sm="4">
                                <ExportToExcel apiData={DisabledUsers} fileName={"Disabled Users"} /> 
                                </Col>
                                {search_by.value ?
                                  <Col sm="9">
                                  <Row>
                                    <Col sm="5">
                                      { search_by.value === 'date_joined' ?
                                          <Row>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="from_date"
                                                  placeholder="Received From"
                                                  type="date"
                                                  errorMessage="From Date"
                                                  className="form-control"
                                                  value={fromDate}
                                                  onChange={(e)=> setFromDate(e.target.value)}
                                                  id="from_date"
                                                />
                                              </div>
                                            
                                            </Col>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                              <AvField
                                                  name="to_date"
                                                  placeholder="Category"
                                                  type="date"
                                                  errorMessage="To Date"
                                                  className="form-control"
                                                  value={toDate}
                                                  onChange={(e)=> setToDate(e.target.value)}
                                                  id="to_date"
                                                />
                                              </div>
                                          </Col>

                                          </Row>
                                          :search_by.value !== null ?
                                          <Row>
                                            <Col sm="12">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="search"
                                                  placeholder={search_by.label}
                                                  type="text"
                                                  value={search}
                                                  onChange={(e) => setSearch(e.target.value)}
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                />
                                              </div>

                                            </Col>

                                          </Row>

                                          : null}

                                    </Col>
                                    <Col sm="3">
                                      <div className="text-sm-start mb-2">
                                        <Select
                                          value={order_by}
                                          onChange={(e) => {
                                            setOrder_by(e)
                                          }}
                                          options={sortOptionGroup}
                                          classNamePrefix="select2-selection"
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="4">
                                      <Button type="submit" color="success" className="btn-rounded me-2" >
                                        Filter
                                      </Button>
                                      {" "}
                                      <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                        Clear
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                                  : null}

                              </Row>
                            </AvForm>
                           
                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleDisableTableChange}
                                noDataIndication={() => DisabledNoDataIndication()}
                                selectRow={selectRowforDisabled}

                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}

                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>


                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>

                </TabPane>
                <TabPane tabId="4">
                  <PaginationProvider
                    pagination={paginationFactory(ArcivePageOptions)}
                    data={archiveData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={archiveData}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <AvForm className="needs-validation" onValidSubmit={FilterData}>
                              <Row className="mb-2">
                              <Col sm="3">
                              <Select
                                  value={selectedArchiveGroup}
                                  onChange={(e) => {
                                    handleArchiveGroup(e);
                                  }}
                                  options={ArchiveGroup}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col sm='2'>
                                <Button type='button'  className="me-2" color='primary' onClick={()=>handleBulkArchive()}>Apply</Button>
                              </Col>
                                <Col sm="3">
                                  <div className="text-sm-start mb-2">
                                    <Select
                                      value={search_by}
                                      onChange={(e) => {
                                        setSearchby(e)
                                      }}
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>

                                </Col>
                                <Col sm="4">
                                <ExportToExcel apiData={archiveData} fileName={"Archive Users"} /> 
                                </Col>
                                {search_by.value ?
                                  <Col sm="9">
                                  <Row>
                                    <Col sm="5">
                                      { search_by.value === 'date_joined' ?
                                          <Row>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="from_date"
                                                  placeholder="Received From"
                                                  type="date"
                                                  errorMessage="From Date"
                                                  className="form-control"
                                                  value={fromDate}
                                                  onChange={(e)=> setFromDate(e.target.value)}
                                                  id="from_date"
                                                />
                                              </div>
                                            
                                            </Col>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                              <AvField
                                                  name="to_date"
                                                  placeholder="Category"
                                                  type="date"
                                                  errorMessage="To Date"
                                                  className="form-control"
                                                  value={toDate}
                                                  onChange={(e)=> setToDate(e.target.value)}
                                                  id="to_date"
                                                />
                                              </div>
                                          </Col>

                                          </Row>
                                          :search_by.value !== null ?
                                          <Row>
                                            <Col sm="12">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="search"
                                                  placeholder={search_by.label}
                                                  type="text"
                                                  value={search}
                                                  onChange={(e) => setSearch(e.target.value)}
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                />
                                              </div>

                                            </Col>

                                          </Row>

                                          : null}

                                    </Col>
                                    <Col sm="3">
                                      <div className="text-sm-start mb-2">
                                        <Select
                                          value={order_by}
                                          onChange={(e) => {
                                            setOrder_by(e)
                                          }}
                                          options={sortOptionGroup}
                                          classNamePrefix="select2-selection"
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="4">
                                      <Button type="submit" color="success" className="btn-rounded me-2" >
                                        Filter
                                      </Button>
                                      {" "}
                                      <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                        Clear
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                                  : null}



                              </Row >
                            </AvForm>


                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleArchiveTableChange}
                                noDataIndication={() => ArchiveNoDataIndication()}
                                selectRow={selectArchiveRow}
                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>

                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
        {alert_confirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleDeleteUser(deleteUser)}
            onCancel={() => {
              setAlert_confirm(false)
              setSelected_id(null)
              setDeleteUser(null)
            }
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {success_confirm ? (
          <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setSuccess_confirm(false)
              setAlert_confirm(false)
              setDeleteUser(null)
            }
            }
          >
            {dynamic_description}
          </SweetAlert>
        )
          : null
        }

      </div>
      

    </React.Fragment>
  )
}

export default Users
