

const accessToken = getAccessTokens()

  
export default accessToken

function getAccessTokens(){
  const obj = JSON.parse(localStorage.getItem("authUser"))
  return obj? `Bearer ${obj.access}`: ""
}


export function getAccessToken(){
  const obj = JSON.parse(localStorage.getItem("authUser"))
  return obj? `Bearer ${obj.access}`: ""
}

