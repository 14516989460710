import React, { useState, useEffect } from "react"
// import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row, Label, Modal, UncontrolledAlert
} from "reactstrap"

//i18n
// import { withTranslation } from "react-i18next"
// Redux
// import { connect } from "react-redux"
// import { withRouter, Link } from "react-router-dom"
import {  Link } from "react-router-dom"

import { AvForm, AvField } from "availity-reactstrap-validation"
// users
// import user4 from "../../../assets/images/users/avatar-7.jpg"
import { BASE_URL } from "../../../helpers/url_helper"
import {  post } from "../../../helpers/api_helper"
import { toast } from 'react-toastify';

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [modal_changePassword, setModal_changePassword] = useState(false)
  const [alert, setAlert] = useState({"error": false, "error_msg":"" })
  const [img,setImg]=useState(null)
  const [username, setusername] = useState("Admin")



  useEffect(() => {
    if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.user.username)
        setImg(obj.user.image)
        console.log(obj.user.image,'profileueserimage')
    
    }
    
  }, [])

  function HandleLockScreen(){
    localStorage.setItem("lockScreen", true);
    props.history.push('/auth-lock-screen');

  }
 
  function tog_changePassword() {
    setModal_changePassword(!modal_changePassword)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  
  function handleValidSubmit(event, values) {
    // setSubmitting(true)

    post(`${BASE_URL}/api/accounts/change-password/`,
    values,
    {  validateStatus: false} )
    .then(resp => {
      if (resp.status >= 400){
        setAlert({"error": true, "error_msg":resp.data.old_password?resp.data.old_password:resp.data.new_password })
        toast.error("Somthing Went Wrong")

      }else{
        setAlert({"error": false, "error_msg":"" })       
        toast.success("Password updated successfully")

        tog_changePassword()
      }

    })
  }

  
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={img}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1">{username}</span>{" "}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>{" "}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
            {("View Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="#" onClick={tog_changePassword}>
            {" "}
            <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>{" "}
            {("Change Password")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="#" onClick={HandleLockScreen}>
            <i className="bx bx-lock-open font-size-16 align-middle me-1"></i>{" "}
            {("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
            <span>{("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

            <Modal
              isOpen={modal_changePassword}
              toggle={() => {
                tog_changePassword()
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Change Password
                    </h5>
                <button
                  type="button"
                  onClick={() => {
                    setModal_changePassword(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
             {alert?
              <AvForm className="needs-validation" onValidSubmit={handleValidSubmit}>
              <div className="modal-body">
              { alert.error? (
                  <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">{alert.error_msg}</UncontrolledAlert>
                ) : null}
                
                {/* {alert? 
                
                :null} */}
                
                  <Row className="mb-3">
                      <Label htmlFor="old_password" className="col-md-3 col-form-label">Current Password:</Label>
                      <div className="col-md-9">
                          <AvField
                              name="old_password"
                              placeholder="Current Password"
                              type="password"
                              errorMessage="Current Password"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="old_password"
                              value={alert.old_password}
                          />
                      </div>
                  </Row>
                  <Row className="mb-3">
                      <Label htmlFor="new_password" className="col-md-3 col-form-label">New Password:</Label>
                      <div className="col-md-9">
                          <AvField
                              name="new_password"
                              placeholder="Enter New Password"
                              type="password"
                              errorMessage="Enter New Password"
                              className="form-control"
                              validate={{ required: { value: true },
                                          minLength: {value: 6, errorMessage: 'Your password must be grater than 6 characters'},
                                         }}
                              id="new_password"
                              value={alert.new_password}
                          />
                      </div>
                  </Row>
                  <Row className="mb-3">
                      <Label htmlFor="new_password1" className="col-md-3 col-form-label">Confirm Password:</Label>
                      <div className="col-md-9">
                          <AvField
                              name="new_password1"
                              placeholder="Enter Confirm Password"
                              type="password"
                              errorMessage="Enter Confirm Password"
                              className="form-control"
                              validate={{ required: { value: true },
                                          match: { value: "new_password" }, }}
                              id="new_password1"
                              value={alert.new_password}
                          />
                      </div>
                  </Row>
                  
              
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Change
                </button>
                <button
                  type="button"
                  onClick={() => {
                    tog_changePassword()
                  }}

                  className="btn btn-primary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
                
              </div>
            </AvForm>
             :null}
            </Modal>
   
    </React.Fragment>
  )
}

// ProfileMenu.propTypes = {
//   success: PropTypes.any,
//   t: PropTypes.any
// }

// const mapStatetoProps = state => {
//   const { error, success } = state.Profile
//   return { error, success }
// }

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
// )


export default ProfileMenu

