import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { get, put, post } from "../../helpers/api_helper";
import { toast } from 'react-toastify';
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"


const EditCustomer = (props) => {
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)
    const [submitting, setSubmitting] = useState(null)
    const [img, setImg] = useState(null)
    const [modal_standard, setmodal_standard] = useState(false)
    const [modelResetPaswrd, setModelResetPaswrd] = useState(false)
    const [ischecked, setIsChecked] = useState(null)

    const [pageTitle, setPageTitle] = useState('User')



    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function tog_resetPasswod() {
        setModelResetPaswrd(!modelResetPaswrd)
        removeBodyCss()
    }


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        setDidMount(true);
        get(`${BASE_URL}/api/accounts/users/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setUser(response.data)
                setPageTitle(`${response.data.first_name} ${response.data.last_name}`)

                setIsChecked(response.data.status)
                console.log(response.data.id,'EditUserId')
                if (response.data.id ===undefined){
                    props.history.push('/users')
                }

            })
        return () => setDidMount(false);

    }, [id,props.history]);

    if (!didMount) {
        return null;
    }
    
    const handleFileUpload = (e) => {
        try {
            let reader = new FileReader();
            let file = e.target.files[0];
            if (!file){
                return;
            }
            const fileSizeInKB=file.size/1024;
            if (fileSizeInKB>30){
                toast.error('Please select an image file smaller than 30 kB');
                setImg(null)
            }else{
                reader.onloadend = () => {
                    var previewImgUrl = reader.result
                    setImg(previewImgUrl)
                }
                reader.readAsDataURL(file);
            }
           
        } catch (error) {

        }
    }

    function handleSubmit(e, values) {
        setSubmitting(true)
        console.log(JSON.stringify({ ...values ,status:ischecked}))
        put(`${BASE_URL}/api/accounts/users/${id}/`, { ...values ,status:ischecked},
            { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken()  }, validateStatus: false }
        )
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Somthing Went Wrong ")
                    console.log(response)
                    setSubmitting(false)
                } else {
                    console.log(response,'id of user')
                    props.history.push("/users")
                }
                if (response.id ===null){
                    props.history.push("/users")
                }
            
            })

    }
  
      
    function handleImageSubmit(e, values) {
       if (img){
        setSubmitting(true)
        put(`${BASE_URL}/api/accounts/users/${id}/`, { ...values, image: img },
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false })
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Somethin Went Wrong")
                    setSubmitting(false)

                } else {
                    props.history.push('/users')
                }
            })
       }else{
        toast.error('Please select an image file smaller than 30 kB');

       }
    }

    function ResetPassword(e, values){
        setSubmitting(true)
        post(`${BASE_URL}/api/accounts/reset-password/`, { "user":id, "password": values.password },
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false })
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Somethin Went Wrong")
                    setSubmitting(false)

                } else {
                    toast.success("Password Reset Successfully")
                    setModelResetPaswrd(false)
                    setSubmitting(false)
                }
            }).catch((e) => {
                toast.error("Somethin Went Wrong")
            })
    }

    document.title = pageTitle + " | FAHC Admin Dashboard"

    return (
        <React.Fragment>
            <div className="page-content" >

                <Breadcrumbs title="Users" breadcrumbItem={user?user.email:"update User"} link="/users" />
                {user ?
                    <Row>
                        <Col md="12">

                            <Row>
                            <Col sm="4">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col sm='12'>
                                                    <div className="text-end">
                                                        <Button color='link' onClick={() => tog_standard()}>
                                                            <i className="bx bx-edit-alt" style={{'fontSize':'30px'}}></i>
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <img src={img?img:user.image?user.image:null} alt="Selected" />

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="8">
                                    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="6">
                                                        <CardTitle>User Details </CardTitle>
                                                    </Col>
                                                    <Col sm="6">
                                                        <Button color='link' className="float-end" onClick={() => tog_resetPasswod()}>
                                                            Reset Password
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="image">User Name</Label>
                                                            <AvField
                                                                name="username"
                                                                placeholder="First Name"
                                                                type="text"
                                                                className="form-control"

                                                                id="username"
                                                                value={user.username}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="email">Email</Label>
                                                            <AvField
                                                                name="email"
                                                                placeholder="Enter Email"
                                                                type="email"
                                                                errorMessage=" Please Enter Email."
                                                                className="form-control"
                                                                // disabled
                                                                id="email"
                                                                value={user.email}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="first_name">First Name</Label>
                                                            <AvField
                                                                name="first_name"
                                                                placeholder="First Name"
                                                                type="text"
                                                                errorMessage=" Please Enter First Name."
                                                                className="form-control"

                                                                id="first_name"
                                                                value={user.first_name}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="last_name">Last Name</Label>
                                                            <AvField
                                                                name="last_name"
                                                                placeholder="Last Name"
                                                                type="text"
                                                                errorMessage=" Please Enter Last Name."
                                                                className="form-control"

                                                                id="last_name"
                                                                value={user.last_name}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>

                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="address">Address</Label>
                                                            <AvField
                                                                name="address"
                                                                placeholder="Address"
                                                                type="text"
                                                                errorMessage=" Please Enter Address."
                                                                className="form-control"

                                                                id="address"
                                                                value={user.address}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="city">City</Label>
                                                            <AvField
                                                                name="city"
                                                                placeholder="City"
                                                                type="text"
                                                                errorMessage=" Please provide a valid city."
                                                                className="form-control"

                                                                id="city"
                                                                value={user.city}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="state">State</Label>
                                                            <AvField
                                                                name="state"
                                                                placeholder="State"
                                                                type="text"
                                                                errorMessage=" Please provide a valid State."
                                                                className="form-control"

                                                                id="state"
                                                                value={user.state}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="zipcode">Zip</Label>
                                                            <AvField
                                                                name="zipcode"
                                                                placeholder="Zip Code"
                                                                type="text"
                                                                className="form-control"
                                                                id="zipcode"
                                                                value={user.zipcode}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                                <Row>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="phone"
                                                                label="Phone"
                                                                placeholder="Contact Number"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9+-]+$",
                                                                        errorMessage: "Invalid Contact"
                                                                    },
                                                                }}
                                                                value={user.phone}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="company">Company</Label>
                                                            <AvField
                                                                name="company"
                                                                placeholder="Company"
                                                                type="text"
                                                                className="form-control"
                                                                id="company"
                                                                value={user.company}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="website">Website</Label>
                                                            <AvField
                                                                name="website"
                                                                placeholder="Website URL"
                                                                type="text"
                                                                className="form-control"
                                                                id="website"
                                                                value={user.website}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="fb">Facebook</Label>
                                                            <AvField
                                                                name="fb"
                                                                placeholder="Facebook URL"
                                                                type="text"
                                                                className="form-control"
                                                                id="fb"
                                                                value={user.fb}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="insta">Instagram</Label>
                                                            <AvField
                                                                name="insta"
                                                                placeholder="Instagram URL"
                                                                type="text"
                                                                className="form-control"
                                                                id="insta"
                                                                value={user.insta}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="twitter">Twitter</Label>
                                                            <AvField
                                                                name="twitter"
                                                                placeholder="Twitter URL"
                                                                type="text"
                                                                className="form-control"
                                                                id="twitter"
                                                                value={user.twitter}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="latitude">Latitude</Label>
                                                            <AvField
                                                                name="latitude"
                                                                placeholder="Latitude"
                                                                type="text"
                                                                className="form-control"
                                                                id="latitude"
                                                                value={user.latitude}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="longitude">Longitude</Label>
                                                            <AvField
                                                                name="longitude"
                                                                placeholder="Longitude"
                                                                type="text"
                                                                className="form-control"
                                                                id="longitude"
                                                                value={user.longitude}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>

                                                    <Col md="12">
                                                        <AvField
                                                            className="mb-3"
                                                            type="textarea"
                                                            label="About"
                                                            rows="3"
                                                            name="about"
                                                            id="about"
                                                            value={user.about}

                                                        />
                                                    </Col>
                                                    
                                                </Row>
                                                <Row>
                                                   
                                                    <Col md="6">
                                                    <div className="checkbox-wrapper mb-3" >
                                                            <label>
                                                                <input type="checkbox"
                                                                    checked={ischecked}
                                                                    onChange={(e) => setIsChecked(e.target.checked)} /> {"      "}
                                                                Status
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    
                                                </Row>
                                                {submitting ?
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary waves-effect waves-light my-2"
                                                    >
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                        Saving
                                                    </button>
                                                    :
                                                    <Button color="primary" type="submit" className="waves-effect waves-light my-2">
                                                        Save
                                                    </Button>
                                                }
                                            </CardBody>
                                        </Card>
                                    </AvForm>
                                </Col>

                            </Row>


                        </Col>
                        <Row>
                            <Col sm={6} md={4} xl={3}>

                                <Modal
                                    isOpen={modal_standard}
                                    toggle={() => {
                                        tog_standard()
                                    }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0 " style={{ 'textAlign': 'center' }} id="myModalLabel">
                                            Image
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setmodal_standard(false)
                                            }}
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">


                                        <Col lg='12'>
                                            <AvForm className="needs-validation" onValidSubmit={handleImageSubmit}>

                                                <Row>
                                                    <Col md={12}>
                                                        <div class="text-center">


                                                        </div>

                                                    </Col>



                                                    {/* <img src={img} alt="Selected" /> */}

                                                    <input type='file' accept="image/png, image/jpeg" className="mb-3 mr-5" onChange={handleFileUpload} />


                                                </Row>


                                                <div className="modal-footer">
                                                    {submitting ?

                                                        <button
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light my-3"
                                                        >
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                            Saving
                                                        </button>
                                                        :
                                                        <Button color="primary"  type="submit">
                                                            Save
                                                        </Button>
                                                    }{" "}
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            tog_standard();
                                                            setImg(null)
                                                        }}
                                                        className="btn btn-primary waves-effect"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>

                                                </div>


                                            </AvForm>
                                        </Col>


                                    </div>

                                </Modal>
                            </Col>
                            <Col sm={6} md={4} xl={3}>

                            <Modal
                                isOpen={modelResetPaswrd}
                                toggle={() => {
                                    tog_resetPasswod()
                                }}
                                >
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                    Reset Password
                                        </h5>
                                    <button
                                    type="button"
                                    onClick={() => {
                                        setModelResetPaswrd(false)
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    >
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                
                                <AvForm className="needs-validation" onValidSubmit={ResetPassword}>
                                <div className="modal-body">
                                    <Row className="mb-3">
                                        <Label htmlFor="password" className="col-md-3 col-form-label">New Password:</Label>
                                        <div className="col-md-9">
                                            <AvField
                                                name="password"
                                                placeholder="Enter New Password"
                                                type="password"
                                                errorMessage="Enter New Password"
                                                className="form-control"
                                                validate={{ required: { value: true },
                                                            minLength: {value: 6, errorMessage: 'Your password must be grater than 6 characters'},
                                                            }}
                                                id="password"
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="new_password" className="col-md-3 col-form-label">Confirm Password:</Label>
                                        <div className="col-md-9">
                                            <AvField
                                                name="new_password"
                                                placeholder="Enter Confirm Password"
                                                type="password"
                                                errorMessage="Enter Confirm Password"
                                                className="form-control"
                                                validate={{ required: { value: true },
                                                            match: { value: "password" }, }}
                                                id="new_password"
                                            />
                                        </div>
                                    </Row>
                                    
                                
                                </div>
                                <div className="modal-footer">
                                    <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light"
                                    >
                                    Reset
                                    </button>
                                    <button
                                    type="button"
                                    onClick={() => {
                                        tog_resetPasswod()
                                    }}

                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                    >
                                    Close
                                    </button>
                                    
                                </div>
                                </AvForm>
                                
                                </Modal>
                            </Col>
                            

                        </Row>
                    </Row>
                    :
                    <div id="preloader">
                        <div id="status">
                            <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                            </div>
                        </div>
                    </div>
                }

            </div>

        </React.Fragment>
    )
}

export default EditCustomer
