import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Spinner, TabPane, Button, Modal, Badge, NavLink, NavItem, Nav, TabContent } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import Select from "react-select";
import { Link } from "react-router-dom"
import classnames from "classnames"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get, post } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import moment from "moment-timezone";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const optionGroup = [
  {
    label: "Bulk Action",
    options: [
      { label: "Move to Delete", value: "delete" },
      { label: "Move to Pending", value: "pending" },
      { label: "Move to Resolved", value: "resolved" }
    ]
  }
];

const FlaggedEventComments = (props) => {
  const [userData, setUserData] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)

  const [resolvedData, setResolvedData] = useState([]);
  const [totalResolvedSize, settotalResolvedSize] = useState(0);
  const [resolvedPage, setResolvedPage] = useState(1)
  const [resolvedSizePerPage, setResolvedsizePerPage] = useState(20)

  const [loaddata, setLoaData] = useState(false)
  const [loadResolvedata, setLoadResolveData] = useState(false)

  const [ids, setIds] = useState([])
  const [resloveIds, setResolvedIds] = useState([])

  const [modal_standard, setmodal_standard] = useState(false)

  const [selectedGroup, setselectedGroup] = useState({ 'label': "Perform Bulk Action", 'value': null })
  const [selectedComment, setSelectedComment] = useState(" ")

  const [activeTab, setactiveTab] = useState("1")


  ValidateUser(props)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }


  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,

    },
    {
      dataField: 'event_report',
      text: 'Event Name',
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        // <Link to="#" className="me-3 text-dark">{row.event_report ? row.event_report.name : null}</Link>
        <a href={`https://findahousechurch.com/post/event/${row?.event_report?.id}`} rel="noreferrer" target="_blank" className="me-3 text-dark" > {row.event_report ? row.event_report.name : null}</a>
      ),

    },
    {
      dataField: 'user_id',
      text: 'Report By',
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={{ pathname: `/user-detail/${row.user_id.id}`,statedata: { 'url': "/flagged-event-posts", 'page': "Flagged Profile" } }} className="me-3 text-dark">{row.user_id ? row.user_id.first_name + " " + row.user_id.last_name : null}</Link>
      ),

    },

    {
      dataField: 'report_message',
      text: "Message",
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (

        <Link to="#" className="me-3 text-primary" onClick={() => { tog_standard(); setSelectedComment(cellContent) }} style={{ 'fontSize': '20px' }} ><i className="mdi mdi-comment-account"></i></Link>

      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => {
        let badgeClassName=" ";
        let badgeText=" ";
        if (row.status==='resolved'){
            badgeClassName="bg-success"
            badgeText="Resolved"
        }else if (row.status ==='pending'){
          badgeClassName="bg-danger"
          badgeText="Pending"
        }
        return(
          <Link
          to="#"
          className="me-3 text-dark"
        >
          <Badge className={badgeClassName}>{badgeText}</Badge>
        </Link>
        );
      }
    },

    {
      dataField: 'created_at',
      text: 'Date',
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={{ pathname: `/user-detail/${row.user_id.id}`, statedata: { 'url': "/flagged-event-posts", 'page': "Flagged Profile" } }} className="me-3 text-dark">{moment(new Date(row.created_at)).format("MM-DD-YYYY")}</Link>
      ),

    },
    {
      dataField: "View",
      isDummyField: true,
      headerFormatter: HeaderFormatter,
      text: "View",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <a href={`https://findahousechurch.com/member/${row.user_id.slug}`} target="_blank" rel="noreferrer" className="me-3 text-primary" >  <i className="fas fa-user" style={{ 'fontSize': '20px' }}></i> </a>
          <a href={`https://findahousechurch.com/post/event/${row?.event_report?.id}`} rel="noreferrer" target="_blank" className="me-3 text-primary" >  <i className="mdi mdi-eye" style={{ 'fontSize': '20px' }}></i></a>
          
        </React.Fragment>

      ),
    },

    {

    },

  ]

  useEffect(() => {
  
    loadData(`${BASE_URL}/api/house_church/post-report/?report_type=event&type=comment&status=pending`, 20, 1)
    loadResolvedData(`${BASE_URL}/api/house_church/post-report/?report_type=event&type=comment&status=resolved`, 20, 1)


  }, []);
  //get report listing  of profile by user
  function loadData(url, sizePerPage, page) {
    let data = get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } })
    data.then(response => {
      console.log(response.data.results)
      setUserData(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setLoaData(true)
    })
  }
  
  function loadResolvedData(url, sizePerPage, page) {
    let data = get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } })
    data.then(response => {
      setResolvedData(response.data.results)
      settotalResolvedSize(response.data.count)
      setResolvedPage(page)
      setResolvedsizePerPage(sizePerPage)
      setLoadResolveData(true)
    })
  }

  //when length of id is null then show Table is Empty
  function NoDataIndication() {
    if (loaddata && userData.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }
  function resolvedNoDataIndication() {
    if (loadResolvedata && resolvedData.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }


  //select multiple id with status and check if multple id is in reports then change status
  function HandleBulkOperations(value) {
    let data = {}
    if (activeTab === "1") {
      data["action"] = value
      data["reports"] = ids
    } else{
      data["action"] = value
      data["reports"] = resloveIds
    }
    post(`${BASE_URL}/api/house_church/post-reports-bulk-action/`, data,
      { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } },
      { validateStatus: false }
    )
      .then(res => {
        if (res.status >= 400) {
          toast.error("Somthing Went Wrong")
        }
        else {
          if (activeTab === '1') {

            let new_page = page
            // if (userData.length < 2) {
            //   new_page = new_page > 1 ? new_page - 1 : 1
            // }
            let url = `${BASE_URL}/api/house_church/post-report/?report_type=event&type=comment&status=pending&page_size=${sizePerPage}&page=${new_page}`
            // if (filter !== "") {
            //   url = `${url}${filter}`
            // }
            loadData(url, sizePerPage, new_page)
            setIds([])
            setUserData([])
            loadResolvedData(`${BASE_URL}/api/house_church/post-report/?report_type=event&type=comment&status=resolved`, resolvedSizePerPage, resolvedPage)


          } else {

            let new_page = resolvedPage
            // if (reseolve.length < 2) {
            //   new_page = new_page > 1 ? new_page - 1 : 1
            // }
            let url = `${BASE_URL}/api/house_church/post-report/?report_type=event&type=comment&status=resolved&page_size=${resolvedSizePerPage}&page=${new_page}`
            // if (filter !== "") {
            //   url = `${url}${filter}`
            // }
            loadResolvedData(url, sizePerPage, new_page)
            setResolvedIds([])
            setResolvedData([])
            loadData(`${BASE_URL}/api/house_church/post-report/?report_type=event&type=comment&status=pending`, sizePerPage, page)
          } 


        }
      }
      )
  }
  //change status according of select reports id
  function BulkActions() {
    ids.length > 0 ? HandleBulkOperations(selectedGroup.value) : toast.error("select Reports")

  }

  function ResolvedBulkActions() {
    resloveIds.length > 0 ? HandleBulkOperations(selectedGroup.value) : toast.error("select Reports")

  }

  //using for page change
  function handleTableChange(type, { page, sizePerPage, searchText, }) {

    setTimeout(() => {
      setLoaData(false)
      setUserData([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/post-report/?report_type=event&type=comment&status=pending&page_size=${sizePerPage}&page=${new_page}`
      window.scrollTo(0, 0)
      loadData(url, sizePerPage, new_page, searchText)
    }, 1000);
  }

  function handleResolvedTableChange(type, { page, sizePerPage, searchText, }) {

    setTimeout(() => {
      setLoadResolveData(false)
      setResolvedData([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/post-report/?report_type=event&type=comment&status=resolved&page_size=${sizePerPage}&page=${new_page}`
      window.scrollTo(0, 0)
      loadResolvedData(url, sizePerPage, new_page, searchText)
    }, 1000);
  }

  function handleOnSelect(row, isSelect) {
    let id = []
    if (isSelect) {
      id = ids
      id.push(row.id)
    } else {
      for (let i = 0; i < ids.length; i++) {
        if (ids[i] !== row.id) {
          id.push(ids[i])
        }
      }

    }
    setIds(id)
  }

  function handleOnSelectResolved(row, isSelect) {
    let id = []
    if (isSelect) {
      id = resloveIds
      id.push(row.id)
    } else {
      for (let i = 0; i < resloveIds.length; i++) {
        if (resloveIds[i] !== row.id) {
          id.push(resloveIds[i])
        }
      }

    }
    setResolvedIds(id)
  }

  //select multiple row of active denomination
  function handleOnSelectAll(isSelect, rows) {
    console.log(isSelect,'isSelected')
    if (isSelect) {
      // let email = []
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setIds(id)
    } else {
      setIds([])
    }
  }

  function handleOnSelectAllResolved(isSelect, rows) {
    console.log(isSelect,'isSelected')
    if (isSelect) {
      // let email = []
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setResolvedIds(id)
    } else {
      setResolvedIds([])
    }
  }


  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };

  const selectResolvedRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelectResolved,
    onSelectAll: handleOnSelectAllResolved
  };



  const defaultSorted = [{
    dataField: 'created_at',
    order: 'desc',
    savestate: true
  }];

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]

  }
  const ResolvedpageOptions = {
    page: resolvedPage,
    sizePerPage: resolvedSizePerPage,
    totalSize: totalResolvedSize, 
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]

  }


  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Flagged Event Posts Comment' link="/dashboard" />
        <Col xs={12}>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1")
                    }}
                  >
                   
                    <span className="d-none d-sm-block">Pending</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    
                    <span className="d-none d-sm-block">Resolved</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={userData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={userData}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <AvForm className="needs-validation">
                              <Row className="mb-2">
                                <Col sm='3'>
                                  <Select
                                    value={selectedGroup}
                                    onChange={(e) => {
                                      handleSelectGroup(e);
                                    }}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col sm="2">
                                  <Button type='button' color='primary' onClick={() => BulkActions()}>Apply</Button>
                                </Col>
                              </Row >
                            </AvForm>

                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleTableChange}
                                noDataIndication={() => NoDataIndication()}
                                selectRow={selectRow}
                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>


                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </TabPane>
                <TabPane tabId="2">
                  <PaginationProvider
                    pagination={paginationFactory(ResolvedpageOptions)}
                    data={resolvedData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={resolvedData}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <AvForm className="needs-validation">
                              <Row className="mb-2">
                                <Col sm='3'>
                                  <Select
                                    value={selectedGroup}
                                    onChange={(e) => {
                                      handleSelectGroup(e);
                                    }}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col sm="2">
                                  <Button type='button' color='primary' onClick={() => ResolvedBulkActions()}>Apply</Button>
                                </Col>
                              </Row >
                            </AvForm>

                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleResolvedTableChange}
                                noDataIndication={() => resolvedNoDataIndication()}
                                selectRow={selectResolvedRow}
                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>


                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </TabPane>
              </TabContent>

            </CardBody>
          </Card>
          <Col lg={6}>
            <Modal
              isOpen={modal_standard}
              toggle={() => {
                tog_standard()
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Flagged Profile
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col lg='12'>
                    <p>Comment:{selectedComment}</p>
                  </Col>

                </Row>
              </div>

            </Modal>
          </Col>

        </Col>


      </div>

    </React.Fragment>
  )
}

export default FlaggedEventComments
