import React, { useEffect, useState } from "react"
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Col, Card, CardBody, Spinner, Button, Modal, Label } from "reactstrap"

import { Link } from "react-router-dom"

import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken"

// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import SweetAlert from "react-bootstrap-sweetalert";

import { toast } from "react-toastify";
import { get, del, post } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper";
import { HeaderFormatter } from "../../../helpers/methods";
import { ValidateUser } from "../../../helpers/validate_user";
import { AvForm, AvField } from "availity-reactstrap-validation"
import moment from "moment-timezone";

 const Notifications = (props) => {
  const [notifications, setNotifications] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [submitting, setSubmitting] = useState(null)
  const [modal_standard, setmodal_standard] = useState(false)


  ValidateUser(props)

  const columns = [
    {
      dataField: "id",
      text: "S No.",
      hidden: true
    },
    {
      dataField: "message",
      text: "Notification",
      headerFormatter: HeaderFormatter,
    },
    {
      dataField: "created_at",
      text: "Created",
      headerFormatter: HeaderFormatter,
      formatter:(cellContent)=> (
        moment(cellContent).tz("America/Chicago").format("MM-DD-YYYY")
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      headerFormatter: HeaderFormatter,
      text: "Action",
      formatter: (cellContent, row) => (
        <React.Fragment>
          {/* <Link to="#" className="me-3 text-primary" onClick={() => {  }} ><i className="bx bx-edit-alt"></i></Link> */}
          <Link to="#" className="me-3 text-danger" onClick={() => { setAlert_confirm(true); setSelected_id(row.id) }} ><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>

      ),
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/house_church/notification/?is_global=true`, 20, 1)
    setDidMount(true);
    return () => setDidMount(false);


  }, []);


  if (!didMount) {
    return null;
  }


  //get data of category field
  function loadData(url, sizePerPage, page) {

    get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
      .then(response => {
        setNotifications(response.data.results.results)
        settotalSize(response.data.count)
        setpage(page)
        setsizePerPage(sizePerPage)
        setLoading(true)
      })
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function togdata_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }



  //show Empty data when length of category listing is null 
  function NoDataIndication() {
    if (loading && notifications.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }


  //change the page
  function handleTableChange(type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setLoading(false)
      setNotifications([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/notification/?page_size=${sizePerPage}&page=${new_page}&is_global=true`
      window.scrollTo(0, 0)
      loadData(url, sizePerPage, new_page, searchText)


    }, 1000);
  }

  function handleDeleteTemplate(id) {
    del(`${BASE_URL}/api/house_church/notification/${id}/`,
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } },
      { validateStatus: false }
    )
      .then(res => {
        if (res.status >= 400) {
          toast.error('Somthing Went Wrong')
        }
        else {
          loadData(`${BASE_URL}/api/house_church/notification/?is_global=true`, 20, 1)
          setSuccess_confirm(true)
          setAlert_confirm(false)
          setDynamic_title('Deleted!')
          setDynamic_description("Notification Has beed deleted")
          setSelected_id(null)
        }
      }
      )
  }

  function handleCreateNotification(e, values) {
    let data = {
      "message": values.message,
      "link": "#",
      "is_global": true
  }
  console.log(data, "datadatadatadata")
    setSubmitting(true)
    post(`${BASE_URL}/api/house_church/notification/`, data,
      { headers: { 'Content-Type': 'application/json', }, validateStatus: false })
      .then(response => {
        if (response.status >= 400) {
          setSubmitting(false)
          togdata_standard()
          console.log(response, 'denominationresponse')

        } else {
          setSubmitting(false)
          togdata_standard()
          loadData(`${BASE_URL}/api/house_church/notification/?is_global=true`, 20, 1)
          console.log(response, 'succesdenominationresponse')
        }
      })
  }


  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]

  }


  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Notifications' link="/dashboard" />
        <Col xs={12}>
          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                data={notifications}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={notifications}
                    columns={columns}
                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                    bootstrap5
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>

                          <Col sm='12'>
                            <div className='float-end mb-3'>
                              <Button type='button' color='primary' onClick={() => togdata_standard()}><i className="bx bx-plus"></i>{" "}
                                Add New Notification</Button>

                            </div>

                          </Col>
                        </Row>

                        <div className="table-responsive">

                          <BootstrapTable
                            remote
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            responsive
                            bordered={false}
                            striped={true}
                            // defaultSorted={defaultSorted}
                            hover
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            onTableChange={handleTableChange}
                            noDataIndication={() => NoDataIndication()}
                          />

                        </div>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="pagination pagination-rounded mb-2">
                              <PaginationTotalStandalone
                                {...paginationProps}
                              />
                            </div>

                          </Col>
                          <Col sm={6}>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>

                          </Col>
                        </Row>


                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </div>
      {alert_confirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDeleteTemplate(selected_id)}
          onCancel={() => {
            setAlert_confirm(false)
            setSelected_id(null)
          }
          }
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {success_confirm ? (
        <SweetAlert
          success
          title={dynamic_title}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setSuccess_confirm(false)
            setAlert_confirm(false)
          }
          }
        >
          {dynamic_description}
        </SweetAlert>
      )
        : null
      }
      <Col lg={6}>

        <Modal
          isOpen={modal_standard}
          toggle={() => {
            togdata_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add Notification
            </h5>

            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

          </div>

          <div className="modal-body">

            <Col lg='12'>
              <AvForm className="needs-validation" onValidSubmit={handleCreateNotification}>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="message"> Notification</Label>
                      <AvField
                        name="message"
                        placeholder="Notification"
                        type="textarea"
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: 'This Field May not be blank' },
                        }}
                        id="message"

                      />
                    </div>
                  </Col>

                </Row>

                {submitting ?
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        togdata_standard()
                      }}
                    >
                      Close
                    </button>
                    <Button color="primary" type="submit">
                      Create
                    </Button>
                  </div>
                  :
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        togdata_standard()
                      }}
                    >
                      Close
                    </button>
                    <Button color="primary" type="submit">
                      Create
                    </Button>
                  </div>

                }

              </AvForm>
            </Col>
          </div>

        </Modal>
      </Col>

    </React.Fragment>
  );
}
export default Notifications
