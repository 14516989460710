import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import LockScreen from "../../pages/Authentication/LockScreen"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }else if(localStorage.getItem("lockScreen")=== true || localStorage.getItem("lockScreen")=== "true") {
        
        return (
            <LockScreen {...props} />
        )

      }else{
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
        
      }


      
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
