import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { get, put ,patch} from "../../helpers/api_helper";
import { toast } from 'react-toastify';
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"


const EditPage = (props) => {
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)
    const [submitting, setSubmitting] = useState(null)
    const [img, setImg] = useState(null)
    const [modal_standard, setmodal_standard] = useState(false)
    const [ischecked, setIsChecked] = useState(null)
    const [isfollow,setFollow]=useState(null)
    const [isindex,setIndex]=useState(null)
   

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        setDidMount(true);
         get(`${BASE_URL}/api/house_church/locationpage/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setUser(response.data)
                setIsChecked(response.data.is_active)
                setFollow(response.data.follow)
                console.log(response,'EditUserId')
                setIndex(response.data.index)
                console.log(response.data.page_title,'page_title')
             

            })
       

    }, [id]);

    if (!didMount) {
        return null;
    }
    
    const handleFileUpload = (e) => {
        try {
            let reader = new FileReader();
            let file = e.target.files[0];
            if (!file){
                return;
            }
            const fileSizeInKB=file.size/1024;
            if (fileSizeInKB>30){
                toast.error('Please select an image file smaller than 30 kB');
                setImg(null)
            }else{
                reader.onloadend = () => {
                    var previewImgUrl = reader.result
                    setImg(previewImgUrl)
                }
                reader.readAsDataURL(file);
            }
           
        } catch (error) {

        }
    }

    function handleSubmit(e, values) {
        setSubmitting(true)
        // values={is_active:ischecked,index:isindex,follow:isfollow,meta_title:metaTitle,meta_description:description, ...values}
        console.log(values,'values')
        patch(`${BASE_URL}/api/house_church/locationpage/${id}/`, {...values,is_active:ischecked,index:isindex,follow:isfollow,location:user.location.id},
            { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken()}, validateStatus: false }
        )
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Somthing Went Wrong ")
                    console.log(response)
                } else {
                    console.log(response,'id of user')
                    props.history.push("/pages")
                }
              
            
            })

    }
  
      
    function handleImageSubmit(e, values) {
       if (img){
        setSubmitting(true)
        put(`${BASE_URL}/api/house_church/locationpage/${id}/`, { ...values, image: img },
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false })
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Somethin Went Wrong")

                } else {
                    props.history.push('/pages')
                }
            })
       }else{
        toast.error('Please select an image file smaller than 30 kB');

       }
    }

    return (
        <React.Fragment>
            <div className="page-content" >

                <Breadcrumbs title="Pages" breadcrumbItem={user?user.meta_title:"update Page"} link="/pages" />
                {user ?
                    <Row>
                        <Col md="12">

                            <Row>
                                <Col sm="4">
                                    <Card>
                                        <CardBody>
                                            <CardTitle></CardTitle>
                                            <Row>
                                                <Col sm='12'>
                                                    <div className="text-end">
                                                        <Button color='link' onClick={() => tog_standard()}>
                                                            <i className="bx bx-edit-alt" style={{'fontSize':'30px'}}></i>

                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="text-center">
                                                        {/* <img
                                                            className="img-fluid"
                                                            alt=""
                                                            width="300"
                                                            // src={user.image?user.image:not_avail}
                                                            src={user.image ? user.image : not_avail}
                                                        /> */}

                                                    </div>

                                                </Col>


                                                {/* <input type="file" checked={file} onChange={(e) => setFile(e.target.files[0])} />
                                                 */}
                                                {/* 
                                             <input type='file'  onChange={handelchange}/>
                                             <img src={img}/> */}
                                                {/* <input type='file' onChange={handleFileUpload} /> */}
                                                <img src={img?img:user.image?user.image:null} alt="Selected" />

                                            </Row>

                                        </CardBody>
                                    </Card>
                                   

                                </Col>
                                <Col sm="8">
                                    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle>User Details </CardTitle>
                                                <Row>
                                                    <Col md={12}>
                                                        
                                                        <div className="mb-3">
                                                            <Label htmlFor="location">Location</Label>
                                                            <AvField 
                                                            name='location'
                                                            placeholder='Location'
                                                            disabled
                                                            type='text'
                                                            id='location'
                                                            value={user.location?user.location.name:null}/>
                                                        </div> 
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="page_title"> Page Title</Label>
                                                            <AvField
                                                            name='page_title'
                                                            placeholder='Page Title'
                                                            type='text'
                                                            id='page_title'
                                                            value={user.page_title}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor="meta_title">Meta Title</Label>
                                                            <AvField
                                                                name='meta_title'
                                                                placeholder='Meta Title'
                                                                type='text'
                                                                id='meta_title'
                                                                value={user.meta_title} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor='keywords'>Keywords</Label>
                                                            <AvField
                                                                name='keywords'
                                                                placeholder='Keyword'
                                                                type="textarea"
                                                                rows="3"
                                                                id='keywords'
                                                                value={user.keywords} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                    <div className="mb-3">
                                                            <Label htmlFor="meta_description">Meta Discription</Label>
                                                            <AvField
                                                                name="meta_description"
                                                                placeholder="Meta Description"
                                                                type="textarea"
                                                                rows="3"
                                                                className="form-control"
                                                                id="meta_description"
                                                                value={user.meta_description}
                                                            />
                                                        </div>
                                                    </Col>
                                                 
                                                </Row>

                                                <Row>
                                                <Col md={4}>
                                                    <div className="checkbox-wrapper mb-2">
                                                        <label>
                                                            <input
                                                            type='checkbox'
                                                            checked={ischecked}
                                                            onChange={(e)=>setIsChecked(e.target.checked)}
                                                            />{" "}
                                                            Active
                                                        </label>

                                                    </div>
                                                </Col>
                                                <Col>
                                                <div className='checkbox-wrapper mb-2'>
                                                    <label>
                                                        <input
                                                        type='checkbox'
                                                        checked={isfollow}
                                                        onChange={(e)=>setFollow(e.target.checked)}
                                                        />{" "}
                                                        Follow
                                                    </label>
                                                </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="checkbox-wrapper mb-2">
                                                        <label>
                                                            <input 
                                                            type='checkbox'
                                                            checked={isindex}
                                                            onChange={(e)=>setIndex(e.target.checked)}
                                                            
                                                            />{" "}
                                                            Index
                                                        </label>

                                                    </div>
                                                </Col>
                                                    

                                                </Row>

                                                {submitting ?
                                                    // <button
                                                    //     type="button"
                                                    //     className="btn btn-primary waves-effect waves-light my-3"
                                                    // >
                                                    //     <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                    //     Updating
                                                    // </button>
                                                    <Button color="primary" type="submit">
                                                    Update
                                                </Button>
                                                    :
                                                    <Button color="primary" type="submit">
                                                        Update
                                                    </Button>
                                                }
                                            </CardBody>
                                        </Card>
                                    </AvForm>
                                </Col>

                            </Row>


                        </Col>
                        <Row>
                            <Col sm={6} md={4} xl={3}>

                                <Modal
                                    isOpen={modal_standard}
                                    toggle={() => {
                                        tog_standard()
                                    }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0 " style={{ 'textAlign': 'center' }} id="myModalLabel">
                                            Image
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setmodal_standard(false)
                                            }}
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {/* <h5>Overflowing text to show scroll behavior</h5> */}

                                        <Col lg='12'>
                                            <AvForm className="needs-validation" onValidSubmit={handleImageSubmit}>

                                                <Row>
                                                    <Col md={12}>
                                                        <div className="text-center">


                                                        </div>

                                                    </Col>



                                                    {/* <img src={img} alt="Selected" /> */}

                                                    <input type='file' accept="image/png, image/jpeg" className="mb-3 mr-5" onChange={handleFileUpload} />


                                                </Row>


                                                <div className="modal-footer">
                                                    {submitting ?

                                                        // <button
                                                        //     type="button"
                                                        //     onClick={() => {
                                                        //         tog_standard()
                                                        //     }}
                                                        //     className="btn btn-primary waves-effect waves-light my-3"
                                                        // >
                                                        //     <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                        //     Updateimage
                                                        // </button>
                                                        <Button color="primary"  type="submit">
                                                        Update
                                                    </Button>
                                                        :
                                                        <Button color="primary"  type="submit">
                                                            Update
                                                        </Button>
                                                    }{" "}
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            tog_standard();
                                                            setImg(null)
                                                        }}
                                                        className="btn btn-primary waves-effect"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>

                                                </div>


                                            </AvForm>
                                        </Col>


                                    </div>

                                </Modal>
                            </Col>

                        </Row>
                    </Row>
                    :
                    <div id="preloader">
                        <div id="status">
                            <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                            </div>
                        </div>
                    </div>
                }

            </div>

        </React.Fragment>
        
    )
}

export default EditPage
