import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { toast } from 'react-toastify';
import not_avail from "../../assets/images/not_avail.jpg"
import Select from "react-select";
import classnames from "classnames"

import { Row, Col, Card, CardBody, CardTitle, Spinner, NavLink, NavItem, Nav, TabPane, TabContent, Button, Label, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { get, put, del } from "../../helpers/api_helper"
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import moment from "moment-timezone";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"
import SweetAlert from "react-bootstrap-sweetalert";
import EventReplies from "./EventReplies";

// import './House.css'

const optionGroup = [
    {
        label: "Search by",
        options: [
            { label: "Email", value: "user_email" },
            {label:'Type',value:'type'}


        ],
    },
];


const sortOptionGroup = [
    {
        label: "Order by",
        options: [
            { label: "Asc", value: "" },
            { label: "Desc", value: "-" },
        ],
    },
];

const PostOptionGroup = [
    {
        label: "Search by",
        options: [
            { label: 'Date', value: 'created' },


        ]
    }
]

const EditCustomer = (props) => {
    const state = props.location.statedata
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [event, setEvent] = useState(null)
    const [submitting, setSubmitting] = useState(null)
    const [modal_standard, setmodal_standard] = useState(false)
    const [search, setSearch] = useState(null)

    const [member, setMember] = useState([])

    // const [file, setFile] = useState(null)
    const [img, setImg] = useState(null)
    const [activeTab, setactiveTab] = useState("1")
    const [totalSize, settotalSize] = useState(0);
    const [page, setpage] = useState(1)
    const [sizePerPage, setsizePerPage] = useState(20)
    const [search_by, setSearchby] = useState({ label: "Search By", value: null });
    const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))
    // const [ids, setIds] = useState([])
    const [filterdata, setFilterData] = useState("")

    const [postPage, setPostPage] = useState(1)
    const [postTotalSize, setPostTotalSize] = useState(1);
    const [posts, setPosts] = useState([])
    const [selectedPost, setSelectedPost] = useState(null)
    const [modalPostDetial, setModalPostDetial] = useState(false)
    const [Postfilterdata, setPostFilterData] = useState("")

    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")


    const [modalPostReplies, setModalPostReplies] = useState(false)
    const [eventReplies, setEventReplies] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)


    ValidateUser(props)

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function tog_replies() {
        setModalPostReplies(!modalPostReplies)
        removeBodyCss()
    }

    function tog_postModel() {
        setModalPostDetial(!modalPostDetial)
        removeBodyCss()
    }


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function Membercolumns(event_detail){
        return [
            {
                dataField: "id",
                text: "S No.",
                hidden: true
    
            },
    
            {
                dataField: 'user',
                text: "User",
                headerFormatter: HeaderFormatter,
                sort: true,
                formatter: (cellContent, row) => (
                    <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { url: `/edit-event/${id}`, page: event_detail?`Edit ${event_detail.name}`:'Edit Event' } }} className="me-3 text-dark">{row.user ? row.user.first_name + " " + row.user.last_name : null}</Link>
    
                )
    
            },
            {
                dataField: "user_email",
                text: "User Email",
                headerFormatter: HeaderFormatter,
                Sort: true,
                formatter: (cellContent, row) => (
                    <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { url: `/edit-event/${id}`, page: event_detail?`Edit ${event_detail.name}`:'Edit Event' } }} className="me-3 text-dark">{cellContent}</Link>
                ),
            },
            {
                dataField: 'status',
                text: 'Status',
                headerFormatter: HeaderFormatter,
                sort: true,
                formatter: (cellContent, row) => (
                    <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { url: `/edit-event/${id}`, page:event_detail?`Edit ${event_detail.name}`:'Edit Event' } }} className="me-3 text-dark">{cellContent}</Link>
                ),
    
            },
            {
                dataField: "joined_by",
                text: "Joined By",
                headerFormatter: HeaderFormatter,
                sort: true,
                formatter: (cellContent, row) => (
                    <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { url: `/edit-event/${id}`, page: event_detail?`Edit ${event_detail.name}`:'Edit Event' } }} className="me-3 text-dark">{cellContent}</Link>
                ),
            },
            {
                dataField: "type",
                text: "Type",
                headerFormatter: HeaderFormatter,
                sort: true,
                formatter: (cellContent, row) => (
                    <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { url: `/edit-event/${id}`, page: event_detail?`Edit ${event_detail.name}`:'Edit Event' } }} className="me-3 text-dark">{cellContent}</Link>
                ),
            },
        ]
    } 

    function postColumn(event_detail){
        return [
        {
            dataField: "id",
            text: "S No.",
            hidden: true

        }, 
        {
            dataField: "user",
            text: "Name",
            isDummyField: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/user-detail/${row.user ? row.user.id : null}`, statedata: { 'url': `/edit-event/${id}`, 'page': event_detail?`Edit ${event_detail.name}`:"Edit Church" } }} className="me-3 text-dark">{row.user.first_name}{" "}{row.user.last_name}</Link>
            )
        },
        {
            dataField: "message",
            text: "Message",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <a href={`https://findahousechurch.com/post/event/${row?.uuid}`} rel="noreferrer" target="_blank" className="me-3 text-dark" >{cellContent.length >50?cellContent.substring(0,50)+" ...":cellContent}</a>
            )
        },
        {
            dataField: "likes",
            text: "Likes",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                cellContent
            )
        },
        {
            dataField: "replies",
            text: "Replies",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
               
               <Link to={'#'} onClick={()=> {tog_replies(); setEventReplies(row.replies); setSelectedEvent(row.uuid)}} className="me-3 text-dark">{cellContent.length}</Link>
            )
        },
        {
            dataField: 'created_at',
            text: 'Date',
            headerFormatter: HeaderFormatter,
            sort: true,
            formatter: (cellContent, row) => (
              <Link to='#' className="me-3 text-dark">{moment(new Date(row.created_at)).format("MM-DD-YYYY")}</Link>
            ),
      
          },
        {
            dataField: "type",
            text: "Type",
            isDummyField: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <React.Fragment>
                {/* <Link to='#' onClick={() => {setSelectedPost(row); tog_postModel()}} className="me-3 text-dark"><i className="mdi mdi-eye"></i></Link> */}
                <a href={`https://findahousechurch.com/post/event/${row?.uuid}`} rel="noreferrer" target="_blank" className="me-3 text-dark" >  <i className="mdi mdi-eye" style={{ 'fontSize': '20px' }}></i></a>
                <Link to='#' onClick={() => {setSelectedPost(row); setAlert_confirm(true)}} className="text-danger"><i className="mdi mdi-delete"></i></Link>
                </React.Fragment>
            )

        },
    ]
    }



    useEffect(() => {
        localStorage.setItem("ID_EditEvent", id);
        loadMemberData(`${BASE_URL}/api/house_church/event-members/?event_id=${id}`, 20, 1)
        loadPosts(`${BASE_URL}/api/house_church/dash-post/?event_id=${id}`,20, 1 )


        setDidMount(true);
        get(`${BASE_URL}/api/house_church/events/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
        .then(response => {
            if (response.data.id === undefined) {
                props.history.push('/house-churches')
            }
            setEvent(response.data)
        })


    }, [id, props.history]);

    if (!didMount) {
        return null;
    }

    //get data on the basis of house id
    function loadMemberData(url, sizePerPage, page) {
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setMember(response.data.results)
            settotalSize(response.data.count)
            setpage(page)
            setsizePerPage(sizePerPage)

        })
    }


        //get listing of house church posts on the basis of house id.
    function loadPosts(url, sizePerPage, page) {

        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setPosts(response.data.results)
            setPostTotalSize(response.data.count)
            setsizePerPage(sizePerPage)
            setPostPage(page)

        })

    }


    //when file is greate than than 30 kb the file is not support.this is used to convert base64 image
    const handleFileUpload = (e) => {
        try {
            let reader = new FileReader();
            let file = e.target.files[0];
            if (!file) {
                return;
            }
            const fileSizeInKB = file.size / 1024;
            if (fileSizeInKB > 30) {
                toast.error('Please select an image file smaller than 30 kB');
                setImg(null)
            } else {
                reader.onloadend = () => {
                    var previewImgUrl = reader.result
                    setImg(previewImgUrl)
                }
                reader.readAsDataURL(file);
            }

        } catch (error) {

        }
    }

    //update field of events on the basis of events id.
    function handleSubmit(e, values) {
        setSubmitting(true)
        put(`${BASE_URL}/api/house_church/events/${id}/`, { ...values, user_id: event.user_id.id },
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                if (response.status >= 400) {
                    toast.error("Somthing Went Wrong")
                } else {
                    props.history.push(`/edit-event/${id}`)

                }
            })

    }
    //update image on the basis the basis of events id.

    function handleImageSubmit(e, values) {
        if (img) {
            setSubmitting(true)
            put(`${BASE_URL}/api/house_church/events/${id}/`, { ...values, image: img },
                { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false })
                .then(response => {
                    if (response.status >= 400) {
                        toast.error("Image not found")

                    } else {
                        setSubmitting(false)
                        tog_standard()                       
                        console.log(response)
                    }
                })
        } else {
            toast.error('Please select an image file smaller than 30 kB');

        }
    }



    function ClearFilter() {
        setFilterData("")
        setSearch("")
        setSearchby({ label: "Search By", value: null });
        setFromDate(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
        setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))


        loadMemberData(`${BASE_URL}/api/house_church/event-members/?event_id=${id}`, 20, 1)
        

    }

    function ClearPostFilter() {
        setFilterData("")
        setSearch("")
        setSearchby({ label: "Search By", value: null });
        setFromDate(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
        setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))


        loadPosts(`${BASE_URL}/api/house_church/dash-post/?event_id=${id}`, 20, 1)
        

    }

    function FilterPostData(e, values) {
        let filter_data = ""
        if (!search_by.value) {
            toast.error("Select Search Field")
        } else {
            if (search_by.value === "created") {

                filter_data = `&start_date=${fromDate}&end_date=${toDate}`
            }
            // filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
            setPostFilterData(filter_data)
            setPosts([])
            // loadMemberData(`${BASE_URL}/api/house_church/event-members/?event_id=${id}${filter_data}`, 20, 1)
            loadPosts(`${BASE_URL}/api/house_church/dash-post/?event_id=${id}${filter_data}`, 20, 1)



        }
    }

    function FilterLoadMember(e, values) {
        let filter_data = ""
        if (!search_by.value) {
            toast.error('Select Search Field')
        } else {
            filter_data = `&${search_by.value}=${values.search}`
        }
        filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
        console.log(filter_data, 'loadmemeber')
        setFilterData(filter_data)
        loadMemberData(`${BASE_URL}/api/house_church/event-members/?event_id=${id}${filter_data}`, 20, 1)



    }

    //show table is empty when length of event member is null.
    function NoDataIndication() {
        if (loadMemberData && member.length === 0) {
            return (
                <div className="d-flex align-itme-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>

                </div>
            )
        } else {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color='dark' />

                </div>
            )
        }
    }

    function postsNoDataIndication() {
        if ( posts.length === 0) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )
        }
    }

    //change page
    function handleTableChange(type, { page, sizePerPage, searchText, }) {

        setTimeout(() => {
            let new_page = page
            let url = `${BASE_URL}/api/house_church/event-members/?event_id=${id}&page_size=${sizePerPage}&page=${new_page}`
            if (filterdata !== '') {
                url = url + `${filterdata}`
            }
            console.log(url, "handleTableChange")
            window.scrollTo(0, 0)
            loadMemberData(url, sizePerPage, new_page, searchText)


        }, 1000);
    
    }

    function handlePostsTableChange(type, { page, sizePerPage, searchText, }) {
        setTimeout(() => {
            setPosts([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/dash-post/?event_id=${id}&page_size=${sizePerPage}&page=${new_page}`
            if (Postfilterdata !== '') {
                url = url + `&${Postfilterdata}`
            }
            window.scrollTo(0, 0)
            loadPosts(url, sizePerPage, new_page)
        }, 100);

    }

    function handleDeletePost(post_id){

        del(`${BASE_URL}/api/house_church/dash-post/${post_id}/`,
            { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() } },
            { validateStatus: false }
        )
            .then(res => {
            if (res.status >= 400) {
                
            }
            else {
                
                let new_page = postPage
                if (posts.length < 2) {
                    new_page = new_page > 1 ? new_page - 1 : 1
                }
                let url = `${BASE_URL}/api/house_church/dash-post/?event_id=${id}&page_size=${sizePerPage}&page=${new_page}`
                if (Postfilterdata !== "") {
                    url = `${url}${Postfilterdata}`
                }
                loadPosts(url, sizePerPage, new_page)
                setSuccess_confirm(true)
                setAlert_confirm(false)
                setDynamic_title('Deleted!')
                setDynamic_description("Post Has been Deleted.")
                setSelectedPost(null) 

            }
            }
            )
    }


    const defaultSorted = [{
        dataField: 'user_email',
        order: 'desc',
        savestate: true
    }];


    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }
    const PostsPageOptions = {
        page: postPage,
        sizePerPage: sizePerPage,
        totalSize: postTotalSize,
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title={event?`Edit ${event.house_name}`: 'Edit House Church'} breadcrumbItem={event ? event.name : 'Edit Event'} link={event? `/edit-house-church/${event.house_id}`:'/house-churches'} />

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeTab === "1",
                            })}
                            onClick={() => {
                                toggle("1")
                            }}
                        >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">House Event</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeTab === "2",
                            })}
                            onClick={() => {
                                toggle("2")
                            }}
                        >
                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                            <span className="d-none d-sm-block">Event Member</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeTab === "3",
                            })}
                            onClick={() => {
                                toggle("3")
                            }}
                        >
                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                            <span className="d-none d-sm-block">Event Posts</span>
                        </NavLink>
                    </NavItem>


                </Nav>

                <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">

                        {event ?
                            <Row>
                                <Col md="12">

                                    <Row>
                                        <Col sm="4">
                                            <Card>
                                                <CardBody>
                                                    <CardTitle></CardTitle>
                                                    <Row>
                                                        <Col sm="12">

                                                            <div className="text-end">
                                                                <Button color="link" type="button" onClick={() => tog_standard()}>
                                                                    <i className="bx bx-edit-alt" style={{ 'fontSize': '30px' }}></i>
                                                                </Button>
                                                            </div>

                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="text-center">
                                                                {/* <img
                                                                    className="img-fluid"
                                                                    disabled
                                                                    alt=""
                                                                    width="300"
                                                                    // src={user.image?user.image:not_avail}
                                                                    src={user.image ? user.image : not_avail}
                                                                /> */}
                                                                {/* <img src={img} alt="Selected" /> */}

                                                            </div>

                                                        </Col>
                                                        <img src={img?img:event.image?event.image:not_avail} alt="Selected" />

                                                    </Row>

                                                </CardBody>
                                            </Card>

                                        </Col>

                                        <Col sm="8">
                                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle>Event Detail </CardTitle>
                                                        <Row>

                                                            <Col md='6'>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="user_id">User</Label>
                                                                    <AvField
                                                                        name="user_id"
                                                                        placeholder="User"
                                                                        type="text"
                                                                        errorMessage="Please provide a valid User."
                                                                        className="form-control"
                                                                        disabled

                                                                        id="user_id"
                                                                        value={event.user_id ? event.user_id.first_name : null}
                                                                    />
                                                                </div>

                                                            </Col>
                                                            <Col md='6'>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="name">Name</Label>
                                                                    <AvField
                                                                        name="name"
                                                                        placeholder="Name"
                                                                        type="text"
                                                                        className="form-control"
                                                                        disabled

                                                                        id="name"
                                                                        value={event.name}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md="6">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="address">Address</Label>
                                                                    <AvField
                                                                        name="address"
                                                                        placeholder="Address"
                                                                        type="text"
                                                                        errorMessage=" Please Enter Address."
                                                                        className="form-control"
                                                                        disabled
                                                                        id="address"
                                                                        value={event.address}
                                                                    />
                                                                </div>

                                                            </Col>
                                                            <Col md="6">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="city">City</Label>
                                                                    <AvField
                                                                        name="city"
                                                                        placeholder="City"
                                                                        type="text"
                                                                        errorMessage=" Please Enter city."
                                                                        className="form-control"
                                                                        disabled
                                                                        id="city"
                                                                        value={event.city}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md="6">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="state">State</Label>
                                                                    <AvField
                                                                        name="state"
                                                                        placeholder="State"
                                                                        type="text"
                                                                        errorMessage=" Please Enter State."
                                                                        className="form-control"
                                                                        disabled
                                                                        id="state"
                                                                        value={event.state}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md="6">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="zipcode">ZipCode</Label>
                                                                    <AvField
                                                                        name="zipcode"
                                                                        placeholder="ZipCode"
                                                                        type="text"
                                                                        errorMessage=" Please provide a valid ZipCode."
                                                                        className="form-control"
                                                                        disabled
                                                                        id="zipcode"
                                                                        value={event.zipcode}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md='6'>
                                                                <div className='mb-3'>
                                                                    <Label htmlFor="time">Time</Label>
                                                                    <AvField
                                                                        name='time'
                                                                        placeholder='Time'
                                                                        type='time'
                                                                        className='form-control'
                                                                        disabled
                                                                        id='desc'
                                                                        value={event.time} />
                                                                </div>

                                                            </Col>
                                                            <Col md='6'>
                                                                <div className='mb-3'>
                                                                    <Label htmlFor="day">Day</Label>
                                                                    <AvField
                                                                        name='day'
                                                                        placeholder='Day'
                                                                        type='text'
                                                                        className='form-control'
                                                                        disabled
                                                                        id='day'
                                                                        value={event.day} />

                                                                </div>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col md='6'>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="user_id">Event Type</Label>
                                                                    <AvField
                                                                        name="event_type"
                                                                        placeholder="Event Type"
                                                                        type="text"
                                                                        errorMessage="Please provide a valid Event Type."
                                                                        className="form-control"
                                                                        disabled

                                                                        id="event_type"
                                                                        value={event.event_type ? event.event_type : null}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md='12'>
                                                                <div className='mb-3'>
                                                                    <Label htmlFor="desc">Description</Label>
                                                                    <AvField
                                                                        name='desc'
                                                                        placeholder='Desc'
                                                                        type='textarea'
                                                                        className='form-control'
                                                                        disabled
                                                                        id='desc'
                                                                        value={event.desc} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        
                                                    </CardBody>
                                                </Card>
                                            </AvForm>
                                        </Col>

                                    </Row>


                                </Col>
                                
                            </Row>

                            :
                            <div id="preloader">
                                <div id="status">
                                    <div className="spinner-chase">
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                    </div>
                                </div>
                            </div>
                        }

                    </TabPane>
                    <TabPane tabId="2">
                        <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            data={member}
                        >
                            {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                    keyField="id"
                                    data={member}
                                    columns={Membercolumns(event)}
                                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                    bootstrap5
                                    search
                                >
                                    {toolkitProps => (
                                        <React.Fragment>
                                            <AvForm className="needs-validation" onValidSubmit={FilterLoadMember}>

                                                <Row className="mb-2">


                                                    <Col sm="3">
                                                        <div className="text-sm-start mb-2">
                                                            <Select
                                                                value={search_by}
                                                                onChange={(e) => {
                                                                    setSearchby(e)
                                                                }}
                                                                options={optionGroup}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                {search_by.value ?

                                                    <Row>
                                                        <Col sm="5">
                                                            {search_by.value === "created" ?
                                                                <Row>
                                                                    <Col sm="6">
                                                                        <div className="text-sm-end mb-2">
                                                                            <AvField
                                                                                name="from_date"
                                                                                placeholder="Received From"
                                                                                type="date"
                                                                                errorMessage="From Date"
                                                                                className="form-control"
                                                                                value={fromDate}
                                                                                onChange={(e) => setFromDate(e.target.value)}
                                                                                id="from_date"
                                                                            />
                                                                        </div>

                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <div className="text-sm-end mb-2">
                                                                            <AvField
                                                                                name="to_date"
                                                                                placeholder="Category"
                                                                                type="date"
                                                                                errorMessage="To Date"
                                                                                className="form-control"
                                                                                value={toDate}
                                                                                onChange={(e) => setToDate(e.target.value)}
                                                                                id="to_date"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                : search_by.value !== null ?
                                                                    <Row>
                                                                        <Col sm="12">
                                                                            <div className="text-sm-end mb-2">
                                                                                <AvField
                                                                                    name="search"
                                                                                    placeholder={search_by.label}
                                                                                    type="text"
                                                                                    value={search}
                                                                                    onChange={(e) => setSearch(e.target.value)}
                                                                                    className="form-control"
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </div>

                                                                        </Col>

                                                                    </Row>

                                                                    : null}

                                                        </Col>
                                                        <Col sm="3">
                                                            <div className="text-sm-start mb-2">
                                                                <Select
                                                                    value={order_by}
                                                                    onChange={(e) => {
                                                                        setOrder_by(e)
                                                                    }}
                                                                    options={sortOptionGroup}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm="4">
                                                            <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                Filter
                                                            </Button>
                                                            {" "}
                                                            <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                                                Clear
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                    : null}

                                            </AvForm>

                                            <Row>
                                                <Col ms='10'>

                                                </Col>


                                            </Row>
                                            <div className="table-responsive">

                                                <BootstrapTable
                                                    remote
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                    responsive
                                                    bordered={false}
                                                    striped={true}
                                                    defaultSorted={defaultSorted}
                                                    hover
                                                    classes={
                                                        "table align-middle table-nowrap table-check"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                    onTableChange={handleTableChange}
                                                    noDataIndication={() => NoDataIndication()}
                                                    // selectRow={selectRow}
                                                />

                                            </div>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="mb-3">
                                                        <SizePerPageDropdownStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>
                                                    <div className="pagination pagination-rounded mb-2">
                                                        <PaginationTotalStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>

                                                </Col>
                                                <Col sm={6}>
                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>

                                                </Col>
                                            </Row>


                                        </React.Fragment>
                                    )}
                                </ToolkitProvider>
                            )}
                        </PaginationProvider>


                    </TabPane>
                    <TabPane tabId="3">
                    <PaginationProvider
                                            pagination={paginationFactory(PostsPageOptions)}
                                            data={posts}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={posts}
                                                    columns={postColumn(event)}
                                                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                    bootstrap5
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <AvForm className="needs-validation" onValidSubmit={FilterPostData}>
                                                                <Row className="mb-2">

                                                                    <Col sm="3">
                                                                        <div className="text-sm-start mb-2">
                                                                            <Select
                                                                                value={search_by}
                                                                                onChange={(e) => {
                                                                                    setSearchby(e)
                                                                                }}
                                                                                options={PostOptionGroup}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>


                                                                    </Col>
                                                                    {search_by.value ?
                                                                        <Col sm="9">
                                                                            <Row>
                                                                        
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-end mb-2">
                                                                                    <AvField
                                                                                        name="from_date"
                                                                                        placeholder="Received From"
                                                                                        type="date"
                                                                                        errorMessage="From Date"
                                                                                        className="form-control"
                                                                                        value={fromDate}
                                                                                        onChange={(e) => setFromDate(e.target.value)}
                                                                                        id="from_date"
                                                                                    />
                                                                                    </div>
                                    
                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-end mb-2">
                                                                                    <AvField
                                                                                        name="to_date"
                                                                                        placeholder="Category"
                                                                                        type="date"
                                                                                        errorMessage="To Date"
                                                                                        className="form-control"
                                                                                        value={toDate}
                                                                                        onChange={(e) => setToDate(e.target.value)}
                                                                                        id="to_date"
                                                                                    />
                                                                                    </div>
                                                                                </Col>
                                                                                    
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-start mb-2">
                                                                                        <Select
                                                                                            value={order_by}
                                                                                            onChange={(e) => {
                                                                                                setOrder_by(e)
                                                                                            }}
                                                                                            options={sortOptionGroup}
                                                                                            classNamePrefix="select2-selection"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                        Filter
                                                                                    </Button>
                                                                                    {" "}
                                                                                    <Button type="button" color="primary" onClick={() => ClearPostFilter()} className="btn-rounded" >
                                                                                        Clear
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        : null}
                                                                </Row >
                                                            </AvForm>

                                                            <div className="table-responsive">

                                                                <BootstrapTable
                                                                    remote
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    hover
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                    onTableChange={handlePostsTableChange}
                                                                    noDataIndication={() => postsNoDataIndication()}
                                                                // selectRow={selectRow}
                                                                />

                                                            </div>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <div className="mb-3">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="pagination pagination-rounded mb-2">
                                                                        <PaginationTotalStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                            </Row>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>


                    </TabPane>





                </TabContent>


            </div>
            <Col sm={6} md={4} xl={3}>

                <Modal  
                    isOpen={modal_standard}
                    toggle={() => {
                        tog_standard()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0 " style={{ 'textAlign': 'center' }} id="myModalLabel">
                            Image
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_standard(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* <h5>Overflowing text to show scroll behavior</h5> */}

                        <Col lg='12'>
                            <AvForm className="needs-validation" onValidSubmit={handleImageSubmit}>

                                <Row>
                                    <Col md={12}>
                                        <div class="text-center">


                                        </div>

                                    </Col>
                                    
                                    {/* <img src={img} alt="Selected" /> */}

                                    <input type='file' accept="image/png, image/jpeg" className="mr-5 mb-3 text-center" onChange={handleFileUpload} />


                                </Row>


                                <div className="modal-footer">
                                    {submitting ?

                                        // <button
                                        //     type="button"
                                        //     onClick={() => {
                                        //         tog_standard()
                                        //     }}
                                        //     className="btn btn-primary waves-effect waves-light my-3"
                                        // >
                                        //     <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                        //     Updateimage
                                        // </button>
                                        <Button color="primary" type="submit">
                                            Update
                                        </Button>
                                        :
                                        <Button color="primary" type="submit">
                                            Update
                                        </Button>
                                    }
                                    <button
                                        type="button"
                                        onClick={() => {
                                            tog_standard();
                                            setImg(null)
                                        }}
                                        className="btn btn-primary waves-effect"

                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>

                                </div>


                            </AvForm>
                        </Col>
                    </div>

                </Modal>
                </Col>

            <Col sm={6} md={4} xl={3}>
                <Modal
                isOpen={modalPostDetial}
                toggle={() => {
                    tog_postModel()
                }}
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">{`Post Detail`} </h5>

                    <button
                    type="button"
                    onClick={() => {
                        tog_postModel()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {/* <h5>Overflowing text to show scroll behavior</h5> */}

                    <Col lg='12'>
                            <Row>
                                <Col md={12}>
                                    
                                        <p><b>Send By: </b>{selectedPost?.user?.first_name} {selectedPost?.user?.last_name}</p>
                                        <p><b>Message: </b>{selectedPost?.message}</p>
                                        <p><b>Date: </b> {moment(selectedPost?.created).tz("America/Chicago").format("YYYY-MM-DD")}</p>
                                    

                                </Col>
                            </Row>
                    </Col>


                </div>


                </Modal>
            </Col>

            {/* azfdsdgd */}
            <Col sm={6} md={4} xl={3}>
                <Modal
                size="lg"
                isOpen={modalPostReplies}
                toggle={() => {
                    tog_replies()
                }}
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">{`Replies`} </h5>

                    <button
                    type="button"
                    onClick={() => {
                        tog_replies()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {/* <h5>Overflowing text to show scroll behavior</h5> */}

                   <EventReplies eventReplies={eventReplies} uuid={selectedEvent}/>


                </div>


                </Modal>
            </Col>
            {/* end sdfsdf */}
            {alert_confirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleDeletePost(selectedPost.id)}
            onCancel={() => {
              setAlert_confirm(false)
              setSelectedPost(null)
            }
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {success_confirm ? (
          <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setSuccess_confirm(false)
              setSelectedPost(false)

            }
            }
          >
            {dynamic_description}
          </SweetAlert>
        )
          : null
        }

        </React.Fragment>
    )
}

export default EditCustomer
