import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { BASE_URL } from "../../helpers/url_helper";
import { get, post, } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { EditorState, convertToRaw, left, right, center, justify, undo, redo, } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken";
import { ValidateUser } from "../../helpers/validate_user";



const CustomEmails = (props) => {
    ValidateUser(props)

    const [didMount, setDidMount] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rawMessage, setrawMessage] = useState('');
    const [submitting, setSubmitting] = useState(null);


    useEffect(() => {
        setDidMount(true);


    }, []);

    if (!didMount) {
        return null;
    }


    function onEditorStateChange(editorState) {
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };



    function cleanHtml(html) {
        // Create a temporary DOM element to parse the HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        // Remove all span elements
        const spans = tempDiv.getElementsByTagName('span');
        for (let i = spans.length - 1; i >= 0; i--) {
            const span = spans[i];
            // Replace each span with its inner HTML
            while (span.firstChild) {
                span.parentNode.insertBefore(span.firstChild, span);
            }
            span.parentNode.removeChild(span);
        }

        // Return the cleaned HTML
        return tempDiv.innerHTML;
    };


    function handleSubmit(e, values) {
        let data = {}
        let email = (values.email).split(",")
        data['content'] = cleanHtml(rawMessage)
        data['emails'] = email
        data['subject'] = values.subject
        setSubmitting(true)
        post(`${BASE_URL}/api/house_church/email/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                if (response.status >= 400) {
                    console.log(response)
                    toast.error("Somthing went Wrong")
                    setSubmitting(false)

                } else {
                    
                    setSubmitting(false)
                    toast.success("Email Send Successfully")
                }
            })
        // console.log(cleanHtml(rawMessage), "-----------------------")

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title={'Dashboard'} breadcrumbItem={`Custom Email`} link={"/dashboard"} />
                <Row>
                    <Col md="12">
                        <AvForm className="needs-validation" onValidSubmit={handleSubmit}>

                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                        <Row>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                        <Label htmlFor="subject">Subject</Label>
                                                        <AvField
                                                            name="subject"
                                                            placeholder="Subject"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{
                                                                required: { value: true, errorMessage: 'This Field May not be blank' },
                                                            }}
                                                            id="subject"

                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                        <Label htmlFor="email">Emails</Label>
                                                        <AvField
                                                            name="email"
                                                            placeholder="User Emails (Ex: myemial@xyz.com, user@xyz.com)"
                                                            type="text"
                                                            className="form-control"
                                                            validate={{
                                                                required: { value: true, errorMessage: 'This Field May not be blank' },
                                                            }}
                                                            id="email"

                                                        />
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md="12" className="mb-3">
                                                    <Editor
                                                        toolbar={{

                                                            options: ['inline', 'blockType', 'textAlign', 'history'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline'],
                                                            },
                                                            blockType: {
                                                                inDropdown: true,
                                                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                            },
                                                            textAlign: {
                                                                inDropdown: false,
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                                options: ['left', 'center', 'right', 'justify'],
                                                                left: { icon: left, className: undefined },
                                                                center: { icon: center, className: undefined },
                                                                right: { icon: right, className: undefined },
                                                                justify: { icon: justify, className: undefined },
                                                            },
                                                            history: {
                                                                inDropdown: false,
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                                options: ['undo', 'redo'],
                                                                undo: { icon: undo, className: undefined },
                                                                redo: { icon: redo, className: undefined },
                                                            },


                                                        }}
                                                        editorStyle={{ height: '450px', border: 'solid 2px', padding: '10px' }}
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={onEditorStateChange}
                                                    />
                                                </Col>
                                            </Row>


                                            {submitting ?
                                                <button
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light my-3"
                                                >
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                    Submit
                                                </button>
                                                :
                                                <Button color="primary my-3" type="submit">
                                                    Submit
                                                </Button>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>
                    </Col>
                </Row>
            </div>

        </React.Fragment>
    )
}

export default CustomEmails