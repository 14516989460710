import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { get, put } from "../../helpers/api_helper";
import { toast } from 'react-toastify';
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"


const UpdateDenomination = (props) => {

    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)
    const [submitting, setSubmitting] = useState(null)


    useEffect(() => {
        setDidMount(true);
        get(`${BASE_URL}/api/house_church/denominations/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setUser(response.data)
            })
        return () => setDidMount(false);

    }, [id]);

    if (!didMount) {
        return null;
    }

    function handleSubmit(e, values) {
        setSubmitting(true)
        let data = values
        console.log(data, "0---------------data")


        put(`${BASE_URL}/api/house_church/denominations/${id}/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                console.log(response, "0---------------data")
                if (response.status >= 400) {
                    toast.error("Somthing Went Wrong")
                } else {
                    props.history.push("/denominations")
                }
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Denominations" breadcrumbItem={`${user ? user.name : 'Denomination'} Details`} link="/denominations" />
                {user ?
                    <Row>

                        <Col lg="6">
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Denomination Details </CardTitle>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label htmlFor="name"> Name</Label>
                                                    <AvField
                                                        name="name"
                                                        placeholder=" Name"
                                                        type="text"
                                                        className="form-control"

                                                        id="name"
                                                        value={user.name}

                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="page_title">Page Title</Label>
                                                    <AvField
                                                        name="page_title"
                                                        placeholder="Page Title"
                                                        type="text"
                                                        errorMessage=" Please provide a valid Detail."
                                                        className="form-control"

                                                        id="page_title"
                                                        value={user.page_title}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="page_description">Page Description</Label>
                                                    <AvField
                                                        name="page_description"
                                                        placeholder="Page Description"
                                                        type="textarea"
                                                        errorMessage=" Please provide a valid Info."
                                                        className="form-control"

                                                        id="page_description"
                                                        value={user.page_description}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="meta_title">Meta Title</Label>
                                                    <AvField
                                                        name="meta_title"
                                                        placeholder="Meta Title"
                                                        type="text"
                                                        errorMessage=" Please provide a valid Detail."
                                                        className="form-control"

                                                        id="meta_title"
                                                        value={user.meta_title}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="meta_description">Meta Description</Label>
                                                    <AvField
                                                        name="meta_description"
                                                        placeholder="Meta Description"
                                                        type="textarea"
                                                        errorMessage=" Please provide a valid Info."
                                                        className="form-control"

                                                        id="meta_description"
                                                        value={user.meta_description}
                                                    />
                                                </div>
                                            </Col>

                                        </Row>
                                        {/* <Row>

                                            <Col md={6}>

                                                <div className="checkbox-wrapper">
                                                    <label>
                                                        <input
                                                            id="statuss"
                                                            name="status"
                                                            type="checkbox"
                                                            defaultChecked={user.status}

                                                        />{"   "}
                                                        Active
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row> */}
                                        {submitting ?
                                            <button
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light my-3"
                                            >
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                Updating
                                            </button>
                                            :
                                            <Button color="primary" type="submit">
                                                Update
                                            </Button>
                                        }

                                    </CardBody>
                                </Card>
                            </AvForm>
                        </Col>

                    </Row>
                    :
                    <div id="preloader">
                        <div id="status">
                            <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                            </div>
                        </div>
                    </div>
                }

            </div>

        </React.Fragment>
    )
}

export default UpdateDenomination
