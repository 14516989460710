import React, { useEffect, useState } from "react"
import Breadcrumbs from '../../components/Common/Breadcrumb'


import { Row, Col, Card, CardBody, Spinner, NavLink, NavItem, Nav, TabPane, TabContent, Button, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
import classnames from "classnames"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get, post, patch } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Name", value: "name" },
      { label: "Adress", value: "address" },

    ],
  },
];

const EnableGroup = [
  {
    label: 'Enable by',
    options: [
      { label: "Move To Inactive", value: 'disable' },
      { label: "Move To Archive", value: 'archive' }
    ]
  }
];


const DisableGroup = [
  {
    label: 'Disable by',
    options: [

      { label: "Move To Active", value: 'enable' },
      { label: "Move To Archive", value: 'archive' }
    ]
  }
];
const ArchiveGroup = [
  {
    label: 'Archive by',
    options: [
      { label: "Move To Active", value: 'enable' },
      { label: "Move To Inactive", value: 'disable' }
    ]
  }
];

const sortOptionGroup = [
  {
    label: "Order by",
    options: [
      { label: "Asc", value: "" },
      { label: "Desc", value: "-" },
    ],
  },
];
const AllChurch = (props) => {
  const [activeTab, setactiveTab] = useState("2")
  const [church, setChurch] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [loadChurch, setLoadChurch] = useState(false)

  const [archiveIds, setArchiveIds] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [archivePage, setArchivePage] = useState(1)
  const [archiveTotalSize, setArchiveTotalSize] = useState(20)

  const [DisabledUsers, setDisabledUsers] = useState([]);
  const [DisabledTotalSize, setDisabledtotalSize] = useState(0);
  const [DisabledPage, setDisabledpage] = useState(1)

  const [loaddata, setLoadData] = useState(false)
  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")

  const [ids, setIds] = useState([])
  const [DisabledIds, setDisabledIds] = useState([])

  const [modal_standard, setmodal_standard] = useState(false)

  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [search_by, setSearchby] = useState({ label: "Search By", value: null });
 
  const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });

  const [selectedGroup, setSelectGroup] = useState({ label: "Perform Bulk Action", value: null })
  const [selectedEnableGroup, setSelectEnableGroup] = useState({ label: "Perform Bulk Action", value: null })

  const [selectArchiveGroup, setSelectArchiveGroup] = useState({ label: "Perform Bulk Action", value: null })
  const [filterdata,setFilterData]=useState("")


  ValidateUser(props)


  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (

        <Link to={{ pathname: `/edit-church/${row?row.id:null}`, statedata: { 'activeTab': activeTab, 'page': { "2": page, '3': DisabledPage } } }} className="me-3 text-dark">{cellContent}</Link>
      ),
    },
    {
      dataField: "address",
      text: "Address",
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={{ pathname: `/edit-church/${row.id}`, statedata: { 'activeTab': activeTab, 'page': { "2": page, '3': DisabledPage } } }} className="me-3 text-dark">{cellContent}</Link>
      ),
    },


    {
      dataField: "action",
      isDummyField: true,
      headerFormatter: HeaderFormatter,
      text: "Action",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={{ pathname: `/edit-church/${row.id}`, statedata: { 'activeTab': activeTab, 'page': { "2": page, '3': DisabledPage } } }} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i>{cellContent}</Link>
        
        </React.Fragment>
      ),
    },
  ]




  useEffect(() => {
  
    loadData(`${BASE_URL}/api/house_church/dash_list_churches/?status=true&archive=false&approved=true`, 20, 1)
    loadDisabledData(`${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=false&approved=true`, 20, 1)
    loadArchiveData(`${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=true&approved=true`, 20, 1)

    return () => {
      setChurch([])
    }

  }, []);



  function loadData(url, sizePerPage, page) {
    setLoadChurch(false)
    let data = get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken()  } })
    data.then(response => {
      console.log(response.data.count,'not appoved data')
      setChurch(response.data.results)
      console.log(data.length,'approved data')
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setLoadChurch(true)
    })
  }


  function loadDisabledData(url, sizePerPage, page) {
    setLoadData(false)
    let data = get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken()  } })
    data.then(response => {
      setDisabledUsers(response.data.results)
      setDisabledtotalSize(response.data.count)
      setDisabledpage(page)
      setsizePerPage(sizePerPage)
      setLoadData(true)
 
    })
  }

  function loadArchiveData(url, sizePerPage, page) {
    setLoadData(false)
    let data = get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken()  } })
    data.then((response) => {
      setArchiveData(response.data.results)
      setArchiveTotalSize(response.data.count)
      setsizePerPage(sizePerPage)
      setArchivePage(page)
      setLoadData(true)
    })

  }


  function ClearFilter() {
    setFilterData("")
    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null });

    loadData(`${BASE_URL}/api/house_church/dash_list_churches/?status=true&archive=false&approved=true`, 20, 1)
    loadDisabledData(`${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=false&approved=true`, 20, 1)
    loadArchiveData(`${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=true&approved=true`, 20, 1)

  }


  function FilterData(e, values) {
    let filter_data = ""
    if (!search_by.value) {
      toast.error("Select Search Field")
    } else {
      
      filter_data = `&${search_by.value}=${values.search}`
   
      filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
      console.log(filter_data,'filterdata')

      loadData(`${BASE_URL}/api/house_church/dash_list_churches/?status=true&archive=false&approved=true${filter_data}`, 20, 1)
      loadDisabledData(`${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=false&approved=true${filter_data}`, 20, 1)
      loadArchiveData(`${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=true&approved=true${filter_data}`, 20, 1)
      setFilter(filter_data)
      setFilterData(filter_data)

    }
  }




  function NoDataIndication() {
    if (loadChurch && church.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }

  function ArchiveDataIndication() {
    if (loaddata && archiveData.length === 0) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <h3 className="my-3">Table is Empty</h3>
        </div>
      )
    } else {
      <div className="d-flex align-items-center justify-content-center">
        <Spinner className="my-3" color='dark' />
      </div>
    }
  }

  function DisabledNoDataIndication() {
    if (loaddata && DisabledUsers.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }

  function handleCustomerClicks(id) {
    let data = { "Disabled": true }

    patch(`${BASE_URL}/api/tgcl/registerusers/${id}/`, data,
      { headers: { 'Content-Type': 'application/json', } },
      { validateStatus: false }
    )
      .then(res => {
        if (res.status >= 400) {
        }
        else {
          if (activeTab === '2') {
            let new_page = page
            if (church.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/tgcl/registerusers/?approved=true&Disabled=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadData(url, sizePerPage, new_page)

          } else {
            let new_page = DisabledPage
            if (DisabledUsers.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/tgcl/registerusers/?Disabled=true&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadDisabledData(url, sizePerPage, new_page)
          }
          setSuccess_confirm(true)
          setAlert_confirm(false)
          setDynamic_title('Disabledd!')
          setDynamic_description("User Has been Disabledd.")
          setSelected_id(null)
        }
      }
      )
  }

  function handleTableChange(type, { page, sizePerPage, searchText, }) {

    setTimeout(() => {
      setChurch([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/dash_list_churches/?status=true&archive=false&approved=true&page_size=${sizePerPage}&page=${new_page}`
      if (filterdata!==''){
        url=url+ `&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadData(url, sizePerPage, new_page, searchText)


    }, 1000);
  }

  function handleArchiveTableChange(type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setArchiveData([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=true&approved=true&page_size=${sizePerPage}&page=${new_page}`
      if (filterdata !==''){
        url=url+ `&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadArchiveData(url, sizePerPage, new_page, searchText)

    }, 1000);
  }

  function handleDisableTableChange(type, { page, sizePerPage, searchText, }) {

    setTimeout(() => {
      setDisabledUsers([])
      let new_page = page
      let url = `${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=false&approved=true&page_size=${sizePerPage}&page=${new_page}`
      if (filterdata !==''){
        url =url+ `&${filterdata}`
      }
      window.scrollTo(0, 0)
      loadDisabledData(url, sizePerPage, new_page, searchText)
    }, 1000);
  }


  function HandleBulkOpertation(value) {
    let data = {}
    if (activeTab === "2") {
      data["action"] = value
      data["churches"] = ids
    } else if (activeTab === "3") {
      data["action"] = value
      data["churches"] = DisabledIds
    } else if (activeTab === '4') {
      data['action'] = value
      data['churches'] = archiveIds
    }

    post(`${BASE_URL}/api/house_church/churches-bulk-action/`, data,
      { headers: { 'Content-Type': 'application/json', } },
      { validateStatus: false }
    )
      .then(res => {
        if (res.status >= 400) {
          toast.error("Somthing Went Wrong")
        }
        else {
          if (activeTab === '2') {

            let new_page = page
            if (church.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/house_church/dash_list_churches/?status=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadData(url, sizePerPage, new_page)
            setIds([])
            setChurch([])


          } else if (activeTab === '3') {

            let new_page = DisabledPage
            if (DisabledUsers.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadDisabledData(url, sizePerPage, new_page)
            setDisabledIds([])
            setDisabledUsers([])


          } else if (activeTab === '4') {
            let new_page = archivePage
            if (archiveData.length < 2) {
              new_page = new_page > 1 ? new_page - 1 : 1
            }
            let url = `${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=true&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
              url = `${url}${filter}`
            }
            loadArchiveData(url, sizePerPage, new_page)
            setArchiveIds([])
            setArchiveData([])
          }
          if (value === "disable") {
            loadDisabledData(`${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=false${filter}`, 20, 1)
          } else if (value === "enable") {
            loadData(`${BASE_URL}/api/house_church/dash_list_churches/?status=true&archive=false${filter}`, 20, 1)
          } else if (value === "archive") {
            loadArchiveData(`${BASE_URL}/api/house_church/dash_list_churches/?status=false&archive=true${filter}`, 20, 1)
          }


        }
      }
      )

  }

  function handleOnSelect(row, isSelect) {
    let id = []
    if (isSelect) {
      id = ids
      id.push(row.id)
    } else {
      for (let i = 0; i < ids.length; i++) {
        if (ids[i] !== row.id) {
          id.push(ids[i])
        }
      }

    }
    setIds(id)
  }

  function handleOnSelectAll(isSelect, rows) {
    console.log(isSelect,'churchSelect')
    if (isSelect) {
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setIds(id)
    } else {
      setIds([])
    }
  }

  function handleOnSelectDisabledRow(row, isSelect) {
    let id = []
    if (isSelect) {
      id = DisabledIds
      id.push(row.id)
    } else {
      for (let i = 0; i < DisabledIds.length; i++) {
        if (DisabledIds[i] !== row.id) {
          id.push(DisabledIds[i])
        }
      }

    }
    setDisabledIds(id)
  }

  function handleOnSelectArciveRow(row, isSelect) {
    let id = []
    if (isSelect) {
      id = archiveIds
      id.push(row.id)
    } else {
      for (let i = 0; i < archiveIds; i++) {
        if (archiveIds[i] !== row.id) {
          id.push(archiveIds[i])
        }
      }
    }
    setArchiveIds(id)
  }
  function handleOnSelectAllArchiveRow(isSelect, rows) {
    if (isSelect) {
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setArchiveIds(id)
    } else {
      setArchiveIds([])
    }

  }
  function handleOnSelectAllDisabledRow(isSelect, rows) {
    if (isSelect) {
      let id = []
      for (let i = 0; i < rows.length; i++) {
        id.push(rows[i].id)
      }
      setDisabledIds(id)
    } else {
      setDisabledIds([])
    }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };


  const selectRowforDisabled = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelectDisabledRow,
    onSelectAll: handleOnSelectAllDisabledRow
  };
  const selectRowforArchive = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelectArciveRow,
    onSelectAll: handleOnSelectAllArchiveRow

  };
  const defaultSorted = [{
    dataField: 'f_name',
    order: 'desc',
    savestate: true
  }];

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]

  }

  const ArchivePageOptions = {
    page: archivePage,
    sizePerPage: sizePerPage,
    totalSize: archiveTotalSize,
    custom: true,
    sizePerPageList: [
      { text: '20', value: 20 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '200', value: 200 }
    ]
  }

  const DisabledpageOptions = {
    page: DisabledPage,
    sizePerPage: sizePerPage,
    totalSize: DisabledTotalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]
  }

  function handleSelectGroup(selectedGroup) {
    setSelectGroup(selectedGroup)
  }

  function handleEnableGroup(selectedEnableGroup) {
    setSelectEnableGroup(selectedEnableGroup)
  }
  function handleArchiveGroup(selectArchiveGroup) {
    setSelectArchiveGroup(selectArchiveGroup)
  }
  function handleBulkAction() {

    if (activeTab === "3") {
      if (selectedGroup.value === "enable") {
        DisabledIds.length > 0 ? HandleBulkOpertation('enable') : toast.error("Select Churches")

      } else if (selectedGroup.value === "archive") {
        DisabledIds.length > 0 ? HandleBulkOpertation('archive') : toast.error("Select Churches")

      }
  
    }
  }

  function handledisablebulkAction() {
    if (activeTab === '2') {
      if (selectedEnableGroup.value === 'disable') {
        ids.length > 0 ? HandleBulkOpertation('disable') : toast.error("Select Churches")

      } else if (selectedEnableGroup.value === 'archive') {
        ids.length > 0 ? HandleBulkOpertation('archive') : toast.error("Select Churches")

      }
    }
  }
  function handleArchivebulkAction() {
    if (activeTab === '4') {
      if (selectArchiveGroup.value === 'disable') {
        archiveIds.length > 0 ? HandleBulkOpertation('disable') : toast.error("Select Archive Churches")
      } else if (selectArchiveGroup.value === 'enable') {
        archiveIds.length > 0 ? HandleBulkOpertation('enable') : toast.error("Select Archive Churches")
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Churches' link="/dashboard"/>
        <Col xs={12}>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Active</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Inactive</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                      acive: activeTab === '4',
                    })}
                    onClick={() => {
                      toggle("4")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="fa fa-user"></i></span>
                    <span className="d-none d-sm-block">Archive</span>

                  </NavLink> */}
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggle("4")
                    }}
                  >
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">Archive</span>
                  </NavLink>
                </NavItem>

              </Nav>
              <Row>

              

              </Row>

              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="2">

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={church}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={church}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <AvForm className="needs-validation" onValidSubmit={FilterData}>

                              <Row className="mb-2">


                                <Col sm="3" >

                                  <Select
                                    value={selectedEnableGroup}
                                    onChange={(e) => {
                                      handleEnableGroup(e);
                                    }}
                                    options={EnableGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col sm='1'>
                                  <Button type='button' className="me-2" onClick={() => handledisablebulkAction()} color='primary'>Apply</Button>

                                </Col>
                                <Col sm="3">
                                  <div className="text-sm-start mb-2">
                                    <Select
                                      value={search_by}
                                      onChange={(e) => {
                                        setSearchby(e)
                                      }}
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                                {/* <Col sm={2}></Col> */}
                                <Col sm='5'>
                                <div className="data1 float-end mb-3"  >
                                  {/* <a href="/create-church"> */}
                                    <Button color="primary" type="button" onClick={()=> props.history.push('/create-church')} className="data2" ><i className="bx bx-plus "></i>{" "}
                                      Create Church
                                    </Button>
                                  {/* </a> */}
                                </div>

                              </Col>


                                


                              </Row>
                             
                              {search_by.value ?
                               
                                    <Row>
                                      <Col sm="5">
                                        {search_by.value !== null ?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>

                                              </Col>

                                            </Row>

                                            : null}

                                      </Col>
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={order_by}
                                            onChange={(e) => {
                                              setOrder_by(e)
                                            }}
                                            options={sortOptionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">
                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                          Filter
                                        </Button>
                                        {" "}
                                        <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                          Clear
                                        </Button>
                                      </Col>
                                    </Row>
                             
                                  : null}
                            
                            </AvForm>

                            <Row>
                              <Col ms='10'>

                              </Col>
                              

                            </Row>





                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleTableChange}
                                noDataIndication={() => NoDataIndication()}
                                selectRow={selectRow}
                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>


                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </TabPane>
                <TabPane tabId="3" >
                  <PaginationProvider
                    pagination={paginationFactory(DisabledpageOptions)}
                    data={DisabledUsers}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={DisabledUsers}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <AvForm className="needs-validation" onValidSubmit={FilterData}>


                              <Row className="mb-2">

                                <Col sm="3" >

                                  <Select
                                    value={selectedGroup}
                                    onChange={(e) => {
                                      handleSelectGroup(e)
                                    }}
                                    options={DisableGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>

                                <Col sm='1'>
                                  <Button type='button' className='me-2' onClick={() => handleBulkAction()} color='primary'>Apply</Button>
                                </Col>
                                <Col sm="3">
                                  <div className="text-sm-start mb-2">
                                    <Select
                                      value={search_by}
                                      onChange={(e) => {
                                        setSearchby(e)
                                      }}
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>


                                </Col>
                                {search_by.value ?
                                  <Col sm="8">
                                    <Row>
                                      <Col sm="5">
                                        {search_by.value !== null ?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>

                                              </Col>

                                            </Row>

                                            : null}

                                      </Col>
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={order_by}
                                            onChange={(e) => {
                                              setOrder_by(e)
                                            }}
                                            options={sortOptionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">


                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                          Filter
                                        </Button>
                                        {" "}
                                        <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                          Clear
                                        </Button>

                                      </Col>
                                    </Row>
                                  </Col>
                                  : null}

                              </Row>

                            </AvForm>
                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleDisableTableChange}
                                noDataIndication={() => DisabledNoDataIndication()}
                                selectRow={selectRowforDisabled}

                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}

                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>


                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>

                </TabPane>
                <TabPane tabId="4">
                  <PaginationProvider
                    pagination={paginationFactory(ArchivePageOptions)}
                    data={archiveData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={archiveData}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <AvForm className="needs-validation" onValidSubmit={FilterData}>

                              <Row className="mb-2">

                                <Col sm="3" >

                                  <Select
                                    value={selectArchiveGroup}
                                    onChange={(e) => {
                                      handleArchiveGroup(e);
                                    }}
                                    options={ArchiveGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col sm='1'>
                                  <Button type='button' className="me-2" color='primary' onClick={() => handleArchivebulkAction()}>Apply</Button>
                                </Col>
                                <Col sm="3">
                                  <div className="text-sm-start mb-2">
                                    <Select
                                      value={search_by}
                                      onChange={(e) => {
                                        setSearchby(e)
                                      }}
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>


                                </Col>
                                {search_by.value ?
                                  <Col sm="9">
                                    <Row>
                                      <Col sm="5">
                                        {search_by.value !== null ?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>

                                              </Col>

                                            </Row>

                                            : null}

                                      </Col>
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={order_by}
                                            onChange={(e) => {
                                              setOrder_by(e)
                                            }}
                                            options={sortOptionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">
                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                          Filter
                                        </Button>
                                        {" "}
                                        <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                          Clear
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                  : null}



                              </Row >
                            </AvForm>

                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                hover
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleArchiveTableChange}
                                noDataIndication={() => ArchiveDataIndication()}
                                selectRow={selectRowforArchive}
                              />

                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination pagination-rounded mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>

                              </Col>
                            </Row>


                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
        {alert_confirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleCustomerClicks(selected_id)}
            onCancel={() => {
              setAlert_confirm(false)
              setSelected_id(null)
            }
            }
          >
            {/* You won't be able to revert this! */}
          </SweetAlert>
        ) : null}

        {success_confirm ? (
          <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setSuccess_confirm(false)
              setAlert_confirm(false)
            }
            }
          >
            {dynamic_description}
          </SweetAlert>
        )
          : null
        }

      </div>
      <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{`Add Location`} </h5>

            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>


        </Modal>
      </Col>

    </React.Fragment>
  );
}
export default AllChurch
