import React, { useState, useEffect } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Label, Button,  
} from "reactstrap"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';



import { toast } from "react-toastify";
import { get, post } from "../../helpers/api_helper"

import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";

import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

import Select from "react-select";


const CreateChurch = (props) => {
    const state = props.location.statedata
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)
    const [submitting, setSubmitting] = useState(null)
    const [ischecked, setIsChecked] = useState(null)
    const [isClaimed,setIsClaimed]=useState(null)
    const [isSearch,setIsSearch]=useState(null)
    const [img, setImg] = useState(null)
    const [name, setName] = useState([])
    const [selectedDenomination, setselectedDenomination] = useState({ label: "Select Denomination", value: null });
    const [isapproved,setapproved]=useState(false)

    useEffect(() => {
        loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false`)

        setDidMount(true);
        get(`${BASE_URL}/api/house_church/dash_list_churches/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
        )
            .then(response => {
                setUser(response.data)
                setImg(response.data.image,)
                setIsClaimed(response.data.claimed)
                setIsSearch(response.data.search_status)
                setIsChecked(response.data.status)
               
            })

     
    }, [id]);




    if (!didMount) {
        return null;
    }

    const handleFileUpload = (e) => {
        try {
            let reader = new FileReader();
            let file = e.target.files[0];
            if (!file){
                return;
            }
            const fileSizeInKB=file.size/1024;
            if (fileSizeInKB>30){
                toast.error('Please select an image file smaller than 30 kB');
                setImg(null)
            }else{
                reader.onloadend = () => {
                    var previewImgUrl = reader.result
                    setImg(previewImgUrl)
                }
                reader.readAsDataURL(file);
            }
           
        } catch (error) {

        }
    }

    function handleSubmit(e, values) {
        setSubmitting(true)
        if (img) {
            // Convert the image to bytes to calculate its size
            const imageSizeInKB = img.size / 1024;

            if (imageSizeInKB > 30) {
                // Show an error message using the 'toast.error()' function
                toast.error('Please select an image less than 30 KB');
                setSubmitting(false); // Set submitting to false to enable the user to try again.
                return; // Exit the function without making the API call.
            }
        }


        let data =  {...values,image:img,status:ischecked,claimed:isClaimed?1:0,search_status:isSearch?1:0,approved:isapproved,denomination_id:selectedDenomination.value}
        console.log(data,'data')
        post(`${BASE_URL}/api/house_church/dash_list_churches/`,data,
        { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
    )
        .then(response => {
            if (response.status >= 400) {
               
                console.log(typeof response.name,'responsevalidation')
                setSubmitting(false)
                console.log(response.data,'church')
            } else {
                console.log(response,'sucesschurch')
                setSubmitting(false)
            }
        })

    }
   
    
   
    function loadData(url) {
        let data = get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken()  } })
        data.then(response => {
            const formattedOptions = response.data.results.map(items => ({
                value: items.id,
                label: items.name
            }));



            setName(formattedOptions)



        })
    }
   
  

    function handleSelectGroup(selectedDenomination) {
        setselectedDenomination(selectedDenomination);
    }

  
   
    
    function handleDenominationChange(e) {
        loadData(`${BASE_URL}/api/house_church/denominations/?status=true&archive=false&name=${e}`)
    }
   










    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Church" breadcrumbItem={'Create Church'} link="/all-churches" data={state} />

                <Row>
                    <Col lg={12}>



                        {user ?
                            <Row>
                                <Col md="12">

                                    <Row>

                                        <Col sm="10">
                                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                                <Card>
                                                <CardBody>
                                                <CardTitle>Church Detail </CardTitle>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="email">Email</Label>
                                                            <AvField
                                                            name='email'
                                                            placeholder='Email'
                                                            type='email'
                                                            className='form-control'
                                                            
                                                            
                                                            id='email'
                                                            value={user.email}/>

                                                        </div>
                                                    </Col>
                                                    <Col md='6'>
                                                        <div className="mb-3">
                                                            <Label htmlFor="pageurl">Page Url</Label>
                                                            <AvField
                                                            name='pageurl'
                                                            placeholder='Page Url'
                                                            type='text'
                                                            className='form-control'
                                                            id='pageurl'
                                                            value={user.pageurl}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="name">Name</Label>
                                                            <AvField
                                                                name="name"
                                                                placeholder="Name"
                                                                type="text"
                                                                className="form-control"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter a name' },
                                                                    pattern: { value: '^[a-zA-Z0-9 ]*$', errorMessage: 'Your name must be composed only with letter and numbers' },
                                                                    minLength: { value: 6, errorMessage: 'Your name must be between 6 and 16 characters' },
                                                                    maxLength: { value: 16, errorMessage: 'Your name must be between 6 and 16 characters' }
                                                                  }}
                                                                id="name"
                                                                value={user.name}
                                                            />
                                                        </div>

                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="address">Address</Label>
                                                            <AvField
                                                                name="address"
                                                                placeholder="Address"
                                                                type="text"
                                                                errorMessage=" Please Enter Address."
                                                                className="form-control"

                                                                id="address"
                                                                value={user.address}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="city">City</Label>
                                                            <AvField
                                                                name="city"
                                                                placeholder="City"
                                                                type="text"
                                                                errorMessage=" Please Enter city."
                                                                className="form-control"

                                                                id="city"
                                                                value={user.city}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="state">State</Label>
                                                            <AvField
                                                                name="state"
                                                                placeholder="State"
                                                                type="text"
                                                                errorMessage=" Please Enter State."
                                                                className="form-control"

                                                                id="state"
                                                                value={user.state}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>


                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="zipcode">ZipCode</Label>
                                                            <AvField
                                                                name="zipcode"
                                                                placeholder="ZipCode"
                                                                type="text"
                                                                errorMessage=" Please provide a valid ZipCode."
                                                                className="form-control"

                                                                id="zipcode"
                                                                value={user.zipcode}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="phone"
                                                                label="Phone"
                                                                placeholder="Contact Number"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9+-]+$",
                                                                        errorMessage: "Invalid Contact"
                                                                    },
                                                                }}
                                                                value={user.phone}
                                                            /> 
                                                        </div>

                                                    </Col>

                                                </Row>
                                                <Row>

                                                    <Col md="6">

                                                        <div className='mb-3'>
                                                            <Label htmlFor="country">Country</Label>
                                                            <AvField
                                                                name='country'
                                                                placeholder='Country'
                                                                type='text'
                                                                className='form-control'
                                                                id='country'
                                                                value={user.country} />



                                                        </div>

                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="poc">POC</Label>
                                                            <AvField
                                                                name="poc"
                                                                placeholder="Poc"
                                                                type="text"
                                                                errorMessage=" Please provide a valid Poc."
                                                                className="form-control"

                                                                id="poc"
                                                                value={user.poc}
                                                            />
                                                        </div>

                                                    </Col>
                                                </Row>


                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="poc_email">POC Email</Label>
                                                            <AvField
                                                                name="poc_email"
                                                                placeholder="Poc Email"
                                                                type="text"
                                                                className="form-control"
                                                                id="poc_email"
                                                                value={user.poc_email}
                                                            />
                                                        </div>

                                                    </Col>
                                                    <Col md="6">
                                                        <div className='mb-3'>
                                                            <Label htmlFor='poc_phone'>POC Phone</Label>
                                                            <AvField
                                                                name='poc_phone'
                                                                placeholder='Poc Phone'
                                                                type='text'
                                                                id="poc_phone"
                                                                value={user.poc_phone} />

                                                        </div>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>

                                                        {/* <div className="checkbox-wrapper">
                                                        <label>
                                                            <input type="checkbox"
                                                                checked={ischecked}
                                                                onChange={(e) => setIsChecked(e.target.checked)} />
                                                                Status
                                                        </label>
                                                    </div>  */}
                                                    </Col>
                                                    <Col md={6}>

                                                    </Col>
                                                </Row>

                                                <Row>
                                                <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor='e_zip_full'>E Zip Full</Label>
                                                            <AvField
                                                                name='e_zip_full'
                                                                placeholder='E Zip Full'
                                                                type='text'
                                                                className='form-control'
                                                                id="e_zip_full"
                                                                value={user.e_zip_full}
                                                            />

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label>Denomination</Label>
                                                            <Select
                                                                value={selectedDenomination}
                                                                onChange={(e) => {
                                                                    handleSelectGroup(e);
                                                                }}
                                                                onInputChange={(e)=>handleDenominationChange(e)}
                                                                options={name}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    
                                                <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor='loc_county'>LOC Country</Label>
                                                            <AvField
                                                                name='loc_county'
                                                                placeholder='Loc Country'
                                                                type='text'
                                                                className='form-control'
                                                                id='loc_county'
                                                                value={user.loc_county} />
                                                        </div>
                                                    </Col>

                                                    <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor='loc_area_code'>LOC Area Code</Label>
                                                            <AvField
                                                                name='loc_area_code'
                                                                placeholder='Loc Area Code'
                                                                type='text'
                                                                className='form-control'
                                                                id='loc_area_code'
                                                                value={user.loc_area_code} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor="loc_fips">LOC Fips</Label>
                                                            <AvField
                                                                name='loc_fips'
                                                                placeholder='Loc Fips'
                                                                type='text'
                                                                className='form-control'
                                                                id='loc_fips'
                                                                value={user.loc_fips} />

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor='loc_msa'>LOC MSA</Label>
                                                            <AvField
                                                                name='loc_msa'
                                                                placeholder='Loc MSA'
                                                                type="text"
                                                                className="form-control"
                                                                id="loc_msa"
                                                                value={user.loc_msa} />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="loc_tz">LOC TZ</Label>
                                                            <AvField
                                                                name='loc_tz'
                                                                placeholder='Loc TZ'
                                                                type='text'
                                                                id="loc_tz"
                                                                value={user.loc_tz} />

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor="loc_lat_poly">LOC Lat Poly</Label>
                                                            <AvField
                                                                name='loc_lat_poly'
                                                                placeholder='Loc Lat Poly'
                                                                type='text'
                                                                id='loc_lat_poly'
                                                                value={user.loc_lat_poly} />
                                                        </div>


                                                    </Col>
                                                </Row>
                                                <Row>

                                                <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor='loc_long_poly'>LOC Long Poly</Label>
                                                            <AvField
                                                                name='loc_long_poly'
                                                                placeholder='Loc Long Poly'
                                                                type='text'
                                                                id='loc_long_poly'
                                                                value={user.loc_long_poly} />
                                                        </div>

                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor='lat'>Latitude</Label>
                                                            <AvField
                                                                name='lat'
                                                                placeholder='Lat'
                                                                type='text'
                                                                id='lat'
                                                                value={user.lat} />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor='lng'>Longitude</Label>
                                                            <AvField
                                                                name='lng'
                                                                placeholder='Lng'
                                                                type='text'
                                                                id='lng'
                                                                value={user.lng} />

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor='loc_dst'>LOC DST</Label>
                                                            <AvField
                                                                name='loc_dst'
                                                                placeholder='Loc DST'
                                                                type='text'
                                                                id='loc_dst'
                                                                value={user.loc_dst} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor='website'>Website</Label>
                                                            <AvField
                                                                name='website'
                                                                placeholder='Website'
                                                                type='text'
                                                                id="website"
                                                                value={user.website} />
                                                        </div>
                                                    </Col>
                                                    <Col mb={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor='web_meta_title'>Web Meta Title</Label>
                                                            <AvField
                                                                name='web_meta_title'
                                                                placeholder='Web Meta Title'
                                                                type='text'
                                                                id='web_meta_title'
                                                                value={user.web_meta_title} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col mb={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor='web_meta_desc'>Web Meta Desc</Label>
                                                            <AvField
                                                                name='web_meta_desc'
                                                                placeholder='Web Meta Desc'
                                                                type='text'
                                                                id='web_meta_desc'
                                                                value={user.web_meta_desc} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="web_meta_keys">Web Meta Keys</Label>
                                                            <AvField
                                                                name='web_meta_keys'
                                                                placeholder='Web Meta Keys'
                                                                type='text'
                                                                id='web_meta_keys'
                                                                value={user.web_meta_keys} />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col md={6}>
                                                        <div className='mb-3'>
                                                            <Label htmlFor="biz_phone_ext">Biz Phone Ext</Label>
                                                            <AvField
                                                                name='biz_phone_ext'
                                                                placeholder='Biz Phone Ext'
                                                                type='text'
                                                                id='biz_phone_ext'
                                                                value={user.biz_phone_ext} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="biz_info">Biz Info</Label>
                                                            <AvField
                                                                name="biz_info"
                                                                placeholder="Biz Info"
                                                                type="textarea"
                                                                className="form-control"
                                                                id="biz_info"
                                                                value={user.biz_info}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>


                                                <Row>
                                                    
                                                   
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor='code'>Code</Label>
                                                            <AvField
                                                                name='code'
                                                                placeholder='Code'
                                                                type='textarea'
                                                                id='code'
                                                                value={user.code} />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>

                                                    <Col md={3}>
                                                        <div className="checkbox-wrapper mb-2">
                                                            <label>
                                                                <input type="checkbox"
                                                                    checked={ischecked}
                                                                    onChange={(e) => setIsChecked(e.target.checked)} /> {"      "} 
                                                                Status
                                                            </label>
                                                        </div>
                                                    </Col>
                                                   
                                                    <Col md={3}>
                                                    <div className="checkbox-wrapper mb-3">
                                                            <label>
                                                                <input type="checkbox"
                                                                    checked={isSearch}
                                                                    onClick={(e) => setIsSearch(e.target.checked)}
                                                                /> {"      "} 
                                                                Search Status
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                    <div className="checkbox-wrapper mb-3">
                                                            <label>
                                                                <input type="checkbox"
                                                                    checked={isClaimed}
                                                                    onClick={(e) => setIsClaimed(e.target.checked)}
                                                                /> {"      "} 
                                                                Claimed
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="checkbox-wrapper mb-3">
                                                            <label>
                                                                <input type='checkbox' checked={isapproved} onClick={(e)=>setapproved(e.target.checked)}/>{"   "}
                                                            </label>
                                                            Approved
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col md={6}>
                                                   
                                                 <div className="mb-3">
                                                 <Label>Image</Label>{" "}
                                                   <input
                                                       type="file"
                                                       id="image"
                                                       accept="image/png, image/jpeg"
                                                       onChange={(e) => handleFileUpload(e)}
                                                       value={user.image}
                                                   />
                                                 </div>
                                               
                                       </Col>
                                                </Row>
                                            
                                                   
                                                  

                                                {submitting ?
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary waves-effect waves-light my-3"
                                                    >
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                        Creating
                                                    </button>
                                                    :
                                                    <Button color="primary" type="submit">
                                                        Create
                                                    </Button>
                                                }
                                            </CardBody>
                                                </Card>
                                            </AvForm>
                                        </Col>

                                    </Row>


                                </Col>

                            </Row>
                            :
                            <div id="preloader">
                                <div id="status">
                                    <div className="spinner-chase">
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                    </div>
                                </div>
                            </div>
                        }





                    </Col>



                </Row>


            </div>

        </React.Fragment>
    )
}

export default CreateChurch
