import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { BASE_URL } from "../../../helpers/url_helper";
import { get, put, post } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import { EditorState, convertToRaw, ContentState, left, right, center, justify, undo, redo, color} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ValidateUser } from "../../../helpers/validate_user";
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken";

const EmialTypeOptions = [
    {
      label: "Email Type",
      options: [
        {label: "Register User", value:"Register User" },
        {label:'Create House Church',value:"Create HouseChurch"},
        // {label:'Join HouseChurch',value:"Join HouseChurch"},
        {label:'Create Event',value:"Create Event"},
        // {label:'Join Event',value:"Join Event"},
        {label:'Progress',value:"Progress"},
      ],
    },
  ];

const ManageTemplate = (props) => {
  ValidateUser(props)
    const { id } = props.match.params
    const [didMount, setDidMount] = useState(false);
    const [template, setTemplate] = useState(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty()); 
    const [rawMessage, setrawMessage] = useState(''); 
    const [submitting, setSubmitting] = useState(null);
    const [selectedType, setSelectedType] = useState({ label: "Select Email Type", value: null });


    useEffect(() => {
        setDidMount(true);
        if(id){
            loadTerms()
        }
        
    },[]);


    if(!didMount) {
        return null;
      }


    function onEditorStateChange(editorState){
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    function cleanHtml(html){
      // Create a temporary DOM element to parse the HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // Remove all span elements
      const spans = tempDiv.getElementsByTagName('span');
      for (let i = spans.length - 1; i >= 0; i--) {
        const span = spans[i];
        // Replace each span with its inner HTML
        while (span.firstChild) {
          span.parentNode.insertBefore(span.firstChild, span);
        }
        span.parentNode.removeChild(span);
      }
  
      // Return the cleaned HTML
      return tempDiv.innerHTML;
    };

    function loadTerms(){
        get(`${BASE_URL}/api/house_church/email-template/${id}/`, { headers: { 'Content-Type': 'application/json', }, validateStatus: false})
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                
                toast.error("Somthing went Wrong")
                props.history.push('/email-template')
                
            }else{
                setTemplate(response.data)
                setSelectedType({ label: response.data.temp_type, value: response.data.temp_type })
                const { contentBlocks, entityMap } = htmlToDraft(response.data.content);
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorrState = EditorState.createWithContent(contentState);
                setrawMessage(draftToHtml(convertToRaw(editorrState.getCurrentContent())))
                setEditorState(editorrState)
            }
        })
    }

    function handleSubmit(e, values){
        if (selectedType.value){
            
            let data = {}
            data['content'] = cleanHtml(rawMessage)
            data['subject'] = values.subject
            data['temp_type'] = selectedType.value
            console.log(data, "------------")
            if(id){
                setSubmitting(true)
                put(`${BASE_URL}/api/house_church/email-template/${id}/`, data,
                { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false}
                )
                .then(response => {
                    if(response.status >= 400){
                        console.log(response.data.temp_type)
                        if (response.data.temp_type){
                            toast.error(`Email Template with ${selectedType.value} Type Already Created`)
                        }else{
                            toast.error("Somthing went Wrong")
                        }
                        setSubmitting(false)
                        
                    }else{
                        setSubmitting(false)
                        toast.success("Email Template Updated")
                    }
                })
            }
            else{
                setSubmitting(true)
                post(`${BASE_URL}/api/house_church/email-template/`, data,
                { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false}
                )
                .then(response => {
                    if(response.status >= 400){
                        console.log(response.data.temp_type)
                        if (response.data.temp_type){
                            toast.error(`Email Template with ${selectedType.value} Type Already Created`)
                        }else{
                            toast.error("Somthing went Wrong")
                        }
                        setSubmitting(false)
                        
                    }else{
                        setSubmitting(false)
                        toast.success("Email Template Created")
                    }
                })
            

            }
            
        } else{
            toast.error("Please Select Email Type")
        }
        
    }

    const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) =>
    (
        <div id="add_state" 
        style={{zIndex:99999, position:'absolute',background:"white",width:"100%"}} 
        ref={innerRef} {...innerProps}>
            {children}
        </div>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title={'Email Template'} breadcrumbItem={template?`Update ${template.temp_type} Template`:'Create Email Template'} link={"/email-template"} />

                    <Row>
                        <Col md="9">
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="12" className="mb-3">
                                                        <div className="text-sm-start mb-2">
                                                            
                                                            <Select
                                                            value={selectedType}
                                                            onChange={(e) => {
                                                                setSelectedType(e)
                                                            }}
                                                            options={EmialTypeOptions}
                                                            classNamePrefix="select2-selection"
                                                            components={{ Menu: CustomMenu }}
                                                            isDisabled={template?true:false}
                                                            />
                                                            
                                                        </div>
                                                    </Col>
                                                    
                                                </Row>
                                                <Row>
                                                    <Col sm="12" className="mb-3">
                                                        <div className="text-sm-end mb-2">
                                                            <AvField
                                                                name="subject"
                                                                placeholder="Subject"
                                                                type="text"
                                                                value={template?.subject}
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                            />
                                                        </div>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    
                                                    <Col md="12" className="mb-3">
                                                    {/* <Label >Terms & Condition</Label> */}
                                                        <Editor       
                                                        toolbar={{
                                                        
                                                            options: ['inline', 'blockType', 'textAlign', 'link', 'history'],
                                                            inline: {
                                                            options: ['bold', 'italic', 'underline'],
                                                            },
                                                            
                                                            blockType: {
                                                                inDropdown: true,
                                                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                              },
                                                              textAlign: {
                                                                inDropdown: false,
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                                options: ['left', 'center', 'right', 'justify'],
                                                                left: { icon: left, className: undefined },
                                                                center: { icon: center, className: undefined },
                                                                right: { icon: right, className: undefined },
                                                                justify: { icon: justify, className: undefined },
                                                              },
                                                              history: {
                                                                inDropdown: false,
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                                options: ['undo', 'redo'],
                                                                undo: { icon: undo, className: undefined },
                                                                redo: { icon: redo, className: undefined },
                                                              },
                                                              fontFamily: {
                                                                options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'metropolisRegular', 'gothambold'],
                                                                className: undefined,
                                                                component: undefined,
                                                                dropdownClassName: undefined,
                                                              },
                                                              colorPicker: {
                                                                icon: color,
                                                                className: undefined,
                                                                component: undefined,
                                                                popupClassName: undefined,
                                                                colors: ['rgb(0,0,0)','rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                                  'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                                  'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                                                  'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                                  'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                                  'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                                              },
                                                        }}   
                                                        editorStyle={{ height: '450px', border:'solid 2px', padding:'10px' }}   
                                                        editorState={editorState}            
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={onEditorStateChange}
                                                        />
                                                    </Col>
                                                </Row>
                                        
                                           
                                                {submitting?
                                                <button
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light my-3"
                                                    >
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                    Submit
                                                </button>
                                                :
                                                <Button color="primary my-3" type="submit">
                                                Submit
                                                </Button>
                                            }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </Col>
                    </Row>
                {/* :
                <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                    </div>
                </div>
            </div> */}
                
            </div>
        </React.Fragment>
    )
}

export default ManageTemplate