import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Spinner } from "reactstrap"

import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"

// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get, del } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";

import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"
import moment from "moment-timezone";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const DummyUsers = (props) => {

    const [userData, setUserData] = useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [page, setpage] = useState(1)
    const [sizePerPage, setsizePerPage] = useState(20)
    const [loadUserdata, setLoadUserData] = useState(false)


    const [selected_id, setSelected_id] = useState(null)

    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")


    const [ids, setIds] = useState([])
    const [deleteUser, setDeleteUser] = useState('null')

    ValidateUser(props)

    const Defaultcolumns = [
        {
            dataField: "id",
            text: "ID",
            hidden: true,

        },

        {
            dataField: 'first_name',
            text: "Name",
            headerFormatter: HeaderFormatter,
            // sort: true,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-user-profile/${row.id}` }} className="me-3 text-dark">{row.first_name + " " + row.last_name}</Link>
            ),
        },

        {
            dataField: "email",
            text: "Email",
            headerFormatter: HeaderFormatter,
            // sort: true,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-user-profile/${row.id}` }} className="me-3 text-dark">{cellContent}</Link>
            ),
        },
        {
            dataField: "phone",
            text: "Phone",
            headerFormatter: HeaderFormatter,
            // sort: true,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-user-profile/${row.id}` }} className="me-3 text-dark">{cellContent}</Link>
            ),
        },
        {
            dataField: "date_joined",
            text: "Date",
            headerFormatter: HeaderFormatter,
            // sort: true,
            formatter: (cellContent, row) => (
                moment(cellContent).tz("America/Chicago").format("MM-DD-YYYY")
            ),
        },


    ]
    const Activecolumns = [...Defaultcolumns,
    {
        dataField: "action",
        isDummyField: true,
        headerFormatter: HeaderFormatter,
        text: "Action",
        formatter: (cellContent, row) => (
            <React.Fragment>
                <Link to={{ pathname: `/edit-user-profile/${row.id}` }} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i>{cellContent}</Link>
            </React.Fragment>
        ),
    },]

    useEffect(() => {
        loadData(`${BASE_URL}/api/accounts/users/?is_dummy=true`, 20, 1)



    }, []);

    function loadData(url, sizePerPage, page) {
        setLoadUserData(false)
        get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
            .then(response => {
                // const data=response.data.results.filter((item)=>item.first_name !=='' || item.last_name !=='')
                let users_data = response.data.results
                setUserData(users_data)
                let newArray = [];
                for (let i = 0; i < users_data.length; i++) {
                    let item = users_data[i]
                    let obj = { 'First Name': item.first_name, 'Last Name': item.last_name, 'Email': item.email, 'Phone': item.phone, 'Adress': item.address, 'City': item.city, 'State': item.state }
                    newArray.push(obj);

                }
                settotalSize(response.data.count)
                setpage(page)
                setsizePerPage(sizePerPage)
                setLoadUserData(true)
            })
    }




    function handleDeleteUser(id) {
        del(`${BASE_URL}/api/accounts/users/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } },
            { validateStatus: false }
        )
            .then(res => {
                if (res.status >= 400) {

                }
                else {

                    let new_page = page
                    if (userData.length < 2) {
                        new_page = new_page > 1 ? new_page - 1 : 1
                    }
                    let url = `${BASE_URL}/api/accounts/users/?is_dummy=true&page_size=${sizePerPage}&page=${new_page}`

                    loadData(url, sizePerPage, new_page)


                    setSuccess_confirm(true)
                    setAlert_confirm(false)
                    setDynamic_title('Deleted!')
                    setDynamic_description("User Has been Deleted.")
                    setSelected_id(null)
                    setDeleteUser(null)
                }
            }
            )
    }


    function NoDataIndication() {
        if (loadUserdata && userData.length === 0) {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )

        } else {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
            )

        }
    }

    function handleTableChange(type, { page, sizePerPage, searchText, }) {

        setTimeout(() => {
            setLoadUserData(false)
            setUserData([])
            let new_page = page
            let url = `${BASE_URL}/api/accounts/users/?is_dummy=true&page_size=${sizePerPage}&page=${new_page}`
            window.scrollTo(0, 0)
            loadData(url, sizePerPage, new_page, searchText)
        }, 1000);
    }

    function handleOnSelect(row, isSelect) {
        let id = []
        if (isSelect) {
            id = ids
            id.push(row.id)
        } else {
            for (let i = 0; i < ids.length; i++) {
                if (ids[i] !== row.id) {
                    id.push(ids[i])
                }
            }

        }
        setIds(id)
    }

    function handleOnSelectAll(isSelect, rows) {
        if (isSelect) {
            let email = []
            let id = []
            for (let i = 0; i < rows.length; i++) {
                email.push(rows[i].email.toLowerCase())
                id.push(rows[i].id)
            }
            setIds(id)
        } else {
            setIds([])
        }
    }




    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };



    const defaultSorted = [{
        dataField: 'f_name',
        order: 'desc',
        savestate: true
    }];

    //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }

    document.title = "Dummy Users | FAHC Admin Dashboard"


    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Dashboard" breadcrumbItem='Dummy Users' link="/dashboard" />
                <Col xs={12}>
                    <Card>
                        <CardBody>

                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                data={userData}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        data={userData}
                                        columns={Activecolumns}
                                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                        bootstrap5
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>


                                                <div className="table-responsive">

                                                    <BootstrapTable
                                                        remote
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                        responsive
                                                        bordered={false}
                                                        striped={true}
                                                        defaultSorted={defaultSorted}
                                                        hover
                                                        classes={
                                                            "table align-middle table-nowrap table-check"
                                                        }
                                                        headerWrapperClasses={"table-light"}
                                                        onTableChange={handleTableChange}
                                                        noDataIndication={() => NoDataIndication()}
                                                        selectRow={selectRow}
                                                    />

                                                </div>
                                                <Row>
                                                    <Col sm={6}>
                                                        <div className="mb-3">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="pagination pagination-rounded mb-2">
                                                            <PaginationTotalStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>

                                                    </Col>
                                                    <Col sm={6}>
                                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>

                                                    </Col>
                                                </Row>

                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
                {alert_confirm ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => handleDeleteUser(deleteUser)}
                        onCancel={() => {
                            setAlert_confirm(false)
                            setSelected_id(null)
                            setDeleteUser(null)
                        }
                        }
                    >
                        You won't be able to revert this!
                    </SweetAlert>
                ) : null}

                {success_confirm ? (
                    <SweetAlert
                        success
                        title={dynamic_title}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setSuccess_confirm(false)
                            setAlert_confirm(false)
                            setDeleteUser(null)
                        }
                        }
                    >
                        {dynamic_description}
                    </SweetAlert>
                )
                    : null
                }

            </div>


        </React.Fragment>
    )
}

export default DummyUsers
