import React, { useEffect, useState } from "react"
import Breadcrumbs from '../../components/Common/Breadcrumb'


import { Row, Col, Card, CardBody, Spinner, NavLink, NavItem, Nav, TabPane, TabContent, Button, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
import classnames from "classnames"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get, post, patch, del } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"
import moment from "moment-timezone";


const optionGroup = [
    {
        label: "Search by",
        options: [
            { label: "Name", value: "name" },
        ],
    },
];

const EnableGroup = [
    {
        label: "Enabled By",
        options: [
            { label: "Move To Inactive", value: "inactive" },
            { label: "Move To Archive", value: "archive" }

        ]
    }
];

const DisableGroup = [
    {
        label: "Disable By",
        options: [
            { label: "Move To Active", value: "active" },
            { label: "Move To Archive", value: "archive" },
            { label: "Delete", value: "delete" }
        ]
    }
];
const ArchiveGroup = [
    {
        label: "Archive By",
        options: [
            { label: "Move To Active", value: "active" },
            { label: "Move To Inactive", value: "inactive" },
            { label: "Delete", value: "delete" }
        ]
    }
];

const sortOptionGroup = [
    {
        label: "Order by",
        options: [
            { label: "Asc", value: "" },
            { label: "Desc", value: "-" },
        ],
    },
];

const Organizations = (props) => {
    const [activeTab, setactiveTab] = useState("2")
    const [church, setChurch] = useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [page, setpage] = useState(1)
    const [sizePerPage, setsizePerPage] = useState(20)
    const [loadHouseChurch, setloadHouseChurch] = useState(false)


    const [archiveIds, setArchiveIds] = useState([])
    const [archiveData, setArchiveData] = useState([])
    const [archiveTotalSize, setArchiveTotalSize] = useState(0);
    const [archivepage, setArchivePage] = useState(1)
    const [loadArchiveHouseChurch, setloadArchiveHouseChurch] = useState(false)

    const [DisabledUsers, setDisabledUsers] = useState([]);
    const [DisabledTotalSize, setDisabledtotalSize] = useState(0);
    const [DisabledPage, setDisabledpage] = useState(1)

    const [selected_id, setSelected_id] = useState(null)
    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")
    const [loaddata, setLoaData] = useState(false)

    const [ids, setIds] = useState([])
    const [DisabledIds, setDisabledIds] = useState([])

    const [modal_standard, setmodal_standard] = useState(false)

    const [filter, setFilter] = useState("")
    const [search, setSearch] = useState("")
    const [search_by, setSearchby] = useState({ label: "Search By", value: null });
    const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });

    const [selectedGroup, setselectedGroup] = useState({ label: 'Perform Bulk Action', value: null })

    const [selectedDisableGroup, setselectedDisableGroup] = useState({ label: "Perform Bulk Action", value: null })
    const [selectedArchiveGroup, setselectedArchiveGroup] = useState({ label: "Perform Bulk Action", value: null })
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))
    const [selectedState, setselectedState] = useState({ label: "Select State", value: null })
    const [filterdata, setFilterData] = useState("")
    const [stateOptions, setStateOptions] = useState([])





    ValidateUser(props)


    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const columns = [
        {
            dataField: "id",
            text: "S No.",
            hidden: true
        },

        {
            dataField: "name",
            text: "Name",
            sort: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (

                <React.Fragment>
                    <Link to={`/organization/${row.id}`} className="me-3 text-dark" >{cellContent}</Link>
                </React.Fragment>
            ),

        },

        {
            dataField: "denomination_id",
            text: "Denomination",
            sort: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (

                <React.Fragment>
                    <Link to={`/organization/${row.id}`} className="me-3 text-dark" >{cellContent?.name}</Link>
                </React.Fragment>
            ),

        },
        {
            dataField: "user_id",
            text: "Created By",
            sort: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (

                <React.Fragment>
                    <Link to={`/organization/${row.id}`} className="me-3 text-dark" >{`${cellContent?.first_name} ${cellContent?.last_name}`}</Link>
                </React.Fragment>
            ),

        },
        {
            dataField: "zipcode",
            text: "ZIpcode",
            sort: true,
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (

                <React.Fragment>
                    <Link to={`/organization/${row.id}`} className="me-3 text-dark" >{cellContent}</Link>
                </React.Fragment>
            ),

        },
        {
            dataField: "site",
            isDummyField: true,
            headerFormatter: HeaderFormatter,
            text: "Action",
            formatter: (cellContent, row) => (
                <React.Fragment>

                    <a href={`https://findahousechurch.com/org/${row.slug}`} target="__blank" className="me-3 text-primary" >  <i className="mdi mdi-search-web"></i></a>
                    {/* <Link to="#" onClick={() => { setAlert_confirm(true);  }} className="me-3 text-danger"><i className="mdi mdi-trash-can font-size-18"></i>{cellContent}</Link> */}

                </React.Fragment>

            ),
        },


    ]




    useEffect(() => {

        loadData(`${BASE_URL}/api/house_church/organization/?status=active`, 20, 1)
        loadDisabledData(`${BASE_URL}/api/house_church/organization/?status=inactive`, 20, 1)
        loadArchiveData(`${BASE_URL}/api/house_church/organization/?status=archive`, 20, 1)
        return () => {
            setChurch([])
        }

    }, []);


    function loadData(url, sizePerPage, page) {
        setloadHouseChurch(false)
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setChurch(response.data.results)
            settotalSize(response.data.count)
            setpage(page)
            setsizePerPage(sizePerPage)
            setloadHouseChurch(true)
        })
    }

    function loadArchiveData(url, sizePerPage, page) {
        setloadArchiveHouseChurch(false)
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setArchiveData(response.data.results)
            setArchiveTotalSize(response.data.count)
            setArchivePage(page)
            setsizePerPage(sizePerPage)
            setloadArchiveHouseChurch(true)
        })
    }

    function loadDisabledData(url, sizePerPage, page) {
        setLoaData(false)
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setDisabledUsers(response.data.results)
            setDisabledtotalSize(response.data.count)
            setDisabledpage(page)
            setsizePerPage(sizePerPage)
            setLoaData(true)
        })
    }


    function ClearFilter() {
        setFilterData("")
        setFilter("")
        setSearch("")
        setSearchby({ label: "Search By", value: null });
        setselectedState({ label: "Select State", value: null })

        loadData(`${BASE_URL}/api/house_church/organization/?status=active`, 20, 1)
        loadDisabledData(`${BASE_URL}/api/house_church/organization/?status=inactive`, 20, 1)
        loadArchiveData(`${BASE_URL}/api/house_church/organization/?status=archive`, 20, 1)

    }

    function FilterData(e, values) {
        let filter_data = "";

        if (!search_by.value) {
            toast.error("Select Search Field");
        } else {
            if (search_by.value === "created_at") {
                filter_data = `&from_date=${fromDate}&to_date=${toDate}`
            }
            else {
                filter_data = search_by.value === 'state' ? `&${search_by.value}=${selectedState.value}` : `&${search_by.value}=${values.search}`;
            }
            // Check if the order_by field is provided and add it to the filter_data

            if (order_by.value && search_by.value) {
                // filter_data = filter_data + `&order_by=${order_by.value}`;

                filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`

            }

            setChurch([])
            loadData(`${BASE_URL}/api/house_church/organization/?status=active${filter_data}`, 20, 1);
            loadDisabledData(`${BASE_URL}/api/house_church/organization/?status=inactive${filter_data}`, 20, 1);
            loadArchiveData(`${BASE_URL}/api/house_church/organization/?status=archive${filter_data}`, 20, 1);
            setFilter(filter_data);
            setFilterData(filter_data);
        }
    }




    function NoDataIndication() {
        if (loadHouseChurch && church.length === 0) {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )

        } else {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
            )

        }
    }

    function ArchiveDataIndication() {
        if (loadArchiveHouseChurch && archiveData.length === 0) {
            return (
                <div className="d-flex align-itme-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>

                </div>
            )
        } else {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color='dark' />

                </div>
            )
        }
    }
    function DisabledNoDataIndication() {
        if (loaddata && DisabledUsers.length === 0) {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )

        } else {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
            )

        }
    }

    function handleTableChange(type, { page, sizePerPage, searchText, }) {

        setTimeout(() => {
            setloadHouseChurch(false)
            setChurch([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/organization/?status=active&page_size=${sizePerPage}&page=${new_page}`
            if (filterdata !== '') {
                url = url + `&${filterdata}`
            }

            window.scrollTo(0, 0)
            loadData(url, sizePerPage, new_page, searchText)


        }, 1000);
    }
    function handleDisableTableChange(type, { page, sizePerPage, searchText, }) {

        setTimeout(() => {
            setLoaData(false)
            setDisabledUsers([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/organization/?status=inactive&page_size=${sizePerPage}&page=${new_page}`
            if (filterdata !== '') {
                url = url + `&${filterdata}`
            }
            window.scrollTo(0, 0)
            loadDisabledData(url, sizePerPage, new_page, searchText)
        }, 1000);
    }

    function handleArchiveTableChange(type, { page, sizePerPage, searchText, }) {
        setTimeout(() => {
            setloadArchiveHouseChurch(false)
            setArchiveData([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/organization/?status=archive&page_size=${sizePerPage}&page=${new_page}`
            if (filterdata !== '') {
                url = url + `&${filterdata}`
            }
            window.scrollTo(0, 0)
            loadArchiveData(url, sizePerPage, new_page, searchText)
        }, 1000);
    }


    function HandleBulkOpertation(value) {
        let data = {}
        if (activeTab === "2") {
            data["action"] = value
            data["organizations"] = ids
        } else if (activeTab === "3") {
            data["action"] = value
            data["organizations"] = DisabledIds
        } else if (activeTab === '4') {
            data['action'] = value
            data['organizations'] = archiveIds
        }
        post(`${BASE_URL}/api/house_church/organizations-bulk-action/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } },
            { validateStatus: false }
        )
            .then(res => {
                if (res.status >= 400) {

                    toast.error("Somthing Went Wrong")
                }
                else {
                    if (activeTab === '2') {

                        let new_page = page
                        if (church.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/house_church/organization/?status=active&page_size=${sizePerPage}&page=${new_page}`
                        if (filter !== "") {
                            url = `${url}${filter}`
                        }
                        loadData(url, sizePerPage, new_page)
                        setIds([])
                        setChurch([])


                    } else if (activeTab === '3') {

                        let new_page = DisabledPage
                        if (DisabledUsers.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/house_church/organization/?status=inactive&page_size=${sizePerPage}&page=${new_page}`
                        if (filter !== "") {
                            url = `${url}${filter}`
                        }
                        loadDisabledData(url, sizePerPage, new_page)
                        setDisabledIds([])
                        setDisabledUsers([])


                    } else if (activeTab === '4') {
                        let new_page = archivepage
                        if (archiveData.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/house_church/organization/?status=archive&page_size=${sizePerPage}&page=${new_page}`
                        if (filter !== "") {
                            url = `${url}${filter}`
                        }
                        loadArchiveData(url, sizePerPage, new_page)
                        setArchiveIds([])
                        setArchiveData([])
                    }
                    if (value === "disable") {
                        loadDisabledData(`${BASE_URL}/api/house_church/organization/?status=inactive${filter}`, 20, 1)
                    } else if (value === "active") {
                        loadData(`${BASE_URL}/api/house_church/organization/?status=active${filter}`, 20, 1)
                    } else if (value === "archive") {
                        loadArchiveData(`${BASE_URL}/api/house_church/organization/?status=archive${filter}`, 20, 1)
                    }


                }
            }
            )

    }

    function handleOnSelect(row, isSelect) {
        let id = []
        if (isSelect) {
            id = ids
            id.push(row.id)
        } else {
            for (let i = 0; i < ids.length; i++) {
                if (ids[i] !== row.id) {
                    id.push(ids[i])
                }
            }

        }
        setIds(id)
    }


    function handleOnSelectAll(isSelect, rows) {
        if (isSelect) {
            let id = []
            for (let i = 0; i < rows.length; i++) {
                id.push(rows[i].id)
            }
            setIds(id)
        } else {
            setIds([])
        }
    }

    function handleOnSelectDisabledRow(row, isSelect) {
        let id = []
        if (isSelect) {
            id = DisabledIds
            id.push(row.id)
        } else {
            for (let i = 0; i < DisabledIds.length; i++) {
                if (DisabledIds[i] !== row.id) {
                    id.push(DisabledIds[i])
                }
            }

        }
        setDisabledIds(id)
    }

    function handleOnSelectArciveRow(row, isSelect) {
        let id = []
        if (isSelect) {
            id = archiveIds
            id.push(row.id)
        } else {
            for (let i = 0; i < archiveIds; i++) {
                if (archiveIds[i] !== row.id) {
                    id.push(archiveIds[i])
                }
            }
        }
        setArchiveIds(id)
    }
    function handleOnSelectAllArchiveRow(isSelect, rows) {
        if (isSelect) {
            let id = []
            for (let i = 0; i < rows.length; i++) {
                id.push(rows[i].id)
            }
            setArchiveIds(id)
        } else {
            setArchiveIds([])
        }

    }

    function handleOnSelectAllDisabledRow(isSelect, rows) {
        console.log(isSelect, 'DisableIsSelect')
        if (isSelect) {
            let id = []
            for (let i = 0; i < rows.length; i++) {
                id.push(rows[i].id)
                console.log(id, 'selectdisableid')
            }
            setDisabledIds(id)
        } else {
            setDisabledIds([])
        }
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };


    const selectRowforDisabled = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelectDisabledRow,
        onSelectAll: handleOnSelectAllDisabledRow
    };

    const selectRowForArchive = {
        mode: "checkbox",
        clickToSelect: true,
        onSelect: handleOnSelectArciveRow,
        onSelectAll: handleOnSelectAllArchiveRow

    };

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc',
        savestate: true
    }];


    //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }
    const ArchivePageOptions = {
        page: archivepage,
        sizePerPage: sizePerPage,
        totalSize: archiveTotalSize,
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]
    }
    const DisabledpageOptions = {
        page: DisabledPage,
        sizePerPage: sizePerPage,
        totalSize: DisabledTotalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]
    }
    function handleSelectGroup(selectedGroup) {

        setselectedGroup(selectedGroup)


    }

    function handleDisableSelectGroup(selectedDisableGroup) {
        setselectedDisableGroup(selectedDisableGroup)
    }
    function handleArchiveSelectGroup(selectedArchiveGroup) {
        setselectedArchiveGroup(selectedArchiveGroup)
    }
    function handleBulkEnable() {
        if (activeTab === '2') {
            if (selectedGroup.value === "inactive") {
                ids.length > 0 ? HandleBulkOpertation('inactive') : toast.error("Select Organization")

            } else if (selectedGroup.value === 'archive') {

                ids.length > 0 ? HandleBulkOpertation('archive') : toast.error("Select Organization")

            }
        }
    }
    function handleBulkDisable() {
        if (activeTab === '3') {
            if (selectedDisableGroup.value === 'active') {
                DisabledIds.length > 0 ? HandleBulkOpertation('active') : toast.error('Select Organization')
            } else if (selectedDisableGroup.value === 'archive') {
                DisabledIds.length > 0 ? HandleBulkOpertation('archive') : toast.error('Select Organization')
            } else if (selectedDisableGroup.value === 'delete') {
                DisabledIds.length > 0 ? HandleBulkOpertation('delete') : toast.error('Select Organization')
            }
        }
    }
    function handleBulkArchive() {
        if (activeTab === '4') {
            if (selectedArchiveGroup.value === 'active') {
                archiveIds.length > 0 ? HandleBulkOpertation('active') : toast.error('Select Organization')

            } else if (selectedArchiveGroup.value === 'inactive') {
                archiveIds.length > 0 ? HandleBulkOpertation('inactive') : toast.error('Select Organization')
            } else if (selectedArchiveGroup.value === 'delete') {
                archiveIds.length > 0 ? HandleBulkOpertation('delete') : toast.error('Select Organization')
            }
        }
    }

    function handleDeleteHouseChurch(id) {
        del(`${BASE_URL}/api/house_church/organization/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } },
            { validateStatus: false }
        )
            .then(res => {
                if (res.status >= 400) {

                }
                else {
                    if (activeTab === '3') {
                        let new_page = DisabledPage
                        if (DisabledUsers.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/house_church/organization/?status=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
                        if (filter !== "") {
                            url = `${url}${filter}`
                        }
                        loadDisabledData(url, sizePerPage, new_page)
                    } else {
                        let new_page = archivepage
                        if (archiveData.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/house_church/organization/?status=true&archive=true&page_size=${sizePerPage}&page=${new_page}`
                        if (filter !== "") {
                            url = `${url}${filter}`
                        }
                        loadArchiveData(url, sizePerPage, new_page)
                    }
                    setSuccess_confirm(true)
                    setAlert_confirm(false)
                    setDynamic_title('Deleted!')
                    setDynamic_description("House Church Has been Deleted.")
                    setSelected_id(null)
                }
            }
            )
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Dashboard" breadcrumbItem={"Organizations"} link="/dashboard" />

                <Col xs={12}>


                    <Card>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "2",
                                        })}
                                        onClick={() => {
                                            toggle("2")
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                        <span className="d-none d-sm-block">Active</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "3",
                                        })}
                                        onClick={() => {
                                            toggle("3")
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                        <span className="d-none d-sm-block">Inactive</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "4",
                                        })}
                                        onClick={() => {
                                            toggle("4")
                                        }}
                                    >
                                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                        <span className="d-none d-sm-block">Archive</span>
                                    </NavLink>
                                </NavItem>

                            </Nav>
                            <Row>


                            </Row>
                            <TabContent activeTab={activeTab} className="p-3 text-muted" >
                                <TabPane tabId="2">
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        data={church}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={church}
                                                columns={columns}
                                                remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                bootstrap5
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                                            <Row className="mb-2">
                                                                <Col sm='3'>
                                                                    <Select
                                                                        value={selectedGroup}
                                                                        onChange={(e) => {
                                                                            handleSelectGroup(e);
                                                                        }}
                                                                        options={EnableGroup}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </Col>
                                                                <Col sm='1'>
                                                                    <Button color='primary' className=' me-2' type='button' onClick={() => handleBulkEnable()}>Apply</Button>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <div className="search">
                                                                        <Select
                                                                            value={search_by}
                                                                            onChange={(e) => {
                                                                                setSearchby(e); // Here, we set the selected value as the search_by state
                                                                            }}
                                                                            options={optionGroup}
                                                                            classNamePrefix="select2-selection"
                                                                        />


                                                                    </div>


                                                                </Col>
                                                                <Col sm='5'>
                                                                </Col>

                                                                <Row>

                                                                    {search_by.value ?
                                                                        <Col sm="8" className="mt-3">
                                                                            <Row>
                                                                                <Col sm="5">
                                                                                    {search_by.value === 'created_at' ?
                                                                                        <Row>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="from_date"
                                                                                                        placeholder="Received From"
                                                                                                        type="date"
                                                                                                        errorMessage="From Date"
                                                                                                        className="form-control"
                                                                                                        value={fromDate}
                                                                                                        onChange={(e) => setFromDate(e.target.value)}
                                                                                                        id="from_date"
                                                                                                    />
                                                                                                </div>

                                                                                            </Col>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="to_date"
                                                                                                        placeholder="Category"
                                                                                                        type="date"
                                                                                                        errorMessage="To Date"
                                                                                                        className="form-control"
                                                                                                        value={toDate}
                                                                                                        onChange={(e) => setToDate(e.target.value)}
                                                                                                        id="to_date"
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>

                                                                                        </Row> :
                                                                                        search_by.value !== null ?
                                                                                            search_by.value === 'state' ?
                                                                                                <Row>
                                                                                                    <Col sm="12">
                                                                                                        <div className="text-sm-start mb-2">
                                                                                                            <Select
                                                                                                                value={selectedState}
                                                                                                                onChange={(e) => {
                                                                                                                    setselectedState(e)
                                                                                                                }}
                                                                                                                options={stateOptions}
                                                                                                                classNamePrefix="select2-selection"
                                                                                                            />
                                                                                                        </div>

                                                                                                    </Col>

                                                                                                </Row>
                                                                                                :
                                                                                                <Row>
                                                                                                    <Col sm="12">
                                                                                                        <div className="text-sm-end mb-2">
                                                                                                            <AvField
                                                                                                                name="search"
                                                                                                                placeholder={search_by.label}
                                                                                                                type="text"
                                                                                                                value={search}
                                                                                                                onChange={(e) => setSearch(e.target.value)}
                                                                                                                className="form-control"
                                                                                                                validate={{ required: { value: true } }}
                                                                                                            />
                                                                                                        </div>

                                                                                                    </Col>

                                                                                                </Row>

                                                                                            : null}

                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-start mb-2">
                                                                                        <Select
                                                                                            value={order_by}
                                                                                            onChange={(e) => {
                                                                                                setOrder_by(e)
                                                                                            }}
                                                                                            options={sortOptionGroup}
                                                                                            classNamePrefix="select2-selection"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="4">
                                                                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                        Filter
                                                                                    </Button>
                                                                                    {" "}
                                                                                    <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                                                                        Clear
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        : null}
                                                                </Row>


                                                            </Row >

                                                        </AvForm>
                                                        <div className="table-responsive">

                                                            <BootstrapTable
                                                                remote
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                                responsive
                                                                bordered={false}
                                                                striped={true}
                                                                defaultSorted={defaultSorted}
                                                                hover
                                                                classes={
                                                                    "table align-middle table-nowrap table-check"
                                                                }
                                                                headerWrapperClasses={"table-light"}
                                                                onTableChange={handleTableChange}
                                                                noDataIndication={() => NoDataIndication()}
                                                                selectRow={selectRow}
                                                            />

                                                        </div>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <div className="mb-3">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="pagination pagination-rounded mb-2">
                                                                    <PaginationTotalStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                            <Col sm={6}>
                                                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>


                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </TabPane>
                                <TabPane tabId="3" >
                                    <PaginationProvider
                                        pagination={paginationFactory(DisabledpageOptions)}
                                        data={DisabledUsers}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={DisabledUsers}
                                                columns={columns}
                                                remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                bootstrap5
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>

                                                        <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                                            <Row className="mb-2">
                                                                <Col sm='3'>
                                                                    <Select
                                                                        value={selectedDisableGroup}
                                                                        onChange={(e) => {
                                                                            handleDisableSelectGroup(e);
                                                                        }}
                                                                        options={DisableGroup}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </Col>
                                                                <Col sm='1'>
                                                                    <Button color='primary' className='me-2' type='button' onClick={() => handleBulkDisable()}>Apply</Button>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <div className="text-sm-start mb-2">
                                                                        <Select
                                                                            value={search_by}
                                                                            onChange={(e) => {
                                                                                setSearchby(e)
                                                                            }}
                                                                            options={optionGroup}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>


                                                                </Col>
                                                                {search_by.value ?
                                                                    <Col sm="8" className="mt-3">
                                                                        <Row>
                                                                            <Col sm="5">
                                                                                {search_by.value === 'created_at' ?
                                                                                    <Row>
                                                                                        <Col sm="6">
                                                                                            <div className="text-sm-end mb-2">
                                                                                                <AvField
                                                                                                    name="from_date"
                                                                                                    placeholder="Received From"
                                                                                                    type="date"
                                                                                                    errorMessage="From Date"
                                                                                                    className="form-control"
                                                                                                    value={fromDate}
                                                                                                    onChange={(e) => setFromDate(e.target.value)}
                                                                                                    id="from_date"
                                                                                                />
                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col sm="6">
                                                                                            <div className="text-sm-end mb-2">
                                                                                                <AvField
                                                                                                    name="to_date"
                                                                                                    placeholder="Category"
                                                                                                    type="date"
                                                                                                    errorMessage="To Date"
                                                                                                    className="form-control"
                                                                                                    value={toDate}
                                                                                                    onChange={(e) => setToDate(e.target.value)}
                                                                                                    id="to_date"
                                                                                                />
                                                                                            </div>
                                                                                        </Col>

                                                                                    </Row> :
                                                                                    search_by.value !== null ?
                                                                                        search_by.value === 'state' ?
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="text-sm-start mb-2">
                                                                                                        <Select
                                                                                                            value={selectedState}
                                                                                                            onChange={(e) => {
                                                                                                                setselectedState(e)
                                                                                                            }}
                                                                                                            options={stateOptions}
                                                                                                            classNamePrefix="select2-selection"
                                                                                                        />
                                                                                                    </div>

                                                                                                </Col>

                                                                                            </Row>
                                                                                            :
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="text-sm-end mb-2">
                                                                                                        <AvField
                                                                                                            name="search"
                                                                                                            placeholder={search_by.label}
                                                                                                            type="text"
                                                                                                            value={search}
                                                                                                            onChange={(e) => setSearch(e.target.value)}
                                                                                                            className="form-control"
                                                                                                            validate={{ required: { value: true } }}
                                                                                                        />
                                                                                                    </div>

                                                                                                </Col>

                                                                                            </Row>

                                                                                        : null}

                                                                            </Col>
                                                                            <Col sm="3">
                                                                                <div className="text-sm-start mb-2">
                                                                                    <Select
                                                                                        value={order_by}
                                                                                        onChange={(e) => {
                                                                                            setOrder_by(e)
                                                                                        }}
                                                                                        options={sortOptionGroup}
                                                                                        classNamePrefix="select2-selection"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col sm="4">
                                                                                <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                    Filter
                                                                                </Button>
                                                                                {" "}
                                                                                <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                                                                    Clear
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    : null}

                                                            </Row>

                                                        </AvForm>
                                                        {/* <Col lg='3'>
                                  <div className="position-relative">
                                  <Button type='button' color='primary'>Submit</Button>

                                  </div>
                                </Col> */}
                                                        <div className="table-responsive">

                                                            <BootstrapTable
                                                                remote
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                                responsive
                                                                bordered={false}
                                                                striped={true}
                                                                defaultSorted={defaultSorted}
                                                                hover
                                                                classes={
                                                                    "table align-middle table-nowrap table-check"
                                                                }
                                                                headerWrapperClasses={"table-light"}
                                                                onTableChange={handleDisableTableChange}
                                                                noDataIndication={() => DisabledNoDataIndication()}
                                                                selectRow={selectRowforDisabled}

                                                            />

                                                        </div>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <div className="mb-3">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}

                                                                    />
                                                                </div>
                                                                <div className="pagination pagination-rounded mb-2">
                                                                    <PaginationTotalStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                            <Col sm={6}>
                                                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>


                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>

                                </TabPane>
                                <TabPane tabId="4">

                                    <PaginationProvider
                                        pagination={paginationFactory(ArchivePageOptions)}
                                        data={archiveData}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={archiveData}
                                                columns={columns}
                                                remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                bootstrap5
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>

                                                        <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                                            <Row className="mb-2">
                                                                <Col sm='3'>
                                                                    <Select
                                                                        value={selectedArchiveGroup}
                                                                        onChange={(e) => {
                                                                            handleArchiveSelectGroup(e);
                                                                        }}
                                                                        options={ArchiveGroup}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </Col>
                                                                <Col sm='1'>
                                                                    <Button color='primary' className="me-2" type='button' onClick={() => handleBulkArchive()}>Apply</Button>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <div className="text-sm-start mb-2">
                                                                        <Select
                                                                            value={search_by}
                                                                            onChange={(e) => {
                                                                                setSearchby(e)
                                                                            }}
                                                                            options={optionGroup}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>


                                                                </Col>
                                                                {search_by.value ?
                                                                    <Col sm="8" className="mt-3">
                                                                        <Row>
                                                                            <Col sm="5">
                                                                                {search_by.value === 'created_at' ?
                                                                                    <Row>
                                                                                        <Col sm="6">
                                                                                            <div className="text-sm-end mb-2">
                                                                                                <AvField
                                                                                                    name="from_date"
                                                                                                    placeholder="Received From"
                                                                                                    type="date"
                                                                                                    errorMessage="From Date"
                                                                                                    className="form-control"
                                                                                                    value={fromDate}
                                                                                                    onChange={(e) => setFromDate(e.target.value)}
                                                                                                    id="from_date"
                                                                                                />
                                                                                            </div>

                                                                                        </Col>
                                                                                        <Col sm="6">
                                                                                            <div className="text-sm-end mb-2">
                                                                                                <AvField
                                                                                                    name="to_date"
                                                                                                    placeholder="Category"
                                                                                                    type="date"
                                                                                                    errorMessage="To Date"
                                                                                                    className="form-control"
                                                                                                    value={toDate}
                                                                                                    onChange={(e) => setToDate(e.target.value)}
                                                                                                    id="to_date"
                                                                                                />
                                                                                            </div>
                                                                                        </Col>

                                                                                    </Row> :
                                                                                    search_by.value !== null ?
                                                                                        search_by.value === 'state' ?
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="text-sm-start mb-2">
                                                                                                        <Select
                                                                                                            value={selectedState}
                                                                                                            onChange={(e) => {
                                                                                                                setselectedState(e)
                                                                                                            }}
                                                                                                            options={stateOptions}
                                                                                                            classNamePrefix="select2-selection"
                                                                                                        />
                                                                                                    </div>

                                                                                                </Col>

                                                                                            </Row>
                                                                                            :
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="text-sm-end mb-2">
                                                                                                        <AvField
                                                                                                            name="search"
                                                                                                            placeholder={search_by.label}
                                                                                                            type="text"
                                                                                                            value={search}
                                                                                                            onChange={(e) => setSearch(e.target.value)}
                                                                                                            className="form-control"
                                                                                                            validate={{ required: { value: true } }}
                                                                                                        />
                                                                                                    </div>

                                                                                                </Col>

                                                                                            </Row>

                                                                                        : null}

                                                                            </Col>
                                                                            <Col sm="3">
                                                                                <div className="text-sm-start mb-2">
                                                                                    <Select
                                                                                        value={order_by}
                                                                                        onChange={(e) => {
                                                                                            setOrder_by(e)
                                                                                        }}
                                                                                        options={sortOptionGroup}
                                                                                        classNamePrefix="select2-selection"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col sm="4">
                                                                                <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                    Filter
                                                                                </Button>
                                                                                {" "}
                                                                                <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                                                                    Clear
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    : null}

                                                            </Row>

                                                        </AvForm>
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                remote
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                                responsive
                                                                bordered={false}
                                                                striped={true}
                                                                defaultSorted={defaultSorted}
                                                                hover
                                                                classes={
                                                                    "table align-middle table-nowrap table-check"
                                                                }
                                                                headerWrapperClasses={"table-light"}
                                                                onTableChange={handleArchiveTableChange}
                                                                noDataIndication={() => ArchiveDataIndication()}
                                                                selectRow={selectRowForArchive}

                                                            />
                                                        </div>

                                                        <Row>
                                                            <Col sm={6}>
                                                                <div className="mb-3">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}

                                                                    />
                                                                </div>
                                                                <div className="pagination pagination-rounded mb-2">
                                                                    <PaginationTotalStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                            <Col sm={6}>
                                                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>

                                                            </Col>
                                                        </Row>


                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>

                                </TabPane>
                            </TabContent>

                        </CardBody>
                    </Card>

                </Col>

                {alert_confirm ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => handleDeleteHouseChurch(selected_id)}
                        onCancel={() => {
                            setAlert_confirm(false)
                            setSelected_id(null)
                        }
                        }
                    >
                        You won't be able to revert this!
                    </SweetAlert>
                ) : null}

                {success_confirm ? (
                    <SweetAlert
                        success
                        title={dynamic_title}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setSuccess_confirm(false)
                            setAlert_confirm(false)
                        }
                        }
                    >
                        {dynamic_description}
                    </SweetAlert>
                )
                    : null
                }


            </div>

            <Col sm={6} md={4} xl={3}>
                <Modal
                    isOpen={modal_standard}
                    toggle={() => {
                        tog_standard()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">{`Add Location`} </h5>

                        <button
                            type="button"
                            onClick={() => {
                                setmodal_standard(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>


                </Modal>
            </Col>

        </React.Fragment>
    );
}
export default Organizations