import React from "react"
import { Redirect } from "react-router-dom"

import Dashboard from "../pages/Dashboard/index"

import PagesMaintenance from "../pages/Utility/pages-maintenance"
// import PagesComingsoon from "../pages/Utility/pages-comingsoon"

import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePassword from "../pages/Authentication/ChangePassword"

import LockScreen from "../pages/Authentication/LockScreen"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

import Users from "../pages/Users/Users"
import DummyUsers from "../pages/Users/DummyUsers"
import NewUser from "../pages/Users/NewUser"
import EditUser from "../pages/Users/EditUser"
import Contact from '../pages/Contacts/Contact'
import AllChurch from "../pages/ManageChurch/AllChurch"
import ChurchesToCall from "../pages/ManageChurch/ChurchesToCall"
import ClaimedChurches from "../pages/ManageChurch/ClaimedChurches"
import EditAllChurch from "../pages/ManageChurch/EditAllChurch"

import Denomination from "../pages/ManageDenomination/Denomination"
import CreateDenomination from "../pages/ManageDenomination/CreateDenomination"
import UpdateDenomination from "../pages/ManageDenomination/UpdateDenomination"

import CreateHouse from "../pages/manageallchurch/CreateHouse"
import ManageHouseChurch from '../pages/manageallchurch/ManageHouseChurch'
import EditManageHouseChurch from "../pages/manageallchurch/EditManageHouseChurch"
import DummyHouseChurch from "../pages/manageallchurch/DummyHouseChurch"

import EditEvent from '../pages/manageallchurch/EditEvent'

import CreateChurch from "../pages/ManageChurch/CreateChurch"
// import CreateEvent from "../pages/manageallchurch/CreateEvent"
import UserDetail from "../pages/Users/UserDetail"

import FlaggedHouseChurches from "../pages/ violations/FlaggedHouseChurches"
import FlaggedProfile from "../pages/ violations/FlaggedProfile"
import FlaggedEventPosts from "../pages/ violations/FlaggedEventPosts"
import FlaggedHouseChurchPosts from "../pages/ violations/FlaggedHouseChurchPosts"
import FlaggedEventComments from "../pages/ violations/FlaggedEventComments"
import FlaggedHouseChurchComments from "../pages/ violations/FlaggedHouseChurchComments"

import State from "../pages/Config/State/State"
import County from "../pages/Config/County/County"
import Location from "../pages/Config/Location/Location"
import Page from "../pages/locationpage/Page"
import EditPage from "../pages/locationpage/EditPage"
import Badwords from "../pages/Config/BadWords/Badwords"

import Notifications from "../pages/Config/Notifications"

import Organizations from "../pages/Organization"
import EditOrganization from "../pages/Organization/EditOrganization"

import TermsCondition from "../pages/Config/TermsCondition"
import PrivacyPolicy from "../pages/Config/PrivacyPolicy"
import EmailTemplate from "../pages/Config/EmailTemplate"
import ManageTemplate from "../pages/Config/EmailTemplate/manageTemplate"

import UrlRedirects from "../pages/Config/Redirects"

import CustomEmails from "../pages/Config/CustomEmails"



import LocationPageListing from "../pages/Config/PageContent/LocationPageListing"
import LocationPageContent from "../pages/Config/PageContent/LocationPageContent"

const userRoutes = [
  
  { path: "/dashboard", component: Dashboard },
  
  { path: "/profile", component: UserProfile },

  
  { path: "/users", component: Users }, 
  { path: "/dummy-users", component: DummyUsers }, 
  { path: "/new-user", component: NewUser }, 
  { path: "/edit-user-profile/:id", component: EditUser }, 
  {path:"/user-detail/:id",component:UserDetail},

  {path:'/contact', component:Contact},

  {path:'/all-churches', component:AllChurch},
  {path:'/edit-church/:id',component:EditAllChurch},

  {path:'/church-to-calls',component:ChurchesToCall},
  {path:'/create-church',component:CreateChurch},

  {path:'/organizations',component:Organizations},
  {path:'/organization/:id',component:EditOrganization},

  


  {path:'/claimed-churches',component:ClaimedChurches},

  {path:'/house-churches', component:ManageHouseChurch},
  {path:'/dummy-house-churches', component:DummyHouseChurch},
  {path:'/edit-house-church/:id',component:EditManageHouseChurch},
  {path:'/create-house-church',component:CreateHouse},

  {path:'/edit-event/:id',component:EditEvent},

  {path:'/denominations',component:Denomination},
  {path:'/create-denomination',component:CreateDenomination},
  {path:'/update-denomination/:id',component:UpdateDenomination},
  
  {path:'/flagged-house-church',component:FlaggedHouseChurches},
  {path:'/flagged-profile',component:FlaggedProfile},
  {path:'/flagged-event-posts',component:FlaggedEventPosts},
  {path:'/flagged-house-churh-posts',component:FlaggedHouseChurchPosts},
  {path:'/flagged-event-posts-comment',component:FlaggedEventComments},
  {path:'/flagged-house-churh-posts-comment',component:FlaggedHouseChurchComments},
  

  {path:'/state',component:State},
  {path:'/county',component:County},
  {path:'/location',component:Location},
  {path:'/bad-words',component:Badwords},

  {path:'/notifications',component:Notifications},

  {path:'/terms-conditions',component:TermsCondition},
  {path:'/privacy-policy',component:PrivacyPolicy},
  {path:'/email-template',component:EmailTemplate},
  {path:'/create-template',component:ManageTemplate},
  {path:'/update-template/:id',component:ManageTemplate},
 
  {path:'/pages',component:Page},
  {path:'/edit-location-page/:id',component:EditPage},

  {path:'/page-contents',component:LocationPageListing},
  {path:'/update-page-content/:id',component:LocationPageContent},

  {path:'/url-redirects',component:UrlRedirects},

  {path:'/custom-email',component:CustomEmails},

  
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/password-reset/:token", component: ChangePassword },
  
  { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

    // // Authentication Inner
    { path: "/auth-lock-screen", component: LockScreen },
    //contact

]

export { userRoutes, authRoutes }