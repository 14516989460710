export function toTitleCase(str) {
    let splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    str = splitStr.join(' ')
    let resplitStr = str.split('-');
    for (var j = 0; j < resplitStr.length; j++) {
        resplitStr[j] = resplitStr[j].charAt(0).toUpperCase() + resplitStr[j].substring(1);     
    }
    return resplitStr.join('-');
 }


export function HeaderFormatter(column, colIndex) {
    return (
      <strong>{ column.text }</strong>
    );
  }