import React, {useEffect, useState} from "react"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

import { Container, Row, Col, CardBody, Card, Button, Alert } from "reactstrap"
import { BASE_URL, SITE_URL } from "../../helpers/url_helper"

// import images
import logo from "../../assets/images/logo-sm-dark.png"

const LockScreen = (props) => {
  const [email,setEmail]=useState('Admin')
  const [error, setErrors] = useState("")
  const [img,setImg]=useState(null)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(obj.user.email,'email')
        setEmail(obj.user.email)
        setImg(obj.user.image)

    }else{
      props.history.push('/login')
    }
  }, [props.history])

  const handleSubmit = (event, values)=> {
    let data =  JSON.stringify({'email':email, 'password': values.password})
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data
    };   
    try{
        fetch(`${BASE_URL}/api/accounts/login/`,requestOptions)
        .then(res => 
            {if(res.status >= 400){
              console.log(res,'wrongresponse')
                res.json()
                .then(
                    (data) =>{ setErrors(data.detail) }
                )
            }
            else{
                res.json()
               
                .then(
                    (data) =>{
                    localStorage.setItem("authUser", JSON.stringify(data));
                    localStorage.setItem("lockScreen", false);
                    props.history.push('/dashboard');
                    }
                )
            }    
        }
    )

    }catch(error){
        setErrors("somthing Went Wrong");
    }
  }
  
  


  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Lock screen</h5>
                    <p className="text-white-50 mb-0">Enter your password to unlock the screen!</p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </Link>
                  </div>
                </div>
                <CardBody className="pt-5">

                  <div className="p-2">
                    <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleSubmit(e, v) }}>
                      <div className="user-thumb text-center mb-4">
                        <img src={img}
                          className="rounded-circle img-thumbnail avatar-md" alt="thumbnail" />
                        <h5 className="font-size-15 mt-3">{email}</h5>
                      </div>
                      {error !== "" ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="row mb-0">
                        <div className="col-12 text-end">
                          <Button
                            color="primary"
                            className="w-md waves-effect waves-light"
                            type="submit"
                          >
                            Unlock
                            </Button>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                
                <p>© {'2024'} <a target="_blank" rel="noreferrer" href={SITE_URL}>{'findahousechurch.com'}</a> </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default LockScreen