import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";

const Breadcrumbs = ({ title, breadcrumbItem, link }) => {
  return (
    <div>
      <h1>{title}</h1>
      {/* Use the link prop as the function to go back */}
      <button onClick={link}>Go Back</button>
      <p>{breadcrumbItem}</p>
    </div>
  );
};

const YourComponent = () => {
  const history = useHistory();

  const previouspage = () => {
    history.goBack();
  };

  return (
    <div style={{'marginTop':'200px'}}>
      {/* Pass the previouspage function as the link prop */}
      <Breadcrumbs title="User" breadcrumbItem="User Detail" link={previouspage} />
      {/* Rest of your component's content */}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Route path="/" component={YourComponent} />
    </Router>
  );
};

export default App;
