import React, { useEffect, useState } from "react"
import Breadcrumbs from '../../components/Common/Breadcrumb'


import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { get, del } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"
import moment from "moment-timezone";
import not_avail from "../../assets/images/not_avail.jpg"

const DummyHouseChurch = (props) => {
    const [church, setChurch] = useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [page, setpage] = useState(1)
    const [sizePerPage, setsizePerPage] = useState(20)
    const [loadHouseChurch, setloadHouseChurch] = useState(false)

    const [selected_id, setSelected_id] = useState(null)
    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")

    ValidateUser(props)

    const columns = [
        {
            dataField: "id",
            text: "S No.",
            hidden: true

        },
        {
            dataField: "image",
            text: 'Icon',
            headerFormatter: HeaderFormatter,
            formatter: (cellContent) => (
                <img src={cellContent ? cellContent : not_avail} className="rounded avatar-md" alt="" />
            ),
        },
        {
            dataField: "user_id",
            text: "Owner",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{row.user_id ? row.user_id.first_name + " " + row.user_id.last_name : null}</Link>
            ),
        },


        {
            dataField: "name",
            text: "Name",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (

                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{cellContent}</Link>
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "user_id.phone",
            text: "Phone",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{cellContent}</Link>
            )
            ,
        },
        {
            dataField: "user_id.email",
            text: "Email",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                <Link to={{ pathname: `/edit-house-church/${row.id}`, statedata: { 'url': '/house-churches', 'page': 'House Church' } }} className="me-3 text-dark">{cellContent}</Link>
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "created_at",
            text: "Created",
            headerFormatter: HeaderFormatter,
            // sort: true,
            formatter: (cellContent, row) => (
                moment(cellContent).tz("America/Chicago").format("MM-DD-YYYY")
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "site",
            isDummyField: true,
            headerFormatter: HeaderFormatter,
            text: "site",
            formatter: (cellContent, row) => (
                <React.Fragment>
                    <a href={`https://findahousechurch.com/house-church/${row.slug}`} target="__blank" className="me-3 text-primary" >  <i className="mdi mdi-search-web"></i> {cellContent}</a>
                    <Link to="#" onClick={()=> {setAlert_confirm(true); setSelected_id(row.id)}}  className="me-3 text-danger"><i className="mdi mdi-trash-can font-size-18"></i>{cellContent}</Link>
                </React.Fragment>

            ),
        },
    ]

    useEffect(() => {

        loadData(`${BASE_URL}/api/house_church/dash-list-house_church/?is_dummy=true`, 20, 1)

        return () => {
            setChurch([])
        }

    }, []);


    function loadData(url, sizePerPage, page) {
        setloadHouseChurch(false)
        let data = get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } })
        data.then(response => {
            setChurch(response.data.results)
            settotalSize(response.data.count)
            setpage(page)
            setsizePerPage(sizePerPage)
            setloadHouseChurch(true)
        })
    }

    function NoDataIndication() {
        if (loadHouseChurch && church.length === 0) {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )

        } else {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
            )

        }
    }

    function handleTableChange(type, { page, sizePerPage, searchText, }) {

        setTimeout(() => {
            setloadHouseChurch(false)
            setChurch([])
            let new_page = page
            let url = `${BASE_URL}/api/house_church/dash-list-house_church/?is_dummy=true&page_size=${sizePerPage}&page=${new_page}`


            window.scrollTo(0, 0)
            loadData(url, sizePerPage, new_page)


        }, 1000);
    }

    const defaultSorted = [{
        dataField: 'f_name',
        order: 'desc',
        savestate: true
    }];


    //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]

    }

    function handleDeleteHouseChurch(id) {
        del(`${BASE_URL}/api/house_church/dash-list-house_church/${id}/`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() } },
            { validateStatus: false }
        )
            .then(res => {
                if (res.status >= 400) {

                }
                else {

                    let new_page = page
                    if (page.length < 2) {
                        new_page = new_page > 1 ? new_page - 1 : 1
                    }
                    let url = `${BASE_URL}/api/house_church/dash-list-house_church/?is_dummy=true&page_size=${sizePerPage}&page=${new_page}`
                    loadData(url, sizePerPage, new_page)



                    setSuccess_confirm(true)
                    setAlert_confirm(false)
                    setDynamic_title('Deleted!')
                    setDynamic_description("House Church Has been Deleted.")
                    setSelected_id(null)
                }
            }
            )
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Dashboard" breadcrumbItem={"Dummy House Churches"} link="/dashboard" />

                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                data={church}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        data={church}
                                        columns={columns}
                                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                        bootstrap5
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <div className="table-responsive">

                                                    <BootstrapTable
                                                        remote
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                        responsive
                                                        bordered={false}
                                                        striped={true}
                                                        defaultSorted={defaultSorted}
                                                        hover
                                                        classes={
                                                            "table align-middle table-nowrap table-check"
                                                        }
                                                        headerWrapperClasses={"table-light"}
                                                        onTableChange={handleTableChange}
                                                        noDataIndication={() => NoDataIndication()}

                                                    />

                                                </div>
                                                <Row>
                                                    <Col sm={6}>
                                                        <div className="mb-3">
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="pagination pagination-rounded mb-2">
                                                            <PaginationTotalStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>

                                                    </Col>
                                                    <Col sm={6}>
                                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>

                                                    </Col>
                                                </Row>


                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>

                        </CardBody>
                    </Card>

                </Col>

                {alert_confirm ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => handleDeleteHouseChurch(selected_id)}
                        onCancel={() => {
                            setAlert_confirm(false)
                            setSelected_id(null)
                        }
                        }
                    >
                        You won't be able to revert this!
                    </SweetAlert>
                ) : null}

                {success_confirm ? (
                    <SweetAlert
                        success
                        title={dynamic_title}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setSuccess_confirm(false)
                            setAlert_confirm(false)
                        }
                        }
                    >
                        {dynamic_description}
                    </SweetAlert>
                )
                    : null
                }


            </div>

        </React.Fragment>
    );
}
export default DummyHouseChurch