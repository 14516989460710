import React, { useEffect, useState } from "react"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Row, Col, Card, CardBody, Spinner, TabPane,TabContent, Button, Modal} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { toast } from "react-toastify";
import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import moment from "moment-timezone";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"


const optionGroup = [
    {
      label: "Search by",
      options: [
        { label: "Name", value: "name" },
        { label: "Poc Name", value: "poc" },
        { label: "Poc Email", value: "poc_email" },
      ],
    },
  ];
  

  const sortOptionGroup = [
    {
      label: "Order by",
      options: [
        { label: "Asc", value: "" },
        { label: "Desc", value: "-" },
      ],
    },
  ];
const AllChurch = (props) => {
    const [activeTab, setactiveTab] = useState("2")
    const [claimedChurch,setClaimedChurch]=useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [page, setpage] = useState(1)
    const [sizePerPage, setsizePerPage] = useState(20)
    const [loaddata, setLoaData] = useState(false)
    const [ids, setIds] = useState([])
    const [modal_standard, setmodal_standard] = useState(false)
    const [search, setSearch] = useState("")
    const [search_by, setSearchby] = useState({ label: "Search By", value: null });
    const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))
    const [filterdata,setFilterData]=useState("")
    ValidateUser(props)


  

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
        setactiveTab()
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const columns = [
        {
        dataField: "id",
        text: "S No.",
        
        },
        
        {
        dataField: "name",
        text: " name",
        sort: true,
        headerFormatter: HeaderFormatter,
        formatter: (cellContent, row) => (
            
            <Link to={{ pathname: `/editchurch/${row.id}`, }} className="me-3 text-dark">{cellContent}</Link>        
        ),
        title: (cell) => `${cell}`
        },
        {
            dataField: "poc",
            text: "Poc Name",
            headerFormatter: HeaderFormatter,
            sort: true,
            formatter: (cellContent, row) => (
            <Link to={{ pathname: `/edit-church/${row.id}`,  }} className="me-3 text-dark">{cellContent}</Link>     
            ),
        },
        {
          dataField: "poc_email",
          text: "Poc Email",
          headerFormatter: HeaderFormatter,
          sort: true,
          formatter: (cellContent, row) => (
          <Link to={{ pathname: `/edit-church/${row.id}`,  }} className="me-3 text-dark">{cellContent}</Link>     
          ),

        },
        {
          dataField: "poc_phone",
          text: "Poc Phone",
          headerFormatter: HeaderFormatter,
          sort: true,
          formatter: (cellContent, row) => (
          <Link to={{ pathname: `/edit-church/${row.id}`,  }} className="me-3 text-dark">{cellContent}</Link>     
          ),
        },
  
   
    ]




    useEffect(() => {
        loadData(`${BASE_URL}/api/house_church/dash_list_churches/?claimed=1`, 20, 1)
      
      
        
      }, []);
    

    function loadData(url , sizePerPage, page ){
        let data = get(url, { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }} )
        data.then(response => {
       
        // setClaimedChurch(response.data.results)
        setClaimedChurch(response.data.results)
        settotalSize(response.data.count)
        setpage(page)
        setsizePerPage(sizePerPage)
        setLoaData(true)
        })
    }
 



    function ClearFilter(){
        setFilterData("")
        setSearch("")
        setSearchby({ label: "Search By", value: null });
        setFromDate(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
        setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))

        loadData(`${BASE_URL}/api/house_church/dash_list_churches/?claimed=1`, 20, 1)
    

    }
   

    function FilterData(e, values){
        let filter_data = ""
        if(!search_by.value){
        toast.error("Select Search Field")
        }else{
        if(search_by.value === "created"){
            filter_data = `&from_date=${fromDate}&to_date=${toDate}`
        }else{
            filter_data = `&${search_by.value}=${values.search}`
        }
        filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
        

        loadData(`${BASE_URL}/api/house_church/dash_list_churches/?claimed=1${filter_data}`, 20, 1)
        setFilterData(filter_data)
      


        }
    }
    
    function NoDataIndication(){
        if (loaddata && claimedChurch.length === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
        )

        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
        )
            
        } 
    }

    

    function handleTableChange (type, { page, sizePerPage, searchText, }) {
        
        setTimeout(() => {
        setLoaData(false)
        setClaimedChurch([])
        let new_page = page
        let url = `${BASE_URL}/api/house_church/dash_list_churches/?claimed=1&page_size=${sizePerPage}&page=${new_page}`
        if (filterdata!==''){
          url=url+`&${filterdata}`
        }
       
        window.scrollTo(0, 0)
        loadData(url , sizePerPage, new_page , searchText)
        

        }, 1000);
    }
   
    
    function handleOnSelect(row, isSelect) {
        let id = []
        if (isSelect){
        id = ids
        id.push(row.id)
        }else{
        for(let i=0; i< ids.length; i++){
            if (ids[i] !== row.id){
            id.push(ids[i])
            }
        }

        }
        setIds(id)
    }

    function handleOnSelectAll(isSelect, rows) {
        console.log(isSelect,'ClaimedChurchisSelect')
        if (isSelect) {
        let id = []
        for(let i=0; i< rows.length; i++){
        id.push(rows[i].id)
        }
        setIds(id)
        }else{
        setIds([])
        }
    }


 
    
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };


  
    
    const defaultSorted = [{
        dataField: 'f_name',
        order: 'desc',
        savestate: true
        }];

        //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [ {
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        } ]
        
    }

   

    return (
        <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Claimed Church' link="/dashboard" />
        <Col xs={12}>
            <Card>
              <CardBody>

                <TabContent activeTab={activeTab}  className="p-3 text-muted">
                    <TabPane tabId="2">
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        data={claimedChurch}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={claimedChurch}
                            columns={columns}
                            remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                            bootstrap5
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                  <Row className="mb-2">
                                    <Col sm="3">
                                      <div className="text-sm-start mb-2">
                                          <Select
                                          value={search_by}
                                          onChange={(e) => {
                                              setSearchby(e)
                                          }}
                                          options={optionGroup}
                                          classNamePrefix="select2-selection"
                                        />
                                      </div>
                                      
                                      
                                    </Col>
                                    {search_by.value?
                                    <Col sm="9">
                                      <Row>
                                        <Col sm="5">
                                          {search_by.value === "created"?
                                          <Row>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="from_date"
                                                  placeholder="Received From"
                                                  type="date"
                                                  errorMessage="From Date"
                                                  className="form-control"
                                                  value={fromDate}
                                                  onChange={(e)=> setFromDate(e.target.value)}
                                                  id="from_date"
                                                />
                                              </div>
                                            
                                            </Col>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                              <AvField
                                                  name="to_date"
                                                  placeholder="Category"
                                                  type="date"
                                                  errorMessage="To Date"
                                                  className="form-control"
                                                  value={toDate}
                                                  onChange={(e)=> setToDate(e.target.value)}
                                                  id="to_date"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          :search_by.value !== null?
                                          <Row>
                                            <Col sm="12">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="search"
                                                  placeholder={search_by.label}
                                                  type="text"
                                                  value={search}
                                                  onChange={(e)=> setSearch(e.target.value)}
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                />
                                              </div>
                                            
                                            </Col>
                                            
                                          </Row>

                                          : null}
                                          
                                        </Col>
                                        <Col sm="3">
                                              <div className="text-sm-start mb-2">
                                                  <Select
                                                  value={order_by}
                                                  onChange={(e) => {
                                                      setOrder_by(e)
                                                  }}
                                                  options={sortOptionGroup}
                                                  classNamePrefix="select2-selection"
                                                />
                                              </div>
                                        </Col>
                                        <Col sm="4">
                                              <Button type="submit" color="success" className="btn-rounded me-2" >
                                                Filter
                                              </Button>
                                              {" "}
                                              <Button type="button" color="primary" onClick={()=>ClearFilter()} className="btn-rounded" >
                                                Clear
                                              </Button>
                                        </Col>
                                      </Row>  
                                    </Col>
                                    :null}
                                   
                                    
                                    
                                  </Row >
                                </AvForm>
                        
                                <Row className="my-2">

                                 
                                  <Col sm="6" >
      
                                      {/* <Button type="button" color="primary" onClick={()=>{ids.length>0?MovetoDisabled('approved'):toast.error("Select Users")}} className="btn-rounded me-2" >
                                        Disabled
                                      </Button> */}
                                  </Col>
                                </Row>
                                
                             

                                
                                <div className="table-responsive">

                                  <BootstrapTable
                                    remote
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    bordered={false}
                                    striped={true}
                                    defaultSorted={defaultSorted}
                                    hover
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    onTableChange={ handleTableChange }
                                    noDataIndication={() => NoDataIndication()}
                                    selectRow={ selectRow }
                                  />
                                  
                                </div>
                                <Row>
                                  <Col sm={6}>
                                  <div className="mb-3">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps} 
                                    />
                                  </div>
                                    <div className="pagination pagination-rounded mb-2">
                                      <PaginationTotalStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    
                                  </Col>
                                  <Col sm={6}>
                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    
                                  </Col>
                                </Row>
                                
                                
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </TabPane>
                  
                </TabContent>
              </CardBody>
            </Card>
          </Col>
       
      </div>
      <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{`Add Location`} </h5>     

            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          

        </Modal>
      </Col>

    </React.Fragment>
    );
}
export default AllChurch
