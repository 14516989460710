import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  UncontrolledAlert,
  CardBody,
  Button,
  Label,
  Modal

} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import { BASE_URL } from "../../helpers/url_helper";
import {put } from "../../helpers/api_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { toast } from 'react-toastify';
import not_avail from "../../assets/images/not_avail.jpg"

// import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

// actions
const UserType = [
  {
    label: "Type",
    options: [
      { label: "Super Admin", value: 0 },
      { label: "Sub Admin", value: 1 },
    ],
  },
];

const UserProfile = props => {
  const [user, setUser] = useState(null)
  const [alert, setAlert] = useState({ "error": false, "success": false, "error_msg": "", "success_msg": "" })
  const [userType, setUserType] = useState()
  const [modal_standard, setmodal_standard] = useState(false)
  const [submitting, setSubmitting] = useState(null)
  const [img, setImg] = useState(null)
  // const [email,setEmail]=useState(null)
  


  useEffect(() => {
    ValidateUser(props)
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      obj.user.role === 0 ? setUserType({ label: "Super Admin", value: 0 }) : setUserType({ label: "Sub Admin", value: 1 })
      setUser(obj.user)
      console.log(obj.user.image,'userdata')
      setImg(obj.user.image)
      // setEmail(obj.user.email)
    
    }

  }, [props])

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }


  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const handleFileUpload = (e) => {
    try {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (!file){
            return;
        }
        const fileSizeInKB=file.size/1024;
        if (fileSizeInKB>30){
            toast.error('Please select an image file smaller than 30 kB');
            setImg(null)
        }else{
            reader.onloadend = () => {
                var previewImgUrl = reader.result
                setImg(previewImgUrl)
            }
            reader.readAsDataURL(file);
        }
       
    } catch (error) {

    }
}


  function handleImageSubmit(event, values) {
   if(img){
    setSubmitting(true)
    
    let form_data = values
    put(`${BASE_URL}/api/accounts/users/${user.id}/`,
      {...form_data,image:img},
      { headers: { 'Content-Type': 'application/json' }, validateStatus: false })
      .then(resp => {
        if (resp.status >= 400) {
          setAlert({ "error": true, "success": false, "error_msg": "Somthing Went Wrong", "success_msg": "" })
        } else {
          setAlert({ "error": false, "success": true, "error_msg": "", "success_msg": "Profile Has Been Updated" })
          setSubmitting(true)
          tog_standard()
        }
        console.log(resp,'resposne')
        let userdata = resp.data
        const obj = JSON.parse(localStorage.getItem("authUser"))
        let data = obj
        data.user = userdata
        localStorage.setItem("authUser", JSON.stringify(data));
        window.location.reload()
        
        
      })
   }else {
    toast.error('Please select an image file smaller than 30 kB')
   }

  }
  
  function handleValidSubmit(event, values) {
    setSubmitting(true)
    let form_data = values
    put(`${BASE_URL}/api/accounts/users/${user.id}/`,
      form_data,
      { headers: { 'Content-Type': 'application/json' }, validateStatus: false })
      .then(resp => {
        if (resp.status >= 400) {
          setAlert({ "error": true, "success": false, "error_msg": "Somthing Went Wrong", "success_msg": "" })
        } else {
          setAlert({ "error": false, "success": true, "error_msg": "", "success_msg": "Profile Has Been Updated" })
          console.log(resp,'valid response')
          
        }
        let userdata = resp.data
        const obj = JSON.parse(localStorage.getItem("authUser"))
        let data = obj
        data.user = userdata
        localStorage.setItem("authUser", JSON.stringify(data));

      })

  }
 
  
  return (
    <React.Fragment>
      <div className="page-content">

        {/* Render Breadcrumb */}
        <Breadcrumb title="Dashboard" breadcrumbItem="Profile" link="/dashboard" />
        {user ?
          <>
            <Row>
              <Col lg="12">
                {alert.error ? (
                  <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">{alert.error_msg}</UncontrolledAlert>
                ) : null}
                {alert.success ? (
                  <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">{alert.success_msg}</UncontrolledAlert>
                ) : null}

                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="ms-3">
                      <img src={img?img:user.image?user.image:not_avail} className="avatar-md rounded-circle img-thumbnail" alt="Selected" />

                      </div>
                      <div className="flex-1 align-self-center">
                        <div className="text-muted">
                          <h5>{user.username}</h5>
                          <p className="mb-1">{user.email}</p>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col sm='6'>
                        <Button color='link' style={{'fontSize':'20px'}} onClick={() => tog_standard()}>
                          <i className="bx bx-edit-alt"></i>

                        </Button>                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
            <Col sm={6} md={4} xl={3}>

<Modal
    isOpen={modal_standard}
    toggle={() => {
        tog_standard()
    }}
>
    <div className="modal-header">
        <h5 className="modal-title mt-0 " style={{ 'textAlign': 'center' }} id="myModalLabel">
            Image
        </h5>
        <button
            type="button"
            onClick={() => {
                setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div className="modal-body">
        {/* <h5>Overflowing text to show scroll behavior</h5> */}

        <Col lg='12'>
            <AvForm className="needs-validation" onValidSubmit={handleImageSubmit}>

                <Row>
                    <Col md={12}>
                        <div class="text-center">


                        </div>

                    </Col>



                    {/* <img src={img} alt="Selected" /> */}

                    <input type='file' accept="image/png, image/jpeg" className="mb-3 mr-5" onChange={handleFileUpload} />


                </Row>


                <div className="modal-footer">
                    {submitting ?

                        // <button
                        //     type="button"
                        //     onClick={() => {
                        //         tog_standard()
                        //     }}
                        //     className="btn btn-primary waves-effect waves-light my-3"
                        // >
                        //     <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                        //     Updateimage
                        // </button>
                        <Button color="primary"  type="submit">
                        Update
                    </Button>
                        :
                        <Button color="primary"  type="submit">
                            Update
                        </Button>
                    }{" "}
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                            setImg(null)
                        }}
                        className="btn btn-primary waves-effect"
                        data-dismiss="modal"
                    >
                        Close
                    </button>

                </div>


            </AvForm>
        </Col>


    </div>

</Modal>
</Col>
            </Row>

            <h4 className="card-title mb-4">Change User Detail</h4>
            <Row>
              <Col md="6">
                <Card>
                  <CardBody>

                    <AvForm className="form-horizontal" onValidSubmit={(e, v) => handleValidSubmit(e, v)} >
                      <Row>
                        <Col md="6">
                          <div className="form-group mb-3">
                            <AvField
                              name="username"
                              label="User Name"
                              value={user.username}
                              className="form-control"
                              placeholder="Enter User Name"
                              disabled
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group mb-3">
                            <AvField
                              name="email"
                              label="User Email"
                              value={user.email}
                              className="form-control"
                              placeholder="Enter User Email"
                              type="text"
                              disabled
                            />
                          </div>
                        </Col>

                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="form-group mb-3">
                            <AvField
                              name="first_name"
                              label="First Name"
                              value={user.first_name}
                              className="form-control"
                              placeholder="Enter First Name"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group mb-3">
                            <AvField
                              name="last_name"
                              label="Last Name"
                              value={user.last_name}
                              className="form-control"
                              placeholder="Enter Last Name"
                              type="text"
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField
                              name="phone"
                              label="Phone"
                              placeholder="Contact Number(+91-9999999999)"
                              value={user.phone}
                              type="text"
                              errorMessage="Enter Only Digits"
                              validate={{
                                pattern: {
                                  value: "^[0-9+-]+$",
                                  errorMessage: "Invalid Contact"
                                },
                              }}
                              required
                            />
                          </div>
                        </Col>
                        {user.role ?
                          <Col md="6">
                            <div className="mb-3">
                              <Label>Type</Label>
                              <Select
                                value={userType}
                                options={UserType}
                                classNamePrefix="select2-selection"
                                isDisabled={true}
                              />
                            </div>
                          </Col>
                          : null}
                      </Row>

                      <div className="text-center mt-4">
                        <Button type="submit" color="danger">
                          Update Detail
                        </Button>
                      </div>
                    </AvForm>


                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
          :
          ""}
      </div>
    </React.Fragment>
  )
}

export default UserProfile;